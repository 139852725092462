<template>
    <!--  Job Name popup -->
    <vs-popup class="" title="Job Name" :active.sync="isaddJobNamePopup">
      <template>
        <form data-vv-scope="dataEnrichmentJobName">
          <div class="vx-row">
            <div class="vx-col mb-2 w-full">
              <p class="w-full float-left"> Job Name</p>
              <div class="vx-row align-center">
                <div class="vx-col mb-0 w-full">
                  <vs-input class="w-full mb-4" v-model="jobName" name="jobName" v-validate="'required'" />
                  <span class="text-danger text-sm">{{ errors.first('dataEnrichmentJobName.jobName') }}</span>  
                   <span class="text-danger text-sm" v-if="showJobNameErrorMessage">{{ jobNameErrorMessage }}</span>                  
                </div>
              </div>
            </div>
          </div>
        </form>
      </template>
      <div class="text-right mt-6 mb-8">
        <vs-button title="Update" color="primary" type="filled" class="mr-2" @click="validateForm()">{{ LabelConstant.buttonLabelSave }}</vs-button>
        <vs-button
          color="grey"
          @click="$emit('clickForClose')"
          type="border"
          class="grey-btn"
        >{{ LabelConstant.buttonLabelCancel }}</vs-button>
      </div>
    </vs-popup>
</template>
<script>
import {
    Validator
} from "vee-validate";

const dict = {
    custom: {
        jobName: {
            required: "Please enter job name",
        }
    }
};
Validator.localize("en", dict);
export default {
    props:{
        isaddJobNamePopup:{
            required:true,
            type:Boolean,
            default:false
        },
        jobNameValue: {
            required: true,
        },
        jobNameErrorMessage:{
          type:String,
        },
        showJobNameErrorMessage:{
            type:Boolean,
            default:false
        },
    },
    computed:{
        jobName:{
            get() {
                return this.jobNameValue;
            },
            set(jobNameValue) {
                this.$emit("jobNameInput", jobNameValue);
            },
        }
    },
    data :() =>({
    }),
     watch:{
      isaddJobNamePopup(){
        this.errors.clear('dataEnrichmentJobName')
      }
    },
    methods:{
        validateForm(){
            this.$validator.validateAll("dataEnrichmentJobName").then(async result => {
                if (result) {
                    this.$emit('clickOnSave');
                }
            });
        },
    },
}
</script>
