<template>
    <vs-popup class="delete-popup" :active.sync="connectionPopup">
      <template>
        <h2 class="mt-5">{{ popupTitle}}</h2>
        <form data-vv-scope="updateConnection">
        <div class="vx-row my-5 px-5 text-left">
          <div class="vx-col w-full">
            Select CRM Connection
            <v-select
              v-model="connectionID"
              :options="connectionList"
              v-validate="'required'"
              name="connectionID"
              label="Value"
              :clearable="false"
            />
          </div>
          <span class="text-danger error-msg">{{ errors.first("updateConnection.connectionID") }}</span>
        </div>
          </form>
      </template>
      <div class="text-center my-6 py-5 ">
        <vs-button title="Update" color="primary" type="filled" class="mr-2" @click="validateConnection()">{{ LabelConstant.buttonLabelOkay }}</vs-button>
        <vs-button
          color="grey"
          @click="cancelAction()"
          type="border"
          class="ml-4 grey-btn mr-3"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>
</template>

<script>
import vSelect from "vue-select";
export default ({
    components:{
      "v-select": vSelect,
    },
    props: {
      popupTitle:{
        type: String,
        required: true,
      },
      connectionPopup:{
        type: Boolean,
        required: true,
        default: false
      },
      selectedInner: {
        type: Array,
        required: false,
      },
      selectedMain:{
        type: Array,
        required: false,
      },
      pushModule:{
        type:String,
        requried:false,
      },
      applicationId:{
        type:Number,
        required:true,
      }
    },
    data() {
        return {
            connectionID:null,
            connectionList:[],
        }
    },
    created() {
      // this.getConnectionIds();
    },
    methods: {
      // async getConnectionIds() {
      //   this.$vs.loading();
      //   await this.axios
      //     .get("/ws/TargetCompanyList/GetConnectionIds")
      //     .then((response) => {
      //       let data = response.data;
      //       this.connectionList = data;
      //       this.$vs.loading.close();
      //     })
      //     .catch((e) => {
      //       this.$vs.loading.close();
      //       this.showError(e);
      //     });
      // },
      validateConnection(){
        this.$validator.validateAll("updateConnection").then(async result => {
          if (result) {
              if(this.pushModule =='companies'){
                this.pushtoCRMCompnies();
              } else if(this.pushModule == 'searchLead'){
                this.pushtoCRMCompnies();
              } else {
                this.pushtoCRMContact();
              }
          }
        });
      },
      cancelAction(){
        this.$emit("cancelConnection");
        this.connectionID = null;
        setTimeout(() => {
          this.errors.clear("updateConnection");
        }, 100);
      },
      async  pushtoCRMCompnies(){
      let comp = [];
      if(this.pushModule == 'companies'){
        this.selectedMain.forEach(element => {
          comp.push({"Id": element.CompanyId,
            "Company_Name": element.CompanyName,
            "Company_Website": element.Url
          })
        });
      }
      if(this.pushModule == 'searchLead'){
        this.selectedMain.forEach(element => {
          comp.push({"Id": element.Id,
            "Company_Name": element.comp,
          })
        });
      }
      let input = {
        ConnectionId:this.connectionID.Key,
        ApplicationId:this.applicationId,
        Companies:comp
      };
       this.$vs.loading();
      await this.axios
        .post("/ws/CRMExport/ExportCompanies",input)
        .then((response) => {
          this.$vs.notify({
            title: "Success",
            text: response.data.Message,
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
            this.connectionID = null;
            setTimeout(() => {
              this.errors.clear("updateConnection");
            }, 100);

          this.$emit("cancelConnection");
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
        });
    },
    async pushtoCRMContact(){
      let contact = [];
      this.selectedInner.forEach(element => {
        contact.push({
          "Id": element.Id,
          "First_Name": element.ContactFirstName,
          "Last_Name": element.ContactLastName
        });
      });
      let input = {
        ConnectionId:this.connectionID.Key,
        ApplicationId:this.applicationId,
        Contacts:contact
      };
       this.$vs.loading();
      await this.axios
        .post("/ws/CRMExport/ExportContacts",input)
        .then((response) => {
          this.$vs.notify({
            title: "Success",
            text: response.data.Message,
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
            this.connectionID = null;
            setTimeout(() => {
              this.errors.clear("updateConnection");
            }, 100);
          this.$emit("cancelConnection");
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
        });
    },
    },
})
</script>