<template>
    <vs-popup class="" :title="!tagDetails?'New Tag':'Edit Tag'" :active.sync="isAddUpdateTagPopupActive">
      <template>
        <form data-vv-scope="">
          <div class="vx-row">
            <div class="vx-col mb-2 w-full">
              <p class="w-full float-left">Tag Name</p>
              <div class="vx-row align-center">
                <div class="vx-col mb-0 w-full">
                  <vs-input class="w-full mb-4" v-model="newTagName" />
                  <vs-checkbox v-model="isTagShared"> Share Tag</vs-checkbox>
                </div>
              </div>
            </div>
          </div>
        </form>
      </template>
      <div class="text-right mt-6 mb-8">
        <vs-button title="Update" color="primary" type="filled" class="mr-2" @click="$emit('saveNewTag', { tagId:tagId,tagName:newTagName,isTagShared:isTagShared } )" >{{
          !tagDetails?LabelConstant.buttonLabelSave:LabelConstant.buttonLabelUpdate
        }}</vs-button>
        <vs-button
          color="grey"
          @click="$emit('closeAddUpdateTagPopup')"
          type="border"
          class="grey-btn"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>
</template>
<script>
export default {
    props:{
        isAddUpdateTagPopupActive:{
            required:true,
            type:Boolean,
            default:false
        },
        tagDetails:{
          required:false,
        }
    },
    data :() =>({
        newTagName:null,
        isTagShared:false,
        tagId:"",
    }),
    watch:{
      
      tagDetails(val){
       this.newTagName = val.TagName;
        this.isTagShared = val.IsShared;
        this.tagId = val.Id;
      }
    }
}
</script>