<template>
    <vs-popup :title="popupTitle" :active.sync="isPopup">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full text-center">
            <b-card-text>
              <h4 class="mb-3">{{ LabelConstant.infoLabelYourDailyContactDownloadLimitIsOver }}</h4>
            </b-card-text>
          </div>
        </div>
      </template>
      <div class="text-right mt-6 mb-8">
        <vs-button
          color="grey"
          @click="$emit('clickForFinish')"
          type="border"
          class="grey-btn"
          >{{ LabelConstant.buttonLabelOkay }}</vs-button
        >
      </div>
    </vs-popup>    
</template>

<script>
import { BCardText } from "bootstrap-vue";

export default ({
    components: {
        BCardText
    },
    props:{
        popupTitle:{
            type: String,
            required: true,
        },
        isPopup:{
            type: Boolean,
            required: true,
            default: false
        },
    }
})
</script>
