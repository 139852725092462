<!-- =========================================================================================
    File Name: CollapseAccordion.vue
    Description: Collapse with accrodion
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div title="" code-toggler>
    <!-- Find People left filter section-->
    <div>
      <vs-tabs class="search-tab" :value="mainActiveTab">
        <!-- Quick Search -->
        <vs-tab label="Quick Search" @click="mainActiveTab = 0">
          <div class="filter-list pt-5" v-if="mainActiveTab == 0">
            <div class="filter-card">
              {{ LabelConstant.inputLabelCompanyName }}
              <div class="m-3">
                <input-tag v-model="companyName" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]"></input-tag>
              </div>
            </div>
            <div class="filter-card">
              {{ LabelConstant.inputLabelContactName }}
              <div class="m-3">
                <vs-input v-model="personalContactFirstName" class="mb-2 w-full" placeholder="Type first name here"/>
                <vs-input v-model="personalContactLastName" class="w-full" placeholder="Type last name here"/>
              </div>
            </div>
            <div class="filter-card">
              {{ LabelConstant.inputLabelCompanyWebAddress }}
              <div class="m-3">
                <input-tag v-model="companyWebAddress" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]"></input-tag>
              </div>
            </div>
            <div class="filter-card">
              {{ LabelConstant.inputLabelJobTitles }}
              <div class="m-3">
                <input-tag v-model="jobTitle" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]"></input-tag>
              </div>
            </div>
          </div>
        </vs-tab>
        <!-- Advanced tab -->
        <vs-tab label="Advanced  Search" @click="mainActiveTab = 1">
          <vs-collapse accordion v-if="mainActiveTab == 1" class="px-0">
            <!-- Job Criteria -->
            <div class="filter-card">
              <vs-collapse-item>
                <div slot="header">{{ LabelConstant.inputLabelJobCriteria }}</div>
                <div class="filter-list">
                  <vs-checkbox v-model="showJobTitleText" @click="showJobTitleText == true ? (jobTitle = []) : false">{{ LabelConstant.inputLabelJobTitle }}</vs-checkbox>
                  <div v-show="showJobTitleText">
                    <input-tag v-model="jobTitle" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]"></input-tag>
                  </div>
                  <vs-checkbox v-model="showJobLevelOptions" @click="jobLevel = []">{{ LabelConstant.inputLabelJobLevel }}</vs-checkbox>
                  <div v-if="showJobLevelOptions">
                    <v-select class="multiple-select type-multiselect" :label="jobLevelLabelName" :options="jobLevelOptions" v-model="jobLevel" multiple/>
                  </div>
                  <vs-checkbox v-model="showJobFunctionOptions" @click="jobFunction = []">{{ LabelConstant.inputLabelJobFunction }}</vs-checkbox>
                  <div v-if="showJobFunctionOptions">
                    <v-select class="multiple-select type-multiselect" :label="jobFunctionLabelName" :options="jobFunctionOptions" v-model="jobFunction" multiple/>
                  </div>
                  <vs-checkbox v-model="showJobStartDateOptions" @click="jobStartDate = null">{{ LabelConstant.inputLabelJobStartDate }}</vs-checkbox>
                  <div v-if="showJobStartDateOptions">
                    <v-select :label="jobStartDateLabelName" :options="jobStartDateOptions" v-model="jobStartDate"/>
                  </div>
                  <vs-checkbox v-model="recentChangedJob">{{ LabelConstant.inputLabelRecentChangedJob }}</vs-checkbox>
                </div>
              </vs-collapse-item>
            </div>
            <!-- Company Criteria -->
            <div class="filter-card">
              <vs-collapse-item>
                <div slot="header">{{ LabelConstant.inputLabelCompanyCriteria }}</div>
                <div class="filter-list">
                  <vs-checkbox v-model="showCompanyNameText" @click="showCompanyNameText == true ? (companyName = []) : false">{{ LabelConstant.inputLabelCompanyName }}</vs-checkbox>
                  <div v-if="showCompanyNameText">
                    <input-tag v-model="companyName" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]"></input-tag>
                  </div>
                  <vs-checkbox v-model="showCompanyWebAddressText" @click="showCompanyWebAddressText == true ? (companyWebAddress = []) : false">{{ LabelConstant.inputLabelCompanyWebAddress }}</vs-checkbox>
                  <div v-if="showCompanyWebAddressText">
                    <input-tag v-model="companyWebAddress" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]"></input-tag>
                  </div>
                  <vs-checkbox v-model="showCompanyIndustryOptions" @click="companyIndustry = []" >{{ LabelConstant.inputLabelCompanyIndustry }}</vs-checkbox>
                  <div v-if="showCompanyIndustryOptions">
                    <v-select
                      class="multiple-select type-multiselect"
                      :label="companyIndustryLabelName"
                      :options="companyIndustryOptions"
                      v-model="companyIndustry"
                      multiple
                    />
                  </div>
                  <vs-checkbox v-model="showCompanyEmployeeSizeOptions" @click="companyEmployeeSize = []">{{ LabelConstant.inputLabelCompanyEmployeeSize }}</vs-checkbox>
                  <div v-if="showCompanyEmployeeSizeOptions">
                    <v-select
                      class="multiple-select type-multiselect"
                      :label="companyEmployeeSizeLabelName"
                      :options="companyEmployeeSizeOptions"
                      v-model="companyEmployeeSize"
                      multiple
                    />
                  </div>
                  <vs-checkbox v-model="showCompanyRevenueBandsOptions" @click="companyRevenueBands = []">{{ LabelConstant.inputLabelCompanyRevenueBand }}</vs-checkbox>
                  <div v-if="showCompanyRevenueBandsOptions">
                    <v-select
                      class="multiple-select type-multiselect"
                      :label="companyRevenueBandsLabelName"
                      :options="companyRevenueBandsOptions"
                      v-model="companyRevenueBands"
                      multiple
                    />
                  </div>
                  <vs-checkbox v-model="showCompanyNAICSOptions" @click="companyNAICS = []">{{ LabelConstant.inputLabelCompanyNAICS }}</vs-checkbox>
                  <div v-if="showCompanyNAICSOptions">
                    <v-select
                      class="multiple-select type-multiselect"
                      :label="companyNAICSLabelName"
                      :options="companyNAICSOptions"
                      v-model="companyNAICS"
                      multiple
                    />
                  </div>
                  <vs-checkbox v-model="showCompanySICSOptions" @click="companySICS = []">{{ LabelConstant.inputLabelCompanySIC }}</vs-checkbox>
                  <div v-if="showCompanySICSOptions">
                    <v-select
                      class="multiple-select type-multiselect"
                      :label="companySICSLabelName"
                      :options="companySICSOptions"
                      v-model="companySICS"
                      multiple
                    />
                  </div>
                </div>
              </vs-collapse-item>
            </div>
            <!-- Location Criteria -->
            <div class="filter-card">
              <vs-collapse-item>
                <div slot="header">{{ LabelConstant.inputLabelLocationCriteria }}</div>
                <div class="filter-list">
                  <vs-checkbox v-if="isRegion" v-model="showContactRegionOptions" @click="contactRegion = []">{{ LabelConstant.inputLabelContactRegion }}</vs-checkbox>
                  <div v-if="showContactRegionOptions && isRegion">
                    <v-select
                      class="multiple-select type-multiselect"
                      :label="contactRegionLabelName"
                      :options="contactRegionOptions"
                      v-model="contactRegion"
                      multiple
                    />
                  </div>
                  <vs-checkbox v-if="isAddressType" v-model="showAddressTypeOptions" @click="addressType = []">{{ LabelConstant.inputLabelAddressType }}</vs-checkbox>
                  <div v-if="showAddressTypeOptions && isAddressType">
                    <v-select
                      class="multiple-select type-multiselect"
                      :label="addressTypeLabelName"
                      :options="addressTypeOptions"
                      v-model="addressType"
                      multiple
                    />
                  </div>
                  <vs-checkbox v-model="showContactCountriesOptions" @click="contactCountries = []">{{ LabelConstant.inputLabelContactCountry }}</vs-checkbox>
                  <div v-if="showContactCountriesOptions">
                    <v-select
                      class="multiple-select type-multiselect"
                      :label="contactCountriesLabelName"
                      :options="contactCountriesOptions"
                      v-model="contactCountries"
                      multiple
                    />
                  </div>
                  <vs-checkbox v-model="showContactSatesOptions" @click="contactStates = []">{{ LabelConstant.inputLabelContactState }}</vs-checkbox>
                  <div v-if="showContactSatesOptions">
                    <v-select
                      class="multiple-select type-multiselect"
                      :label="contactStatesLabelName"
                      :options="contactStatesOptions"
                      v-model="contactStates"
                      multiple
                    />
                  </div>
                  <vs-checkbox v-model="showContactCity" @click="contactCity = []">{{ LabelConstant.inputLabelContactCity }}</vs-checkbox>
                  <div v-if="showContactCity">
                    <input-tag v-model="contactCity" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]"></input-tag>
                  </div>
                  <vs-checkbox v-model="showContactZipCode" @click="contactZipCode = []">{{ LabelConstant.inputLabelContactZip }}</vs-checkbox>
                  <div v-if="showContactZipCode">
                    <input-tag v-model="contactZipCode" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]"></input-tag>
                  </div>
                  <!-- <vs-checkbox v-model="localAddressRequired">Local Address Required</vs-checkbox> -->
                </div>
              </vs-collapse-item>
            </div>
            <!-- Personal Criteria -->
            <div class="filter-card">
              <vs-collapse-item>
                <div slot="header">{{ LabelConstant.inputLabelPersonalCriteria }}</div>
                <div class="filter-list">
                  <vs-checkbox v-model="showPersonalContactName" @click="clearFirstAndLastName()">{{ LabelConstant.inputLabelContactName }}</vs-checkbox>
                  <div v-if="showPersonalContactName" class="m-3">
                    <vs-input v-model="personalContactFirstName" class="mb-2" placeholder="Type first name here" />
                    <vs-input v-model="personalContactLastName" placeholder="Type last name here" />
                  </div>
                  <vs-checkbox v-model="showPersonalContactGenderOptions" @click="personalContactGender = []">{{ LabelConstant.inputLabelContactGender }}</vs-checkbox>
                  <div v-if="showPersonalContactGenderOptions">
                    <v-select
                      class="multiple-select type-multiselect"
                      :label="personalContactGenderLabelName"
                      :options="personalContactGenderOptions"
                      v-model="personalContactGender"
                      multiple
                    />
                  </div>
                  <vs-checkbox v-model="showPersonalContactLastName" @click="personalContactAlmaMater = []">{{ LabelConstant.inputLabelContactAlmaMater }}</vs-checkbox>
                  <div v-if="showPersonalContactLastName">
                    <input-tag v-model="personalContactAlmaMater" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]"></input-tag>
                  </div>
                  <vs-checkbox v-model="showPersonalContactBirthday">{{ LabelConstant.inputLabelContactAge }}</vs-checkbox>
                  <div v-if="showPersonalContactBirthday" class="my-3">
                    <vue-numeric-input
                      placeholder="From"
                      :controls="false"
                      v-model="personalContactBirthdayFrom"
                      class="mb-2"
                      @change="fromAgeValidate"
                    ></vue-numeric-input>
                    <span class="text-danger text-sm error-msg" v-show="fromAgeErrorMessage">{{ fromAgeErrorMessage }}</span>
                    <vue-numeric-input
                      placeholder="To"
                      :controls="false"
                      v-model="personalContactBirthdayTo"
                      @change="toAgeValidate"
                    ></vue-numeric-input>
                    <span class="text-danger text-sm error-msg mb-3" v-show="toAgeErrorMessage">{{ toAgeErrorMessage }}</span>
                  </div>
                  <vs-checkbox v-model="showPersonalContactSkills" @click="personalContactSkills = []">{{ LabelConstant.inputLabelContactSkill }}</vs-checkbox>
                  <div v-if="showPersonalContactSkills">
                    <input-tag v-model="personalContactSkills" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]"></input-tag>
                  </div>
                  <vs-checkbox v-model="showPersonalContactFormerEmployer" @click="personalContactFormerEmployer = []">{{ LabelConstant.inputLabelContactFormerEmployer }}</vs-checkbox>
                  <div v-if="showPersonalContactFormerEmployer">
                    <input-tag v-model="personalContactFormerEmployer" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]"></input-tag>
                  </div>
                  <!-- <vs-checkbox v-model="showPersonalContactMinimumConnection" @click="personalContactMinimumConnection =null">Minimum Number Of Connections</vs-checkbox>
                  <div v-if="showPersonalContactMinimumConnection">
                    <vs-input v-model="personalContactMinimumConnection"/>
                  </div> -->
                  <vs-checkbox v-model="personalContactEmailAddressRequired">{{ LabelConstant.inputLabelPersonalEmailAddressRequired }}</vs-checkbox>
                  <vs-checkbox v-model="personalContactPhoneRequired">{{ LabelConstant.inputLabelCellPhoneRequired }}</vs-checkbox>
                </div>
              </vs-collapse-item>
            </div>
            <!-- Suppression Criteria -->
            <div class="filter-card">
              <vs-collapse-item>
                <div slot="header">{{ LabelConstant.inputLabelSuppressionCriteria }}</div>
                <div class="filter-list">
                  <!-- <vs-checkbox v-model="showSuppressionLastUpdatedDateOptions" @click="suppressionLastUpdatedDate = null">Last Updated Date</vs-checkbox>
                  <div v-if="showSuppressionLastUpdatedDateOptions">
                    <v-select
                      :label="suppressionLastUpdatedDateLabelName"
                      :options="suppressionLastUpdatedDateOptions"
                      v-model="suppressionLastUpdatedDate"
                    />
                  </div> -->
                  <vs-checkbox v-model="showSuppressionCompanyWebAddress" @click="suppressionCompanyWebAddress = []">{{ LabelConstant.inputLabelCompanyWebAddress }}</vs-checkbox>
                  <div v-if="showSuppressionCompanyWebAddress">
                    <input-tag v-model="suppressionCompanyWebAddress" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]"></input-tag>
                  </div>
                  <vs-checkbox v-model="showSuppressionContactTitles" @click="suppressionContactTitles = []">{{ LabelConstant.inputLabelContactTitles }}</vs-checkbox>
                  <div v-if="showSuppressionContactTitles">
                    <input-tag v-model="suppressionContactTitles" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]"></input-tag>
                  </div>
                  <vs-checkbox v-model="showSuppressionEmailAddress" @click="suppressionEmailAddress = []">{{ LabelConstant.inputLabelEmailAddress }}</vs-checkbox>
                  <div v-if="showSuppressionEmailAddress">
                    <input-tag v-model="suppressionEmailAddress" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]"></input-tag>
                  </div>
                  <vs-checkbox v-model="suppressionExcludeMyLeads">{{ LabelConstant.inputLabelExcludeMyLeads }}</vs-checkbox>
                </div>
              </vs-collapse-item>
            </div>
            <!-- List Criteria -->
            <div class="filter-card">
              <vs-collapse-item>
                <div slot="header">{{ LabelConstant.inputLabelListCriteria }}</div>
                <div class="filter-list">
                  <vs-checkbox v-model="listPrimaryContact">{{ LabelConstant.inputLabelPrimaryContact }}</vs-checkbox>
                  <vs-checkbox v-model="listProfessionalEmail">{{ LabelConstant.inputLabelProfessionalEmail }}</vs-checkbox>
                  <vs-checkbox v-model="showListEmailConfidenceScore" @click="listEmailConfidenceScore = 50">{{ LabelConstant.inputLabelEmailConfidenceScore }}</vs-checkbox>
                  <div v-if="showListEmailConfidenceScore" class="fliter-slider">
                    <vue-slider
                      v-model="listEmailConfidenceScore"
                      :min="50"
                      :max="100"
                      :process="false"
                      :tooltip="'always'"
                      :height="6"
                      class="mr-4"
                      :direction="'horizontal'"
                    />
                  </div>
                  <vs-checkbox v-model="listDeterministicEmail">{{ LabelConstant.inputLabelDeterministicEmail }}</vs-checkbox>
                  <vs-checkbox v-model="listPhoneNumber">{{ LabelConstant.inputLabelPhoneNumber }}</vs-checkbox>
                </div>
              </vs-collapse-item>
            </div>
            <!--Saved Criteria -->
            <div class="filter-card" v-if="moduleName =='SavedContact'">
              <vs-collapse-item>
                <div slot="header">{{ LabelConstant.inputLabelSavedCriteria }}</div>
                <div class="filter-list">
                  <vs-checkbox v-model="showTags" @click="userDefinedTag =[]">{{ LabelConstant.inputLabelTags }}</vs-checkbox>
                  <div v-if="showTags">
                    <v-select
                      class="multiple-select type-multiselect"
                      :options="userDefinedTagsOptions"
                      :label="userDefinedTagLabelName"
                      v-model="userDefinedTag"
                      multiple
                    />
                  </div>
                  <vs-checkbox v-model="showRealTimeEmailScore" @click="realTimeEmailScore = 0">Real Time Email Score</vs-checkbox>
                  <div v-if="showRealTimeEmailScore" class="fliter-slider">
                    <vue-slider
                      v-model="realTimeEmailScore"
                      :min="0"
                      :max="100"
                      :process="false"
                      :tooltip="'always'"
                      :height="6"
                      class="mr-4"
                      :direction="'horizontal'"
                    />
                  </div>
                  <vs-checkbox v-model="showDateSaved">{{ LabelConstant.inputLabelDateSaved }}</vs-checkbox>
                  <div class="date-picker w-full" v-if="showDateSaved">
                    <date-range-picker
                      ref="dateSavedDatePicker"
                      opens="center"
                      @update="dateSavedUpdateValues"
                      :dateRange="contactSaveddateRange"
                      :alwaysShowCalendars="false"
                      :linked-calendars="false"
                        v-model="contactSaveddateRange"
                        :locale-data="{
                        firstDay: 0,
                        format: 'mm/dd/yyyy',
                        separator: ' - '
                      }"
                    >
                    </date-range-picker>
                  </div>
                  <vs-checkbox v-model="showSavedFrom" @click="savedFrom=[]">{{ LabelConstant.inputLabelSavedFrom }}</vs-checkbox>
                  <div v-if="showSavedFrom">
                    <v-select
                      class="multiple-select type-multiselect"
                      :options="savedFromOptions"
                      :label="savedFromLabelName"
                      v-model="savedFrom"
                      multiple
                    />
                  </div>
                  <vs-checkbox v-model="showSavedBy" @click="savedBy =[]">{{ LabelConstant.inputLabelSavedBy }}</vs-checkbox>
                  <div  v-if="showSavedBy">                  
                    <v-select
                      class="multiple-select type-multiselect"
                      :options="savedByOptions"
                      :label="savedByLabelName"
                      v-model="savedBy"
                      multiple
                    />
                  </div>
                  <vs-checkbox v-model="showLastExported">{{ LabelConstant.inputLabelLastExported }}</vs-checkbox>  
                  <div class="date-picker w-full" v-if="showLastExported">
                    <date-range-picker
                      ref="lastExportedDatePicker"
                      opens="center"
                      :dateRange="lastExportedDateRange"
                      :alwaysShowCalendars="false"
                      :linked-calendars="false"
                      v-model="lastExportedDateRange"
                      :locale-data="{
                        firstDay: 0,
                        format: 'mm/dd/yyyy',
                        separator: ' - '
                      }"
                      @update="lastExportUpdateValues"
                    >
                    </date-range-picker>
                  </div>
                  <vs-checkbox v-model="excludeExported">{{ LabelConstant.inputLabelExcludeExported }}</vs-checkbox>
                </div>
              </vs-collapse-item>
            </div>
          </vs-collapse>
        </vs-tab>
      </vs-tabs>
      <!-- buttons -->
      <div class="pt-5 mt-5 flex flex-wrap items-center">
        <vs-button
          :title="LabelConstant.buttonLabelSearch"
          color="primary"
          type="filled"
          @click="$emit('search-contacts')"
          class="mr-1 float-left px-4 my-2"
          >{{ LabelConstant.buttonLabelSearch }}</vs-button
        >
        <vs-button
          :title="buttonName"
          color="success"
          type="filled"
          class="mr-2 float-left px-4 my-2"
          @click="showsaveSearchpopup()"
          >{{ buttonName }}</vs-button
        >
        <a class="mr-1 float-left my-2 btn-link" @click="listSavedSearch()">View Saved Search</a>
      </div>
    </div>

    <!-- save search -->
    <vs-popup class="tag-info" title="Save your search" :active.sync="saveSearchPopupActive">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col input-text w-full">
            <div class="vx-row">
              <div class="vx-col w-full mt-6">
                <label>Search Name</label>
                <vs-input type="text" class="w-full" v-model="searchName" />
              </div>
              <div class="vx-col w-full mt-6">
                <vs-checkbox v-model="isShared">Share this search with all users</vs-checkbox>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button color="primary" type="filled" @click="saveSearch()">{{ LabelConstant.buttonLabelSave }}</vs-button>
        <vs-button color="grey" @click="cancelsaveSearch()" type="border" class="ml-4 grey-btn" >{{ LabelConstant.buttonLabelCancel }}</vs-button>
      </div>
    </vs-popup>
    <!-- Delete Search saved Popup -->
    <vs-popup class="delete-popup" :active.sync="deleteSearchSavedPopupActive">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="popup-icon">
              <feather-icon icon="Trash2Icon"></feather-icon>
            </div>
            <h4>Delete Saved Search?</h4>
            <span class="deleted-no">{{ deleteSavedSearchName ? deleteSavedSearchName : "" }}</span>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button type="filled" @click="deleteSavedSearch()" color="danger">{{ LabelConstant.buttonLabelDelete }}</vs-button>
        <vs-button @click="cancelDeleteSavedSearch()" color="grey" type="border" class="ml-4 grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
      </div>
    </vs-popup>
    <!-- show saved search -->
    <vs-popup class="popup-width" title="Saved Searches" :active.sync="savedSearchPopupActive">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col input-text w-full">
            <div class="vx-row">
              <div class="vx-col w-full mt-6" v-if="savedSearch.length > 0">
                <!-- table -->
                <vs-table :data="savedSearch" search pagination>
                  <template slot="thead">
                    <vs-th>{{ LabelConstant.tableHeaderLabelName }}</vs-th>
                    <vs-th>{{ LabelConstant.tableHeaderLabelShared }}</vs-th>
                    <vs-th>{{ LabelConstant.tableHeaderLabelAction }}</vs-th>
                  </template>
                  <template slot-scope="{ data }">
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                      <vs-td :data="tr.Saved_Search_Name">
                        <div> {{ tr.Saved_Search_Name }}</div>
                      </vs-td>
                      <vs-td :data="tr.IsShared">
                        <vs-checkbox disabled v-model="tr.IsShared"></vs-checkbox>
                      </vs-td>
                      <vs-td>
                        <vs-button
                          @click="editSavedSearch(tr)"
                          :title="LabelConstant.buttonTitleEdit"
                          color="primary"
                          type="filled"
                          icon-pack="feather"
                          icon="icon-edit"
                          class="mr-1 float-left"
                        ></vs-button>
                        <vs-button
                          v-if="tr.IsOwner"
                          @click="confirmationDeleteSavedSearch(tr.Id,tr.Saved_Search_Name)"
                          :title="LabelConstant.buttonLabelDelete"
                          color="danger"
                          type="filled"
                          icon-pack="feather"
                          icon="icon-trash-2"
                          class="mr-0 float-left"
                        ></vs-button>
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button
          color="grey"
          @click="(savedSearchPopupActive = false), (savedSearch = [])"
          type="border"
          class="ml-4 grey-btn"
          >{{ LabelConstant.buttonLabelClose }}</vs-button
        >
      </div>
    </vs-popup>
  </div>
</template>
<script>
import vSelect from "vue-select";
import InputTag from "vue-input-tag";
import VueNumericInput from "vue-numeric-input";
import vueSlider from "vue-slider-component";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";

export default {
  props: {
    //job Criteria
    jobTitleValue: {
      required: true,
    },

    jobLevelLabelName: String,
    jobLevelOptions: {
      required: true,
    },
    jobLevelValue: {
      required: true,
    },

    jobFunctionLabelName: String,
    jobFunctionOptions: {
      required: true,
    },
    jobFunctionValue: {
      required: true,
    },

    jobStartDateLabelName: String,
    jobStartDateOptions: {
      required: true,
    },
    jobStartDateValue: {
      required: true,
    },

    recentChangedJobValue: {
      required: true,
    },

    //Company Criteria
    companyNameValue: {
      required: true,
    },

    companyWebAddressValue: {
      required: true,
    },

    companyIndustryLabelName: String,
    companyIndustryOptions: {
      required: true,
    },
    companyIndustryValue: {
      required: true,
    },

    companyEmployeeSizeLabelName: String,
    companyEmployeeSizeOptions: {
      required: true,
    },
    companyEmployeeSizeValue: {
      required: true,
    },

    companyRevenueBandsLabelName: String,
    companyRevenueBandsOptions: {
      required: true,
    },
    companyRevenueBandsValue: {
      required: true,
    },

    companyNAICSLabelName: String,
    companyNAICSOptions: {
      required: true,
    },
    companyNAICSValue: {
      required: true,
    },

    companySICSLabelName: String,
    companySICSOptions: {
      required: true,
    },
    companySICSValue: {
      required: true,
    },

    //Location Criteria
    isRegion: {
      type: Boolean,
      default: true,
    },
    contactRegionLabelName: String,
    contactRegionOptions: {
      required: false,
    },
    contactRegionValue: {
      required: false,
    },

    isAddressType: {
      type: Boolean,
      default: false,
    },
    addressTypeLabelName: String,
    addressTypeOptions: {
      required: false,
    },
    addressTypeValue: {
      required: false,
    },

    contactCountriesLabelName: String,
    contactCountriesOptions: {
      required: true,
    },
    contactCountriesValue: {
      required: true,
    },

    contactStatesLabelName: String,
    contactStatesOptions: {
      required: true,
    },
    contactStatesValue: {
      required: true,
    },

    contactCityValue: {
      required: true,
    },

    contactZipCodeValue: {
      required: true,
    },

    // localAddressRequiredValue: {
    //   required: true
    // },

    //Personal Criteria
    personalContactFirstNameValue: {
      required: true,
    },

    personalContactLastNameValue: {
      required: true,
    },

    personalContactGenderLabelName: String,
    personalContactGenderOptions: {
      required: true,
    },
    personalContactGenderValue: {
      required: true,
    },

    personalContactAlmaMaterValue: {
      required: true,
    },

    personalContactBirthdayFromValue: {
      required: true,
    },
    personalContactBirthdayToValue: {
      required: true,
    },

    personalContactSkillsValue: {
      required: true,
    },

    personalContactFormerEmployerValue: {
      required: true,
    },

    // personalContactMinimumConnectionValue: {
    //   required: true
    // },

    personalContactEmailAddressRequiredValue: {
      required: true,
    },

    personalContactPhoneRequiredValue: {
      required: true,
    },

    //Suppression Criteria
    // suppressionLastUpdatedDateLabelName: String,
    // suppressionLastUpdatedDateOptions: {
    //   required: true
    // },
    // suppressionLastUpdatedDateValue: {
    //   required: true
    // },

    suppressionCompanyWebAddressValue: {
      required: true,
    },

    suppressionContactTitlesValue: {
      required: true,
    },

    suppressionEmailAddressValue: {
      required: true,
    },

    suppressionExcludeMyLeadsValue: {
      required: true,
    },

    //List Criteria
    listPrimaryContactValue: {
      required: true,
    },

    listProfessionalEmailValue: {
      required: true,
    },
    showListEmailConfidenceScoreValue: {
      required: true,
    },
    showRealTimeEmailScoreValue:{
      required: false,
    },
    listEmailConfidenceScoreValue: {
      required: true,
    },
    realTimeEmailScoreValue: {
      required: false,
    },
    listDeterministicEmailValue: {
      required: true,
    },

    listPhoneNumberValue: {
      required: true,
    },
    moduleName:{
      required: true,
    },

    //saved criteria
    userDefinedTagLabelName: String,
    userDefinedTagsOptions: {
      required: false,
    },
    userDefinedTagValue: {
      required: false,
    },

    savedFromLabelName:String,
    savedFromOptions:{
      required: false,
    },
    savedFromValue:{
      required: false,
    },

    savedByLabelName:String,
    savedByOptions:{
      required: false,
    },
    savedByValue:{
      required: false,
    },

    savedDateValue:{
      required: false,
    },
    lastExportedValue:{
      required: false,
    },
    excludeExportedValue:{
      required: false,
    }

  },
  computed: {
    //job Criteria
    jobTitle: {
      get() {
        return this.jobTitleValue;
      },
      set(jobTitleValue) {
        this.$emit("jobTitleInput", jobTitleValue);
      },
    },
    jobLevel: {
      get() {
        return this.jobLevelValue;
      },
      set(jobLevelValue) {
        this.$emit("jobLevelInput", jobLevelValue);
      },
    },
    jobFunction: {
      get() {
        return this.jobFunctionValue;
      },
      set(jobFunctionValue) {
        this.$emit("jobFunctionInput", jobFunctionValue);
      },
    },
    jobStartDate: {
      get() {
        return this.jobStartDateValue;
      },
      set(jobStartDateValue) {
        this.$emit("jobStartDateInput", jobStartDateValue);
      },
    },
    recentChangedJob: {
      get() {
        return this.recentChangedJobValue;
      },
      set(recentChangedJobValue) {
        this.$emit("recentChangedJobInput", recentChangedJobValue);
      },
    },

    //Company Criteria
    companyName: {
      get() {
        return this.companyNameValue;
      },
      set(companyNameValue) {
        this.$emit("companyNameInput", companyNameValue);
      },
    },
    companyWebAddress: {
      get() {
        return this.companyWebAddressValue;
      },
      set(companyWebAddressValue) {
        this.$emit("companyWebAddressInput", companyWebAddressValue);
      },
    },
    companyIndustry: {
      get() {
        return this.companyIndustryValue;
      },
      set(companyIndustryValue) {
        this.$emit("companyIndustryInput", companyIndustryValue);
      },
    },
    companyEmployeeSize: {
      get() {
        return this.companyEmployeeSizeValue;
      },
      set(companyEmployeeSizeValue) {
        this.$emit("companyEmployeeSizeInput", companyEmployeeSizeValue);
      },
    },
    companyRevenueBands: {
      get() {
        return this.companyRevenueBandsValue;
      },
      set(companyRevenueBandsValue) {
        this.$emit("companyRevenueBandsInput", companyRevenueBandsValue);
      },
    },
    companyNAICS: {
      get() {
        return this.companyNAICSValue;
      },
      set(companyNAICSValue) {
        this.$emit("companyNAICSInput", companyNAICSValue);
      },
    },
    companySICS: {
      get() {
        return this.companySICSValue;
      },
      set(companySICSValue) {
        this.$emit("companySICSInput", companySICSValue);
      },
    },

    //Location Criteria
    contactRegion: {
      get() {
        return this.contactRegionValue;
      },
      set(contactRegionValue) {
        this.$emit("contactRegionInput", contactRegionValue);
      },
    },
    addressType: {
      get() {
        return this.addressTypeValue;
      },
      set(addressTypeValue) {
        this.$emit("adddressTypeInput", addressTypeValue);
      },
    },
    contactCountries: {
      get() {
        return this.contactCountriesValue;
      },
      set(contactCountriesValue) {
        this.$emit("contactCountriesInput", contactCountriesValue);
      },
    },
    contactStates: {
      get() {
        return this.contactStatesValue;
      },
      set(contactStatesValue) {
        this.$emit("contactStatesInput", contactStatesValue);
      },
    },
    contactCity: {
      get() {
        return this.contactCityValue;
      },
      set(contactCityValue) {
        this.$emit("contactCityInput", contactCityValue);
      },
    },
    contactZipCode: {
      get() {
        return this.contactZipCodeValue;
      },
      set(contactZipCodeValue) {
        this.$emit("contactZipCodeInput", contactZipCodeValue);
      },
    },
    // localAddressRequired: {
    //   get() { return this.localAddressRequiredValue },
    //   set(localAddressRequiredValue) { this.$emit('localAddressRequiredInput', localAddressRequiredValue) }
    // },

    //Personal Criteria
    personalContactFirstName: {
      get() {
        return this.personalContactFirstNameValue;
      },
      set(personalContactFirstNameValue) {
        this.$emit("personalContactFirstNameInput",personalContactFirstNameValue);
      },
    },
    personalContactLastName: {
      get() {
        return this.personalContactLastNameValue;
      },
      set(personalContactLastNameValue) {
        this.$emit("personalContactLastNameInput",personalContactLastNameValue);
      },
    },
    personalContactGender: {
      get() {
        return this.personalContactGenderValue;
      },
      set(personalContactGenderValue) {
        this.$emit("personalContactGenderInput", personalContactGenderValue);
      },
    },
    personalContactAlmaMater: {
      get() {
        return this.personalContactAlmaMaterValue;
      },
      set(personalContactAlmaMaterValue) {
        this.$emit("personalContactAlmaMaterInput",personalContactAlmaMaterValue);
      },
    },
    personalContactBirthdayFrom: {
      get() {
        return this.personalContactBirthdayFromValue;
      },
      set(personalContactBirthdayFromValue) {
        this.$emit("personalContactBirthdayFromInput",personalContactBirthdayFromValue);
      },
    },
    personalContactBirthdayTo: {
      get() {
        return this.personalContactBirthdayToValue;
      },
      set(personalContactBirthdayToValue) {
        this.$emit("personalContactBirthdayToInput",personalContactBirthdayToValue);
      },
    },
    personalContactSkills: {
      get() {
        return this.personalContactSkillsValue;
      },
      set(personalContactSkillsValue) {
        this.$emit("personalContactSkillsInput", personalContactSkillsValue);
      },
    },
    personalContactFormerEmployer: {
      get() {
        return this.personalContactFormerEmployerValue;
      },
      set(personalContactFormerEmployerValue) {
        this.$emit("personalContactFormerEmployerInput",personalContactFormerEmployerValue);
      },
    },
    // personalContactMinimumConnection: {
    //   get() { return this.personalContactMinimumConnectionValue },
    //   set(personalContactMinimumConnectionValue) { this.$emit('personalContactMinimumConnectionInput', personalContactMinimumConnectionValue) }
    // },
    personalContactEmailAddressRequired: {
      get() {
        return this.personalContactEmailAddressRequiredValue;
      },
      set(personalContactEmailAddressRequiredValue) {
        this.$emit("personalContactEmailAddressRequiredInput",personalContactEmailAddressRequiredValue);
      },
    },
    personalContactPhoneRequired: {
      get() {
        return this.personalContactPhoneRequiredValue;
      },
      set(personalContactPhoneRequiredValue) {
        this.$emit("personalContactPhoneRequiredInput",personalContactPhoneRequiredValue);
      },
    },

    //Suppression Criteria
    // suppressionLastUpdatedDate: {
    //   get() { return this.suppressionLastUpdatedDateValue },
    //   set(suppressionLastUpdatedDateValue) { this.$emit('suppressionLastUpdatedDateInput', suppressionLastUpdatedDateValue) }
    // },
    suppressionCompanyWebAddress: {
      get() {
        return this.suppressionCompanyWebAddressValue;
      },
      set(suppressionCompanyWebAddressValue) {
        this.$emit("suppressionCompanyWebAddressInput",suppressionCompanyWebAddressValue);
      },
    },
    suppressionContactTitles: {
      get() {
        return this.suppressionContactTitlesValue;
      },
      set(suppressionContactTitlesValue) {
        this.$emit("suppressionContactTitlesInput",suppressionContactTitlesValue);
      },
    },
    suppressionEmailAddress: {
      get() {
        return this.suppressionEmailAddressValue;
      },
      set(suppressionEmailAddressValue) {
        this.$emit("suppressionEmailAddressInput",suppressionEmailAddressValue);
      },
    },
    suppressionExcludeMyLeads: {
      get() {
        return this.suppressionExcludeMyLeadsValue;
      },
      set(suppressionExcludeMyLeadsValue) {
        this.$emit("suppressionExcludeMyLeadsInput",suppressionExcludeMyLeadsValue);
      },
    },

    //List Criteria
    listPrimaryContact: {
      get() {
        return this.listPrimaryContactValue;
      },
      set(listPrimaryContactValue) {
        this.$emit("listPrimaryContactInput", listPrimaryContactValue);
      },
    },
    listProfessionalEmail: {
      get() {
        return this.listProfessionalEmailValue;
      },
      set(listProfessionalEmailValue) {
        this.$emit("listProfessionalEmailInput", listProfessionalEmailValue);
      },
    },
    listDeterministicEmail: {
      get() {
        return this.listDeterministicEmailValue;
      },
      set(listDeterministicEmailValue) {
        this.$emit("listDeterministicEmailInput", listDeterministicEmailValue);
      },
    },
    listPhoneNumber: {
      get() {
        return this.listPhoneNumberValue;
      },
      set(listPhoneNumberValue) {
        this.$emit("listPhoneNumberInput", listPhoneNumberValue);
      },
    },
    showListEmailConfidenceScore: {
      get() {
        return this.showListEmailConfidenceScoreValue;
      },
      set(showListEmailConfidenceScoreValue) {
        this.$emit("showListEmailConfidenceScoreInput",showListEmailConfidenceScoreValue);
      },
    },
    showRealTimeEmailScore: {
      get() {
        return this.showRealTimeEmailScoreValue;
      },
      set(showRealTimeEmailScoreValue) {
        this.$emit("showRealTimeEmailScoreInput",showRealTimeEmailScoreValue);
      },  
    },
    listEmailConfidenceScore: {
      get() {
        return this.listEmailConfidenceScoreValue;
      },
      set(listEmailConfidenceScoreValue) {
        this.$emit("listEmailConfidenceScoreInput",listEmailConfidenceScoreValue);
      },
    },
    realTimeEmailScore:{
      get() {
        return this.realTimeEmailScoreValue;
      },
      set(realTimeEmailScoreValue) {
        this.$emit("realTimeEmailScoreInput",realTimeEmailScoreValue);
      },
    },

    userDefinedTag: {
      get() {
        return this.userDefinedTagValue;
      },
      set(userDefinedTagValue) {
        this.$emit("userDefinedTagInput",userDefinedTagValue);
      },
    },
    savedFrom: {
      get() {
        return this.savedFromValue;
      },
      set(savedFromValue) {
        this.$emit("savedFromInput",savedFromValue);
      },
    },
    savedBy:{
      get() {
        return this.savedByValue;
      },
      set(savedByValue) {
        this.$emit("savedByInput",savedByValue);
      },
    },
    // dateSavedRange:{
    //   get() {
    //     return this.savedDateValue;
    //   },
    //   set(savedDateValue) {
    //     this.$emit(
    //       "savedDateInput",
    //       savedDateValue
    //     );
    //   },
    // },
    // lastExportedDateRange:{
    //   get() {
    //     return this.lastExportedValue;
    //   },
    //   set(lastExportedValue) {
    //     this.$emit(
    //       "lastExportedInput",
    //       lastExportedValue
    //     );
    //   },
    // },
    excludeExported: {
      get() {
        return this.excludeExportedValue;
      },
      set(excludeExportedValue) {
        this.$emit("excludeExportedInput", excludeExportedValue);
      },
    },
  },
  data() {
    return {
      contactSaveddateRange: {
        startDate: null,
        endDate: null
      },
      lastExportedDateRange:{
        startDate: null,
        endDate: null
      },
      //Job Criteria
      showJobTitleText: false,
      showJobLevelOptions: false,
      showJobFunctionOptions: false,
      showJobStartDateOptions: false,

      //Company Criteria
      showCompanyNameText: false,
      showCompanyWebAddressText: false,
      showCompanyIndustryOptions: false,
      showCompanyEmployeeSizeOptions: false,
      showCompanyRevenueBandsOptions: false,
      showCompanyNAICSOptions: false,
      showCompanySICSOptions: false,

      //Location Criteria
      showContactRegionOptions: false,
      showAddressTypeOptions: false,
      showContactCountriesOptions: false,
      showContactSatesOptions: false,
      showContactCity: false,
      showContactZipCode: false,
      //list Criteria
      // showListEmailConfidenceScore:false,
      //Personal Criteria
      showPersonalContactName: false,
      showPersonalContactGenderOptions: false,
      showPersonalContactLastName: false,
      showPersonalContactBirthday: false,
      fromAgeErrorMessage: null,
      toAgeErrorMessage: null,
      showPersonalContactSkills: false,
      showPersonalContactFormerEmployer: false,
      // showPersonalContactMinimumConnection: false,

      //Suppression Criteria
      // showSuppressionLastUpdatedDateOptions: false,
      showSuppressionCompanyWebAddress: false,
      showSuppressionContactTitles: false,
      showSuppressionEmailAddress: false,

      mainActiveTab: 0,

      buttonName: "Save New Search",

      //save search
      savedSearch: [],
      savedSearchPopupActive: false,
      saveSearchPopupActive: false,
      isShared: false,
      searchName: "",
      deleteSearchSavedPopupActive: false,
      deleteSavedSearchId: null,
      deleteSavedSearchName: null,
      savedSearchId: null,
      // saved contact
      showTags: false,
      showDateSaved: false,
      showSavedFrom: false,
      showSavedBy:false,
      showLastExported:false,
      // showRealTimeEmailScore: false
    };
  },
  components: {
    "v-select": vSelect,
    InputTag,
    VueNumericInput,
    vueSlider,
    DateRangePicker,
  },
  created() {
        //  let fromDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
        // let fromDay = fromDate.getDate();
        // let fromMonth = fromDate.getMonth() + 1;
        // let fromYear = fromDate.getFullYear();

        // let toDate = new Date();
        // let toDay = toDate.getDate();
        // let toMonth = toDate.getMonth() + 1;
        // let toYear = toDate.getFullYear();

        // this.contactSaveddateRange.startDate = fromMonth + "/" + fromDay + "/" + fromYear;
        // this.contactSaveddateRange.endDate = toMonth + "/" + toDay + "/" + toYear;

        // this.lastExportedDateRange.startDate = fromMonth + "/" + fromDay + "/" + fromYear;
        // this.lastExportedDateRange.endDate = toMonth + "/" + toDay + "/" + toYear;
        this.contactSaveddateRange.startDate = this.$store.state.filterFromDate;
        this.contactSaveddateRange.endDate = this.$store.state.filterToDate;
        this.lastExportedDateRange.startDate = this.$store.state.filterFromDate;
        this.lastExportedDateRange.endDate = this.$store.state.filterToDate;
  },
  watch:{
    showDateSaved(){
        this.dateSavedUpdateValues(this.contactSaveddateRange);
    },
    showLastExported(){
      this.lastExportUpdateValues(this.lastExportedDateRange);
    }
  },
  methods: {
    fromAgeValidate() {
      if (this.personalContactBirthdayFrom > this.personalContactBirthdayTo) {
        this.fromAgeErrorMessage = "From age should be less than To age.";
      } else {
        this.fromAgeErrorMessage = null;
        this.toAgeErrorMessage = null;
      }
    },
    toAgeValidate() {
      if (this.personalContactBirthdayTo < this.personalContactBirthdayFrom) {
        this.toAgeErrorMessage = "To age should be greater than From age.";
      } else {
        this.fromAgeErrorMessage = null;
        this.toAgeErrorMessage = null;
      }
    },
    clearFirstAndLastName() {
      if (this.showPersonalContactName) {
        this.personalContactFirstName = null;
        this.personalContactLastName = null;
      }
    },
    showsaveSearchpopup() {
      this.saveSearchPopupActive = true;
    },
    listSavedSearch() {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSearchContacts/ListSavedSearches")
        .then((response) => {
          let data = response.data;
          this.savedSearch = data;
          this.savedSearchPopupActive = true;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    saveSearch() {
      //Job Criteria
      let isJobTitleEntered = false;
      let jobTitleEnteredParam = [];
      if (this.jobTitle.length > 0) {
        isJobTitleEntered = true;
        for (let i = 0; i < this.jobTitle.length; i++) {
          jobTitleEnteredParam.push({
            Text: this.jobTitle[i],
          });
        }
      }
      let isJobLevelSelected = false;
      let jobLevelSelectedParam = [];
      if (this.jobLevel.length > 0) {
        isJobLevelSelected = true;
        for (let i = 0; i < this.jobLevel.length; i++) {
          jobLevelSelectedParam.push({
            Text: this.jobLevel[i].Key,
          });
        }
      }
      let isjobFunctionSelected = false;
      let jobFunctionSelectedParam = [];
      if (this.jobFunction.length > 0) {
        isjobFunctionSelected = true;
        for (let i = 0; i < this.jobFunction.length; i++) {
          jobFunctionSelectedParam.push({
            Text: this.jobFunction[i].Key,
          });
        }
      }
      let isJobStartDateSelected = false;
      let jobStartDateSelectedParam = "";
      if (this.jobStartDate) {
        isJobStartDateSelected = true;
        jobStartDateSelectedParam = this.jobStartDate.Key;
      }

      //Company Criteria
      let isCompanyNameEntered = false;
      let companyNameEnteredParam = [];
      if (this.companyName.length > 0) {
        isCompanyNameEntered = true;
        for (let i = 0; i < this.companyName.length; i++) {
          companyNameEnteredParam.push({
            Text: this.companyName[i],
          });
        }
      }
      let isCompanyWebAddressEntered = false;
      let companyWebAddressEnteredParam = [];
      if (this.companyWebAddress.length > 0) {
        isCompanyWebAddressEntered = true;
        for (let i = 0; i < this.companyWebAddress.length; i++) {
          companyWebAddressEnteredParam.push({
            Text: this.companyWebAddress[i],
          });
        }
      }
      let isCompanyIndustrySelected = false;
      let companyIndustrySelectedParam = [];
      if (this.companyIndustry.length > 0) {
        isCompanyIndustrySelected = true;
        for (let i = 0; i < this.companyIndustry.length; i++) {
          companyIndustrySelectedParam.push({
            Text: this.companyIndustry[i].Key,
          });
        }
      }
      let isCompanyEmployeeSizeSelected = false;
      let companyEmployeeSizeSelectedParam = [];
      if (this.companyEmployeeSize.length > 0) {
        isCompanyEmployeeSizeSelected = true;
        for (let i = 0; i < this.companyEmployeeSize.length; i++) {
          companyEmployeeSizeSelectedParam.push({
            Text: this.companyEmployeeSize[i].Key,
          });
        }
      }
      let isCompanyRevenueBandsSelected = false;
      let companyRevenueBandsSelectedParam = [];
      if (this.companyRevenueBands.length > 0) {
        isCompanyRevenueBandsSelected = true;
        for (let i = 0; i < this.companyRevenueBands.length; i++) {
          companyRevenueBandsSelectedParam.push({
            Text: this.companyRevenueBands[i].Key,
          });
        }
      }
      let isCompanyNAICSSelected = false;
      let companyNAICSSelectedParam = [];
      if (this.companyNAICS.length > 0) {
        isCompanyNAICSSelected = true;
        for (let i = 0; i < this.companyNAICS.length; i++) {
          companyNAICSSelectedParam.push({
            Text: this.companyNAICS[i].Key,
          });
        }
      }
      let isCompanySICSSelected = false;
      let companySICSSelectedParam = [];
      if (this.companySICS.length > 0) {
        isCompanySICSSelected = true;
        for (let i = 0; i < this.companySICS.length; i++) {
          companySICSSelectedParam.push({
            Text: this.companySICS[i].Key,
          });
        }
      }
      //Location Criteria
      let isContactRegionSelected = false;
      let contactRegionSelectedParam = [];
      if (this.contactRegion.length > 0) {
        isContactRegionSelected = true;
        for (let i = 0; i < this.contactRegion.length; i++) {
          contactRegionSelectedParam.push({
            Text: this.contactRegion[i].Key,
          });
        }
      }
      let isAddressTypeSelected = false;
      let addressTypeSelectedParam = [];
      if (this.addressType.length > 0) {
        isAddressTypeSelected = true;
        for (let i = 0; i < this.addressType.length; i++) {
          addressTypeSelectedParam.push({
            Text: this.addressType[i].Key,
          });
        }
      }
      let isContactCountriesSelected = false;
      let contactCountriesSelectedParam = [];
      if (this.contactCountries.length > 0) {
        isContactCountriesSelected = true;
        for (let i = 0; i < this.contactCountries.length; i++) {
          contactCountriesSelectedParam.push({
            Text: this.contactCountries[i].Key,
          });
        }
      }
      let isContactStatesSelected = false;
      let contactStatesSelectedParam = [];
      if (this.contactStates.length > 0) {
        isContactStatesSelected = true;
        for (let i = 0; i < this.contactStates.length; i++) {
          contactStatesSelectedParam.push({
            Text: this.contactStates[i].Key,
          });
        }
      }
      let isContactCityEntered = false;
      let contactCityEnteredParam = [];
      if (this.contactCity.length > 0) {
        isContactCityEntered = true;
        for (let i = 0; i < this.contactCity.length; i++) {
          contactCityEnteredParam.push({
            Text: this.contactCity[i],
          });
        }
      }
      let isContactZipCodeEntered = false;
      let contactZipCodeEnteredParam = [];
      if (this.contactZipCode.length > 0) {
        isContactZipCodeEntered = true;
        for (let i = 0; i < this.contactZipCode.length; i++) {
          contactZipCodeEnteredParam.push({
            Text: this.contactZipCode[i],
          });
        }
      }
      // let localAddressRequiredParam = false;
      // if (this.localAddressRequired) {
      //   localAddressRequiredParam = this.localAddressRequired;
      // }
      //Personal Criteria
      let isFullNameEntered = false;
      let personalContactFirstNameEnteredParam = "";
      let personalContactLastNameEnteredParam = "";
      if (this.personalContactFirstName || this.personalContactLastName) {
        isFullNameEntered = true;
        personalContactFirstNameEnteredParam = this.personalContactFirstName;
        personalContactLastNameEnteredParam = this.personalContactLastName;
      }
      let isPersonalContactGenderSelected = false;
      let personalContactGenderSelectedParam = [];
      if (this.personalContactGender.length > 0) {
        isPersonalContactGenderSelected = true;
        for (let i = 0; i < this.personalContactGender.length; i++) {
          personalContactGenderSelectedParam.push({
            Text: this.personalContactGender[i].Key,
          });
        }
      }
      let isPersonalContactAlmaMaterEntered = false;
      let personalContactAlmaMaterEnteredParam = [];
      if (this.personalContactAlmaMater.length > 0) {
        isPersonalContactAlmaMaterEntered = true;
        for (let i = 0; i < this.personalContactAlmaMater.length; i++) {
          personalContactAlmaMaterEnteredParam.push({
            Text: this.personalContactAlmaMater[i],
          });
        }
      }
      let isBirthDateEntered = false;
      let personalContactBirthdayFromEnteredParam = "";
      let personalContactBirthdayToEnteredParam = "";
      if (this.personalContactBirthdayFrom || this.personalContactBirthdayTo) {
        isBirthDateEntered = true;
        personalContactBirthdayFromEnteredParam =
          this.personalContactBirthdayFrom;
        personalContactBirthdayToEnteredParam = this.personalContactBirthdayTo;
      }
      let isPersonalContactFormerEmployerEntered = false;
      let personalContactFormerEmployerEnteredParam = [];
      if (this.personalContactFormerEmployer.length > 0) {
        isPersonalContactFormerEmployerEntered = true;
        for (let i = 0; i < this.personalContactFormerEmployer.length; i++) {
          personalContactFormerEmployerEnteredParam.push({
            Text: this.personalContactFormerEmployer[i],
          });
        }
      }
      let isPersonalContactSkillsEntered = false;
      let personalContactSkillsEnteredParam = [];
      if (this.personalContactSkills.length > 0) {
        isPersonalContactSkillsEntered = true;
        for (let i = 0; i < this.personalContactSkills.length; i++) {
          personalContactSkillsEnteredParam.push({
            Text: this.personalContactSkills[i],
          });
        }
      }
      // let isPersonalContactMinimumConnectionEntered = false;
      // let personalContactMinimumConnectionEnteredParam = [];
      // if (this.personalContactMinimumConnection) {
      //   isPersonalContactMinimumConnectionEntered = true;
      //   personalContactMinimumConnectionEnteredParam.push({
      //     Text: this.personalContactMinimumConnection,
      //   });
      // }
      let personalContactEmailAddressRequiredParam = false;
      if (this.personalContactEmailAddressRequired) {
        personalContactEmailAddressRequiredParam =
          this.personalContactEmailAddressRequired;
      }
      let personalContactPhoneRequiredParam = false;
      if (this.personalContactPhoneRequired) {
        personalContactPhoneRequiredParam = this.personalContactPhoneRequired;
      }
      //Suppression Criteria
      // let isSuppressionLastUpdatedDateSelected = false;
      // let suppressionLastUpdatedDateSelectedParam = "";

      // if (this.suppressionLastUpdatedDate) {
      //   isSuppressionLastUpdatedDateSelected = true;
      //   suppressionLastUpdatedDateSelectedParam = this.suppressionLastUpdatedDate.Key;
      // }

      let isSuppressionCompanyWebAddressEntered = false;
      let suppressionCompanyWebAddressEnteredParam = [];
      if (this.suppressionCompanyWebAddress.length > 0) {
        isSuppressionCompanyWebAddressEntered = true;
        for (let i = 0; i < this.suppressionCompanyWebAddress.length; i++) {
          suppressionCompanyWebAddressEnteredParam.push({
            Text: this.suppressionCompanyWebAddress[i],
          });
        }
      }
      let isSuppressionContactTitlesEntered = false;
      let suppressionContactTitlesEnteredParam = [];
      if (this.suppressionContactTitles.length > 0) {
        isSuppressionContactTitlesEntered = true;
        for (let i = 0; i < this.suppressionContactTitles.length; i++) {
          suppressionContactTitlesEnteredParam.push({
            Text: this.suppressionContactTitles[i],
          });
        }
      }
      let isSuppressionEmailAddressEntered = false;
      let suppressionEmailAddressEnteredParam = [];
      if (this.suppressionEmailAddress.length > 0) {
        isSuppressionEmailAddressEntered = true;
        for (let i = 0; i < this.suppressionEmailAddress.length; i++) {
          suppressionEmailAddressEnteredParam.push({
            Text: this.suppressionEmailAddress[i],
          });
        }
      }
      let suppressionExcludeMyLeadsParam = false;
      if (this.suppressionExcludeMyLeads) {
        suppressionExcludeMyLeadsParam = this.suppressionExcludeMyLeads;
      }
      //List Criteria
      let listPrimaryContactParam = false;
      if (this.listPrimaryContact) {
        listPrimaryContactParam = this.listPrimaryContact;
      }
      let listProfessionalEmailParam = false;
      if (this.listProfessionalEmail) {
        listProfessionalEmailParam = this.listProfessionalEmail;
      }
      let listEmailConfidenceScoreParam = this.listEmailConfidenceScore;
      let realTimeEmailScoreParam = this.realTimeEmailScore;
      // console.log(this.realTimeEmailScore);
      let listDeterministicEmailParam = false;
      if (this.listDeterministicEmail) {
        listDeterministicEmailParam = this.listDeterministicEmail;
      }
      let listPhoneNumberParam = false;
      if (this.listPhoneNumber) {
        listPhoneNumberParam = this.listPhoneNumber;
      }
      let recentChangedJobParam = false;
      if (this.recentChangedJob) {
        recentChangedJobParam = this.recentChangedJob;
      }

      let isUserDefinedTags = false;
      let userDefinedTags = [];
      let isSavedFrom = false;
      let contactSavedFrom = [];
      let isSavedBy = false;
      let contactSavedBy = [];
      let isExportExclude = false;
      let isContactDateSaved = false;
      let contactDateSavedFrom = "01/01/2000 00:00";
      let contactDateSavedTo = "01/01/2000 00:00";
      let contactDateSaveType ="";
      let isContactLastExport = false;
      let contactLastExportFrom = "01/01/2000 00:00";
      let contactLastExportTo = "01/01/2000 00:00";
      let contactLastExportType = "";
      if(this.moduleName == 'SavedContact'){
        if (this.userDefinedTag.length > 0) {
          isUserDefinedTags = true;
          for (let i = 0; i < this.userDefinedTag.length; i++) {
            userDefinedTags.push(
              this.userDefinedTag[i].TagName,
            );
          }
        }

        if (this.savedFrom.length > 0) {
          isSavedFrom = true;
          for (let i = 0; i < this.savedFrom.length; i++) {
            contactSavedFrom.push(
              this.savedFrom[i].Key,
            );
          }
        }
        
        if (this.savedBy.length > 0) {
          isSavedBy = true;
          for (let i = 0; i < this.savedBy.length; i++) {
            contactSavedBy.push(
                  this.savedBy[i].Key,
            );
          }
        }

        if(this.excludeExported){
          isExportExclude = this.excludeExported
        }

        if(this.showDateSaved){
          isContactDateSaved = true;
          contactDateSaveType = this.checkDateRanges(moment(this.contactSaveddateRange.startDate).format("MM/DD/YYYY"),moment(this.contactSaveddateRange.endDate).format("MM/DD/YYYY"));
          if(contactDateSaveType ==='custom range'){
              contactDateSaveType ="";
              contactDateSavedFrom = moment(this.contactSaveddateRange.startDate).format("MM/DD/YYYY");
              contactDateSavedTo = moment(this.contactSaveddateRange.toDate).format("MM/DD/YYYY");
          }
        }
            
        if(this.showLastExported){
          isContactLastExport = true;
          contactLastExportType = this.checkDateRanges(moment(this.lastExportedDateRange.startDate).format("MM/DD/YYYY"),moment(this.lastExportedDateRange.endDate).format("MM/DD/YYYY"));
          if(contactLastExportType =='custom range') {
              contactLastExportType = "";
              contactLastExportFrom = moment(this.lastExportedDateRange.startDate).format("MM/DD/YYYY");
              contactLastExportTo = moment(this.lastExportedDateRange.endDate).format("MM/DD/YYYY");
          }
        }
      }
      let saveSearchParams = {
        Id: this.savedSearchId
          ? this.savedSearchId
          : "000000000000000000000000",
        Saved_Search_Name: this.searchName,
        IsShared: this.isShared,
        Search_Criteria: {
          AlmaMater: personalContactAlmaMaterEnteredParam,
          Cities: contactCityEnteredParam,
          CompanyIndustries: companyIndustrySelectedParam,
          CompanyNAICSCodes: companyNAICSSelectedParam,
          CompanyNames: companyNameEnteredParam,
          CompanyRevenueIds: companyRevenueBandsSelectedParam,
          CompanySICCodes: companySICSSelectedParam,
          CompanySizeIds: companyEmployeeSizeSelectedParam,
          ContactJobFunctions: jobFunctionSelectedParam,
          ContactJobStartDate: jobStartDateSelectedParam,
          ContactLevels: jobLevelSelectedParam,
          ContactSkills: personalContactSkillsEnteredParam,
          ContactTitles: jobTitleEnteredParam,
          Countries: contactCountriesSelectedParam,
          DeterministicEmailsOnly: listDeterministicEmailParam,
          HasPhone: listPhoneNumberParam,

          EmailsToSuppress: suppressionEmailAddressEnteredParam,
          FirstName: personalContactFirstNameEnteredParam,
          FormerEmployer: personalContactFormerEmployerEnteredParam,
          FromAge: isNaN(parseInt(personalContactBirthdayFromEnteredParam))
            ? 0
            : parseInt(personalContactBirthdayFromEnteredParam),
          Gender: personalContactGenderSelectedParam,
          // HasLocalAddress: localAddressRequiredParam,
          IsCellPhoneRequired: personalContactPhoneRequiredParam,
          IsAlmaMaterSelected: isPersonalContactAlmaMaterEntered,
          IsBirthDateSelected: isBirthDateEntered,
          IsCitiesSelected: isContactCityEntered,
          IsCompanyIndustriesSelected: isCompanyIndustrySelected,
          IsCompanyNAICSCodesSelected: isCompanyNAICSSelected,
          IsCompanyNamesSelected: isCompanyNameEntered,
          IsCompanyRevenuesSelected: isCompanyRevenueBandsSelected,
          IsCompanySICCodesSelected: isCompanySICSSelected,
          IsCompanySizesSelected: isCompanyEmployeeSizeSelected,
          IsContactJobFunctionsSelected: isjobFunctionSelected,
          IsContactJobStartDateSelected: isJobStartDateSelected,
          IsContactLevelsSelected: isJobLevelSelected,
          IsContactSkillsSelected: isPersonalContactSkillsEntered,
          IsContactTitlesSelected: isJobTitleEntered,
          IsCountrySelected: isContactCountriesSelected,
          PersonalEmailOnly: personalContactEmailAddressRequiredParam,
          IsEmailsToSuppressSelected: isSuppressionEmailAddressEntered,
          IsExcludeMyLeadsSelected: suppressionExcludeMyLeadsParam,
          IsFormerEmployerSelected: isPersonalContactFormerEmployerEntered,
          IsFullNameSelected: isFullNameEntered,
          IsGenderSelected: isPersonalContactGenderSelected,
          // IsLastUpdatedSelected: isSuppressionLastUpdatedDateSelected,
          IsLimitPerCompany: true,
          IsLimitPerListSelected: true,
          // IsNumberOfConnectionsSelected: isPersonalContactMinimumConnectionEntered,
          IsRegionSelected: isContactRegionSelected,
          IsAddressTypeSelected: isAddressTypeSelected,
          IsStatesSelected: isContactStatesSelected,
          IsTitlesToSuppressSelected: isSuppressionContactTitlesEntered,
          IsURLsSelected: isCompanyWebAddressEntered,
          IsUrlsToSuppressSelected: isSuppressionCompanyWebAddressEntered,
          IsUseEmailSuppressionFileSelected: true,
          IsUseTitleSuppressionFileSelected: true,
          IsUseUrlSuppressionFileSelected: true,
          IsZipCodesSelected: isContactZipCodeEntered,
          LastName: personalContactLastNameEnteredParam,
          // LastUpdatedInMonths: suppressionLastUpdatedDateSelectedParam,
          IsEmailConfidenceSelected: this.showListEmailConfidenceScore,
          EmailConfidenceScore: listEmailConfidenceScoreParam,
          IsContactRTScoreSelected: this.showRealTimeEmailScore,
          ContactRTScore: realTimeEmailScoreParam,

          //saved criteria 
           IsContactSavedTagsSelected:isUserDefinedTags,
            ContactSavedTags:userDefinedTags,
            IsContactSavedDateSelected:isContactDateSaved,
            ContactSavedDateFrom:contactDateSavedFrom,
            ContactSavedDateTo:contactDateSavedTo,
            IsContactLastExportedSelected:isContactLastExport,
            ContactLastExportedDateFrom:contactLastExportFrom,
            ContactLastExportedDateTo:contactLastExportTo,
            IsExcludeContactLastExportedSelected:isExportExclude,
            IsContactSavedFromSelected:isSavedFrom,
            ContactSavedFrom:contactSavedFrom,
            IsContactSavedBySelected:isSavedBy,
            ContactSavedBy:contactSavedBy,
            ContactLastExportedDateDynamicType:contactLastExportType,
            ContactSaveDateDynamicType:contactDateSaveType,

          LimitPerCompanyCount: 0,
          LimitPerList: 0,
          // NumberOfConnections: personalContactMinimumConnectionEnteredParam,
          Page: 1,
          PageSize: 10,
          IsProfessionalEmailRequired: listProfessionalEmailParam,
          PrimaryContactsOnly: listPrimaryContactParam,
          Regions: contactRegionSelectedParam,
          AddressTypes: addressTypeSelectedParam,
          RequiredEmail: true,
          States: contactStatesSelectedParam,
          TitlesToSuppress: suppressionContactTitlesEnteredParam,
          ToAge: isNaN(parseInt(personalContactBirthdayToEnteredParam))
            ? 0
            : parseInt(personalContactBirthdayToEnteredParam),
          Urls: companyWebAddressEnteredParam,
          UrlsToSuppress: suppressionCompanyWebAddressEnteredParam,
          UseAllSuppressionRecords: true,
          ValidateEmails: true,
          ZipCodes: contactZipCodeEnteredParam,
          EmailAddress: [],
          HasChangedJobsRecently: recentChangedJobParam,
          DirectDialOnly: false,
          IsEmailAddressSelected: false,
        },
      };
      this.$vs.loading();
      this.axios
        .post(
          this.savedSearchId
            ? "/ws/FindAnyoneSearchContacts/UpdateSavedSearch"
            : "/ws/FindAnyoneSearchContacts/AddSavedSearch",
          saveSearchParams
        )
        .then((response) => {
          this.buttonName = "Save New Search";
          let data = response.data;
          if (data.TransactionSuccessful) {
            let msg;
            if (this.savedSearchId) {
              msg = "Saved Search updated successfully";
            } else {
              msg = "Saved Search saved successfully";
            }
            this.saveSearchPopupActive = false;
            this.searchName = null;
            this.savedSearchId = null;
            this.$vs.notify({
              title: "Success",
              text: msg,
              color: "success",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
              time: 4000,
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: data.Message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
              time: 4000,
            });
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    confirmationDeleteSavedSearch(Id, name) {
      this.deleteSearchSavedPopupActive = true;
      this.deleteSavedSearchName = name;
      this.deleteSavedSearchId = Id;
    },
    deleteSavedSearch() {
      this.$vs.loading();
      this.axios
        .get(
          "ws/FindAnyoneSearchContacts/DeleteSavedSearch?id=" +
            this.deleteSavedSearchId
        )
        .then(() => {
          this.deleteSearchSavedPopupActive = false;
          this.deleteSavedSearchName = null;
          this.deleteSavedSearchId = null;
          this.listSavedSearch();
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Success",
            text: "Saved search deleted successfully",
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    cancelsaveSearch() {
      this.saveSearchPopupActive = false;
    },
    editSavedSearch(data) {
      this.savedSearchPopupActive = false;
      this.buttonName = "Save Changes";
      this.searchName = data.Saved_Search_Name;
      this.isShared = data.IsShared;
      this.savedSearchId = data.Id;

      let AlmaMater = [];
      if (data.Search_Criteria.AlmaMater.length > 0) {
        data.Search_Criteria.AlmaMater.forEach((e) => {
          AlmaMater.push(e.Text);
        });
        this.personalContactAlmaMater = AlmaMater;
      }
      let cities = [];
      if (data.Search_Criteria.Cities.length > 0) {
        data.Search_Criteria.Cities.forEach((e) => {
          cities.push(e.Text);
        });
        this.contactCity = cities;
      }
      let CompanyIndustries = [];
      if (data.Search_Criteria.CompanyIndustries.length > 0) {
        data.Search_Criteria.CompanyIndustries.forEach((e) => {
          this.companyIndustryOptions.forEach((ci) => {
            if (e.Text == ci.Key) {
              CompanyIndustries.push(ci);
            }
          });
        });
        this.companyIndustry = CompanyIndustries;
      }
      let CompanyNAICSCodes = [];
      if (data.Search_Criteria.CompanyNAICSCodes.length > 0) {
        data.Search_Criteria.CompanyNAICSCodes.forEach((e) => {
          this.companyNAICSOptions.forEach((cc) => {
            if (cc.Key == e.Text) {
              CompanyNAICSCodes.push(cc);
            }
          });
        });
        this.companyNAICS = CompanyNAICSCodes;
      }
      let CompanyNames = [];
      if (data.Search_Criteria.CompanyNames.length > 0) {
        data.Search_Criteria.CompanyNames.forEach((e) => {
          CompanyNames.push(e.Text);
        });
        this.companyName = CompanyNames;
      }
      let CompanyRevenueIds = [];
      if (data.Search_Criteria.CompanyRevenueIds.length > 0) {
        data.Search_Criteria.CompanyRevenueIds.forEach((e) => {
          this.companyRevenueBandsOptions.forEach((crb) => {
            if (crb.Key == e.Text) {
              CompanyRevenueIds.push(crb);
            }
          });
        });
        this.companyRevenueBands = CompanyRevenueIds;
      }

      let CompanySICCodes = [];
      if (data.Search_Criteria.CompanySICCodes.length > 0) {
        data.Search_Criteria.CompanySICCodes.forEach((e) => {
          this.companySICSOptions.forEach((cSICs) => {
            if (cSICs.Key == e.Text) {
              CompanySICCodes.push(cSICs);
            }
          });
        });
        this.companySICS = CompanySICCodes;
      }
      let CompanySizeIds = [];
      if (data.Search_Criteria.CompanySizeIds.length > 0) {
        data.Search_Criteria.CompanySizeIds.forEach((e) => {
          this.companyEmployeeSizeOptions.forEach((ces) => {
            if (ces.Key == e.Text) {
              CompanySizeIds.push(ces);
            }
          });
        });
        this.companyEmployeeSize = CompanySizeIds;
      }
      let ContactJobFunctions = [];
      if (data.Search_Criteria.ContactJobFunctions.length > 0) {
        data.Search_Criteria.ContactJobFunctions.forEach((e) => {
          this.jobFunctionOptions.forEach((jf) => {
            if (jf.Key == e.Text) {
              ContactJobFunctions.push(jf);
            }
          });
        });
        this.jobFunction = ContactJobFunctions;
      }
      let ContactLevels = [];
      if (data.Search_Criteria.ContactLevels.length > 0) {
        data.Search_Criteria.ContactLevels.forEach((cl) => {
          this.jobLevelOptions.forEach((e) => {
            if (e.Key == cl.Text) {
              ContactLevels.push(e);
            }
          });
        });
        this.jobLevel = ContactLevels;
      }
      let ContactSkills = [];
      if (data.Search_Criteria.ContactSkills.length > 0) {
        data.Search_Criteria.ContactSkills.forEach((e) => {
          ContactSkills.push(e.Text);
        });
        this.personalContactSkills = ContactSkills;
      }
      let ContactTitles = [];
      if (data.Search_Criteria.ContactTitles.length > 0) {
        data.Search_Criteria.ContactTitles.forEach((e) => {
          ContactTitles.push(e.Text);
        });
        this.jobTitle = ContactTitles;
      }
      let Countries = [];
      if (data.Search_Criteria.Countries.length > 0) {
        data.Search_Criteria.Countries.forEach((e) => {
          this.contactCountriesOptions.forEach((con) => {
            if (con.Key == e.Text) {
              Countries.push(con);
            }
          });
        });
        this.contactCountries = Countries;
      }
      this.listDeterministicEmail =
        data.Search_Criteria.DeterministicEmailsOnly;
      this.listPhoneNumber = data.Search_Criteria.HasPhone;
      let EmailsToSuppress = [];
      if (data.Search_Criteria.EmailsToSuppress.length > 0) {
        data.Search_Criteria.EmailsToSuppress.forEach((e) => {
          EmailsToSuppress.push(e.Text);
        });
        this.suppressionEmailAddress = EmailsToSuppress;
      }
      this.personalContactFirstName = data.Search_Criteria.FirstName;
      let FormerEmployer = [];
      if (data.Search_Criteria.FormerEmployer.length > 0) {
        data.Search_Criteria.FormerEmployer.forEach((e) => {
          FormerEmployer.push(e.Text);
        });
        this.personalContactFormerEmployer = FormerEmployer;
      }
      this.personalContactBirthdayFrom = data.Search_Criteria.FromAge;

      let Gender = [];
      if (data.Search_Criteria.Gender.length > 0) {
        data.Search_Criteria.Gender.forEach((e) => {
          this.personalContactGenderOptions.forEach((gen) => {
            if (gen.Key == e.Text) {
              Gender.push(gen);
            }
          });
        });
        this.personalContactGender = Gender;
      }
      this.recentChangedJob = data.Search_Criteria.HasChangedJobsRecently;
      // this.localAddressRequired = data.Search_Criteria.HasLocalAddress;
      this.personalContactPhoneRequired =
        data.Search_Criteria.IsCellPhoneRequired;

      this.jobStartDate = data.Search_Criteria.IsContactJobStartDateSelected;
      this.personalContactEmailAddressRequired =
        data.Search_Criteria.PersonalEmailOnly;
      this.suppressionExcludeMyLeads =
        data.Search_Criteria.IsExcludeMyLeadsSelected;
      this.IsZipCodesSelected = data.Search_Criteria.IsZipCodesSelected;
      this.personalContactLastName = data.Search_Criteria.LastName;
      // if(data.Search_Criteria.LastUpdatedInMonths){
      //   this.suppressionLastUpdatedDateOptions.forEach(e=>{
      //     if(e.Key == data.Search_Criteria.LastUpdatedInMonths){
      //       this.suppressionLastUpdatedDate = e;
      //     }
      //   });
      // }
      this.listProfessionalEmail =
        data.Search_Criteria.IsProfessionalEmailRequired;
      // if(data.Search_Criteria.NumberOfConnections.length >0){
      //   this.personalContactMinimumConnection =data.Search_Criteria.NumberOfConnections[0].Text;
      // }
      this.listProfessionalEmail =
        data.Search_Criteria.IsProfessionalEmailRequired;
      this.showListEmailConfidenceScore =
        data.Search_Criteria.IsEmailConfidenceSelected;
      this.listEmailConfidenceScore = data.Search_Criteria.EmailConfidenceScore;
      this.showRealTimeEmailScore = data.Search_Criteria.IsContactRTScoreSelected;
      this.realTimeEmailScore = data.Search_Criteria.ContactRTScore;
      this.listPrimaryContact = data.Search_Criteria.PrimaryContactsOnly;
      let Regions = [];
      if (data.Search_Criteria.Regions.length > 0) {
        data.Search_Criteria.Regions.forEach((e) => {
          this.contactRegionOptions.forEach((cr) => {
            if (cr.Key == e.Text) {
              Regions.push(cr);
            }
          });
        });
        this.contactRegion = Regions;
      }
      let AddressTypes = [];
      if (data.Search_Criteria.AddressTypes.length > 0) {
        data.Search_Criteria.AddressTypes.forEach((e) => {
          this.addressTypeOptions.forEach((cr) => {
            if (cr.Key == e.Text) {
              AddressTypes.push(cr);
            }
          });
        });
        this.addressType = AddressTypes;
      }
      let States = [];
      if (data.Search_Criteria.States.length > 0) {
        data.Search_Criteria.States.forEach((e) => {
          this.contactStatesOptions.forEach((cs) => {
            if (cs.Key == e.Text) {
              States.push(cs);
            }
          });
        });
        this.contactStates = States;
      }
      let TitlesToSuppress = [];
      if (data.Search_Criteria.TitlesToSuppress.length > 0) {
        data.Search_Criteria.TitlesToSuppress.forEach((e) => {
          TitlesToSuppress.push(e.Text);
        });
        this.suppressionContactTitles = TitlesToSuppress;
      }
      this.personalContactBirthdayTo = data.Search_Criteria.ToAge;
      let Urls = [];
      if (data.Search_Criteria.Urls.length > 0) {
        data.Search_Criteria.Urls.forEach((e) => {
          Urls.push(e.Text);
        });
        this.companyWebAddress = Urls;
      }
      let UrlsToSuppress = [];
      if (data.Search_Criteria.UrlsToSuppress.length > 0) {
        data.Search_Criteria.UrlsToSuppress.forEach((e) => {
          UrlsToSuppress.push(e.Text);
        });
        this.suppressionCompanyWebAddress = UrlsToSuppress;
      }
      let ZipCodes = [];
      if (data.Search_Criteria.ZipCodes.length > 0) {
        data.Search_Criteria.ZipCodes.forEach((e) => {
          ZipCodes.push(e.Text);
        });
        this.contactZipCode = ZipCodes;
      }

      //Job Criteria
      this.showJobTitleText = data.Search_Criteria.IsContactTitlesSelected;
      this.showJobLevelOptions = data.Search_Criteria.IsContactLevelsSelected;
      this.showJobFunctionOptions =
        data.Search_Criteria.IsContactJobFunctionsSelected;
      this.showJobStartDateOptions =
        data.Search_Criteria.IsContactJobStartDateSelected;

      //Company Criteria
      this.showCompanyNameText = data.Search_Criteria.IsCompanyNamesSelected;
      this.showCompanyWebAddressText = data.Search_Criteria.IsURLsSelected;
      this.showCompanyIndustryOptions =
        data.Search_Criteria.IsCompanyIndustriesSelected;
      this.showCompanyEmployeeSizeOptions =
        data.Search_Criteria.IsCompanySizesSelected;
      this.showCompanyRevenueBandsOptions =
        data.Search_Criteria.IsCompanyRevenuesSelected;
      this.showCompanyNAICSOptions =
        data.Search_Criteria.IsCompanyNAICSCodesSelected;
      this.showCompanySICSOptions =
        data.Search_Criteria.IsCompanySICCodesSelected;

      //Location Criteria
      this.showContactRegionOptions = data.Search_Criteria.IsRegionSelected;
      this.showAddressTypeOptions = data.Search_Criteria.IsAddressTypeSelected;
      this.showContactCountriesOptions = data.Search_Criteria.IsCountrySelected;
      this.showContactSatesOptions = data.Search_Criteria.IsStatesSelected;
      this.showContactCity = data.Search_Criteria.IsCitiesSelected;
      this.showContactZipCode = data.Search_Criteria.IsZipCodesSelected;

      //Personal Criteria
      this.showPersonalContactName = data.Search_Criteria.IsFullNameSelected;
      this.showPersonalContactGenderOptions =
        data.Search_Criteria.IsGenderSelected;
      this.showPersonalContactLastName =
        data.Search_Criteria.IsAlmaMaterSelected;
      this.showPersonalContactBirthday =
        data.Search_Criteria.IsBirthDateSelected;
      this.showPersonalContactSkills =
        data.Search_Criteria.IsContactSkillsSelected;
      this.showPersonalContactFormerEmployer =
        data.Search_Criteria.IsFormerEmployerSelected;
      // this.showPersonalContactMinimumConnection =
      //   data.Search_Criteria.IsNumberOfConnectionsSelected;

      //Suppression Criteria
      // this.showSuppressionLastUpdatedDateOptions =
      //   data.Search_Criteria.IsLastUpdatedSelected;
      this.showSuppressionCompanyWebAddress =
        data.Search_Criteria.IsUrlsToSuppressSelected;
      this.showSuppressionContactTitles =
        data.Search_Criteria.IsTitlesToSuppressSelected;
      this.showSuppressionEmailAddress =
        data.Search_Criteria.IsEmailsToSuppressSelected;
      // let ContactJobStartDate = data.Search_Criteria.ContactJobStartDate;
      // this.jobStartDate.Key = ContactJobStartDate;
      if (data.Search_Criteria.ContactJobStartDate) {
        this.jobStartDateOptions.forEach((e) => {
          if (e.Key == data.Search_Criteria.ContactJobStartDate) {
            this.jobStartDate = e;
          }
        });
      }
      //saved criteria 
      if(this.moduleName == 'SavedContact'){

        this.showTags = data.Search_Criteria.IsContactSavedTagsSelected;
        let userDefinedTag = [];
        if (data.Search_Criteria.ContactSavedTags.length > 0) {
          data.Search_Criteria.ContactSavedTags.forEach((e) => {
            this.userDefinedTagsOptions.forEach((cs) => {
              if (cs.TagName == e) {
                userDefinedTag.push(cs);
              }
            });
          });
          this.userDefinedTag = userDefinedTag;
        }
        this.showSavedFrom = data.Search_Criteria.IsContactSavedFromSelected;
        let savedFrom = [];
        if (data.Search_Criteria.ContactSavedFrom.length > 0) {
          data.Search_Criteria.ContactSavedFrom.forEach((e) => {
            this.savedFromOptions.forEach((cs) => {
              if (cs.Key == e) {
                savedFrom.push(cs);
              }
            });
          });
          this.savedFrom = savedFrom;
        }
        this.showSavedBy = data.Search_Criteria.IsContactSavedBySelected;
        let savedBy = [];
        if (data.Search_Criteria.ContactSavedBy.length > 0) {
          data.Search_Criteria.ContactSavedBy.forEach((e) => {
            this.savedByOptions.forEach((cs) => {
              if (cs.Key == e) {
                savedBy.push(cs);
              }
            });
          });
          this.savedBy = savedBy;
        }
        this.excludeExported = data.Search_Criteria.IsExcludeContactLastExportedSelected;
        
        this.showDateSaved = data.Search_Criteria.IsContactSavedDateSelected;
        if(data.Search_Criteria.ContactSaveDateDynamicType){
          let dateSavedRange = data.Search_Criteria.ContactSaveDateDynamicType;
          let date = new Date();
          if(dateSavedRange === 'this month'){
            let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            let fromDay = firstDay.getDate();
            let fromMonth = firstDay.getMonth() + 1;
            let fromYear = firstDay.getFullYear();
            let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            let toDay = lastDay.getDate();
            let toMonth = lastDay.getMonth() + 1;
            let toYear = lastDay.getFullYear();
             this.contactSaveddateRange.startDate = fromMonth + "/" + fromDay + "/" + fromYear;
             this.contactSaveddateRange.endDate = toMonth + "/" + toDay + "/" + toYear;
           
          } else if(dateSavedRange == 'last month'){
            let firstDayPrevMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
            let lastDayPrevMonth = new Date(date.getFullYear(), date.getMonth(), 0);
            this.contactSaveddateRange.startDate = moment(firstDayPrevMonth, 'MM/DD/YYYY').format('MM/DD/YYYY');
            this.contactSaveddateRange.endDate = moment(lastDayPrevMonth,'MM/DD/YYYY').format('MM/DD/YYYY');
          } else if(dateSavedRange == 'this year'){
            let theFirstYear = new Date(date.getFullYear(), 0, 1);
            let theLastYear = new Date(date.getFullYear(), 11, 31);
            this.contactSaveddateRange.startDate = moment(theFirstYear, 'MM/DD/YYYY').format('MM/DD/YYYY');
            this.contactSaveddateRange.endDate = moment(theLastYear,'MM/DD/YYYY').format('MM/DD/YYYY');
          } else if(dateSavedRange == 'yesterday'){
            let yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            this.contactSaveddateRange.startDate = moment(yesterday, 'MM/DD/YYYY').format('MM/DD/YYYY');
            this.contactSaveddateRange.endDate = moment(yesterday,'MM/DD/YYYY').format('MM/DD/YYYY');
          } else {
            this.contactSaveddateRange.startDate = moment(date, 'MM/DD/YYYY').format('MM/DD/YYYY');
            this.contactSaveddateRange.endDate = moment(date,'MM/DD/YYYY').format('MM/DD/YYYY');
          }
          this.dateSavedUpdateValues(this.contactSaveddateRange);
        }
        else {
          //let contactSaved = data.Search_Criteria.ContactSavedDateFrom;
          this.contactSaveddateRange.endDate = moment(new Date(data.Search_Criteria.ContactSavedDateTo),'MM/DD/YYYY').format('MM/DD/YYYY');
          this.contactSaveddateRange.startDate = moment(new Date(data.Search_Criteria.ContactSavedDateFrom), 'MM/DD/YYYY').format('MM/DD/YYYY');
          this.dateSavedUpdateValues(this.contactSaveddateRange);
        }
  
        this.showLastExported = data.Search_Criteria.IsContactLastExportedSelected;
        if(data.Search_Criteria.ContactLastExportedDateDynamicType){
         let dateSavedRange = data.Search_Criteria.ContactLastExportedDateDynamicType;
           let date = new Date();
          if(dateSavedRange === 'this month'){
            let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            this.lastExportedDateRange.startDate = moment(firstDay,'MM/DD/YYYY').format('MM/DD/YYYY');
            this.lastExportedDateRange.endDate = moment(lastDay,'MM/DD/YYYY').format('MM/DD/YYYY');
          } else if(dateSavedRange == 'last month'){
            let firstDayPrevMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
            let lastDayPrevMonth = new Date(date.getFullYear(), date.getMonth(), 0);
             this.lastExportedDateRange.startDate = moment(firstDayPrevMonth,'MM/DD/YYYY').format('MM/DD/YYYY');
            this.lastExportedDateRange.endDate = moment(lastDayPrevMonth,'MM/DD/YYYY').format('MM/DD/YYYY');
          } else if(dateSavedRange == 'this year'){
            let theFirstYear = new Date(date.getFullYear(), 0, 1);
            let theLastYear = new Date(date.getFullYear(), 11, 31);
            this.lastExportedDateRange.startDate = moment(theFirstYear,'MM/DD/YYYY').format('MM/DD/YYYY');
            this.lastExportedDateRange.endDate = moment(theLastYear,'MM/DD/YYYY').format('MM/DD/YYYY');
          } else if(dateSavedRange == 'yesterday'){
            let yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            this.lastExportedDateRange.startDate = moment(yesterday,'MM/DD/YYYY').format('MM/DD/YYYY');
            this.lastExportedDateRange.endDate = moment(yesterday,'MM/DD/YYYY').format('MM/DD/YYYY');
          } else {
            this.lastExportedDateRange.startDate = moment(date,'MM/DD/YYYY').format('MM/DD/YYYY');
            this.lastExportedDateRange.endDate = moment(date,'MM/DD/YYYY').format('MM/DD/YYYY');
          }
            this.lastExportUpdateValues(this.lastExportedDateRange);
        } else {
          this.lastExportedDateRange.startDate = moment(new Date(data.Search_Criteria.ContactLastExportedDateFrom), 'MM/DD/YYYY').format('MM/DD/YYYY');
            this.lastExportedDateRange.endDate = moment(new Date(data.Search_Criteria.ContactLastExportedDateTo),'MM/DD/YYYY').format('MM/DD/YYYY');
            this.lastExportUpdateValues(this.lastExportedDateRange);
        }
      }
    },
    checkDateRanges(start,end){
          let selectedStart = start;
          let selectedEnd = end;
          let dateRanges = this.$refs.lastExportedDatePicker?this.$refs.lastExportedDatePicker.ranges:this.$refs.dateSavedDatePicker.ranges;
          if((moment(dateRanges['This month'][0]).format("MM/DD/YYYY") == selectedStart) && moment(dateRanges['This month'][1]).format("MM/DD/YYYY") == selectedEnd){
            return "this month";
          } else if((moment(dateRanges['Last month'][0]).format("MM/DD/YYYY") == selectedStart) && moment(dateRanges['Last month'][1]).format("MM/DD/YYYY") == selectedEnd){
            return "last month";
          } else if(moment(dateRanges['This year'][0]).format("MM/DD/YYYY") == selectedStart && moment(dateRanges['This year'][1]).format("MM/DD/YYYY") == selectedEnd){
            return "this year";
          } else if(moment(dateRanges['Today'][0]).format("MM/DD/YYYY") == selectedStart && moment(dateRanges['Today'][1]).format("MM/DD/YYYY") == selectedEnd){
            return "today";
          } else if(moment(dateRanges['Yesterday'][0]).format("MM/DD/YYYY") == selectedStart && moment(dateRanges['Yesterday'][1]).format("MM/DD/YYYY") == selectedEnd){
            return "yesterday";
          } else {
            return "custom range";
          }
    },
    lastExportUpdateValues(values){
    //  this.lastExportedValue = values;
      this.$emit(
          "lastExportedInput",
          values
        );
    },
    dateSavedUpdateValues(values){
      //this.savedDateValue = values;
      this.$emit(
          "savedDateInput",
          values
        );
    }
  },
};
</script>