<template>
    <vs-popup
      :title="popupTitle"
      :active.sync="isPopup"
    >
      <template>
        <div class="vx-row px-10 mb-3">
          <div class="vx-col w-full text-center mb-2 download-final">
            <xlsx-workbook>
              <xlsx-sheet
                :collection="sheetOption.data"
                v-for="sheetOption in sheetOptions"
                :key="sheetOption.name"
                :sheet-name="sheetOption.name"
              />
              <xlsx-download
                :filename="excelFileName"
                :options="excelOptions"
                v-show="whichOptionShow == 'excel' || whichOptionShow == true"
              >
                <button
                  class="link-text"
                  @click="$emit('clickForFinish')"
                >
                  {{ LabelConstant.LabelDownloadExcel }}
                </button>
              </xlsx-download>
              <xlsx-download
                :filename="csvFileName"
                :options="csvOptions"
                v-show="whichOptionShow == 'csv' || whichOptionShow == true"
              >
                <button
                  class="link-text"
                  @click="$emit('clickForFinish')"
                >
                  {{ LabelConstant.LabelDownloadCSV }}
                </button>
              </xlsx-download>
            </xlsx-workbook>
              <!-- <button
                 v-show="whichOptionShow == 'pushtocrm' || whichOptionShow == true"
                 v-if="showPushtoCRM"
                  class="link-text"
                  @click="$emit('downloadPushTOCRM')"
                >
                  Push to CRM
              </button> -->
          </div>
        </div>
      </template>
      <div class="text-center mt-6 mb-8">
        <vs-button
          color="grey"
          type="border"
          class="grey-btn"
          @click="$emit('clickForFinish')"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>    
</template>

<script>
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";

export default ({
    components:{
        XlsxWorkbook,
        XlsxSheet,
        XlsxDownload,
    },
    props:{
        popupTitle:{
            type: String,
            required: true,
        },
        isPopup:{
            type: Boolean,
            required: true,
            default: false
        },
        showPushtoCRM:{
            type: Boolean,
            required: true,
            default: true
        },
        sheetOptions:{
            required: true,
        },
        excelFileName:{
            type: String,
            required: true,
        },
        excelOptions:{
            type: Object,
            required: true
        },
        csvFileName:{
            type: String,
            required: true,
        },
        csvOptions:{
            type: Object,
            required: true
        },
        whichOptionShow:{
            required: true
        }
    }    
})
</script>
