<template>
    <vs-popup
      :title="popupTitle"
      :active.sync="isPopup"
    >
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full text-center">
            <b-card-text>
              <p>
                {{ creditWarningMessage }}
              </p>
            </b-card-text>
          </div>
        </div>
      </template>
      <div class="text-right mt-6 mb-8">
        <vs-button
          :title="LabelConstant.buttonLabelYes"
          color="primary"
          type="filled"
          class="mr-2"
          @click="$emit('clickForYes')"
          >{{ LabelConstant.buttonLabelYes }}</vs-button
        >
        <vs-button
          color="grey"
          type="border"
          class="grey-btn"
          @click="$emit('clickForNo')"
          >{{ LabelConstant.buttonLabelNo }}</vs-button
        >
      </div>
    </vs-popup>
</template>

<script>
import { BCardText } from "bootstrap-vue";

export default ({
    components: {
        BCardText
    },
    props:{
        popupTitle:{
            type: String,
            required: true,
        },
        isPopup:{
            type: Boolean,
            required: true,
            default: false
        },
        creditWarningMessage:{
            type: String,
        }
    }    
})
</script>
