<template>
      <vs-popup class="delete-popup" :active.sync="isRemoveTagPopupActive">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            
            <h4>Remove the tags?</h4>
            <span class="deleted-no">Are you sure you want to remove the tag from the selected contacts?
             </span>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button type="filled"  color="danger"  @click="$emit('removeTagsFromContact')">
         {{ LabelConstant.buttonLabelYes }}
        </vs-button>
        <vs-button
           @click="$emit('clickCloseRemoveTagPopup')"
          color="grey"
          type="border"
          class="ml-4 grey-btn"
          >{{ LabelConstant.buttonLabelNo }}</vs-button
        >
      </div>
    </vs-popup>

</template>
<script>

export default ({
    props: {
      isRemoveTagPopupActive:{
        type: Boolean,
        required: true,
        default: false
      }
    }
})
</script>