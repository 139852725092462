<template>
     <vs-popup class="alert-popup" :active.sync="isDataEnrichmentJobMessagePopup">
          <template>
            <div class="vx-row mb-3">
              <div class="vx-col w-full text-center">
                <h2 class="text-primary py-3">
                    <!-- The export job was submitted and you will receive an email when the job is completed. -->
                    {{ message }}
                </h2>
              </div>
            </div>
          </template>
          <div class="text-center mt-6 mb-8">
            <vs-button
              :title="LabelConstant.buttonLabelYes"
              color="primary"
              type="filled"
              class="mr-2"
              @click="$emit('clickForClose')"
              >{{ LabelConstant.buttonLabelOkay }}</vs-button
            > 
          </div>
        </vs-popup>
</template>
<script>
export default {
    props:{
        isDataEnrichmentJobMessagePopup:{
            required:true,
            type:Boolean,
            default:false
        },
        message:{
          required:true,
          type:String,
        }
    }
}
</script>