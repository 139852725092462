<template>
    <vs-popup
      :title="popupTitle"
      :active.sync="isPopup"
    >
      <template>
        <div class="vx-row px-10 mb-3">
          <div class="vx-col w-full text-left mb-2">
            <p
              class="link-text"
              @click="$emit('openExportContactOptionsPopupInput', false), $emit('exportContactTypeInput', 'excel'),$emit('clickForGetContactLimits')"
            >
              {{ LabelConstant.LabelExportExcel }}
            </p>
          </div>
          <div class="vx-col w-full text-left mb-2">
            <p
              class="link-text"
              @click="$emit('openExportContactOptionsPopupInput', false), $emit('exportContactTypeInput', 'csv'),$emit('clickForGetContactLimits')"
            >
              {{ LabelConstant.LabelExportCSV }}
            </p>
          </div>
           <!-- <div class="vx-col w-full text-left mb-2">
            <p
            class="link-text" @click="$emit('contactpushTocrm')"
            >
              Push to CRM
            </p>
          </div> -->
        </div>
      </template>
      <div class="text-center mt-6 mb-8">
        <vs-button
          color="grey"
          type="border"
          class="grey-btn"
          @click="$emit('openExportContactOptionsPopupInput', false)"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>
</template>

<script>

export default ({
    props: {
      popupTitle:{
        type: String,
        required: true,
      },
      isPopup:{
        type: Boolean,
        required: true,
        default: false
      }
    }
})
</script>
