<template>
    <vs-popup
      class="popup-width"
      title="Manage Tags"
      :active.sync="isManageTagsPopupActive"
    >
      <template>
        <div class="vx-row find-people">
          <!-- tag List table-->
          <div class="vx-col xl:w-full col-cus-12 mb-8 w-full">
             <div class="flex btn-search w-full pb-4 justify-start">
             </div>
            <div class="">
             
              <!-- table -->
              <vs-table    pagination
                search :data="userDefinedTagsList">              
                <template slot="thead">
                  <vs-th> Name</vs-th>
                  <vs-th>Shared</vs-th>
                  <vs-th>Actions</vs-th>
                </template>

                <template slot-scope="{ data }">
                 <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
                    <vs-td :data="data[indextr].TagName">
                      {{ data[indextr].TagName }}
                    </vs-td>
                    <vs-td :data="data[indextr].IsShared">
                     <vs-checkbox v-model="data[indextr].IsShared" :vs-value="data[indextr].Id" :disabled="true" > </vs-checkbox>
                    </vs-td>
                    <vs-td width="90">
                      <div class="table-action flex">
                        <vs-button
                          title="Edit"
                          color="primary"
                          type="filled"
                          icon-pack="feather"
                          icon="icon-edit"
                          class="mr-2"
                         @click="$emit('editUserDefinedTag',tr)"
                        ></vs-button>
                        <vs-button
                          title="delete"
                          color="danger"
                          type="filled"
                          icon-pack="feather"
                          icon="icon-trash"
                          class="mr-2"
                          @click="deleteConfirmationPopup=true,tagId=data[indextr].Id"
                        ></vs-button>
                      </div>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </div>
        </div>
        <div class="flex w-full justify-end float-left mt-6 mb-8">
          <vs-button
            color="success"
            @click="$emit('createNewTag')"
            type="filled"
            class="  mr-2"
            >{{ LabelConstant.buttonLabelNew }}</vs-button
          >
          <vs-button
            color="grey"
            @click="$emit('closeManageTagPopup')"
            type="border"
            class="grey-btn "
            >{{ LabelConstant.buttonLabelClose }}</vs-button
          >
        </div>
      </template>
      <!-- crm Add/edit popup  (Custom Export Rules)-->
    <vs-popup class="delete-popup" :active.sync="deleteConfirmationPopup">
        <template>
          <div class="vx-row mb-3">
            <div class="vx-col w-full">
              <div class="popup-icon">
                <feather-icon icon="Trash2Icon"></feather-icon>
              </div>
              <h4>Are you sure?</h4>
            </div>
          </div>
        </template>
        <div class="text-center mt-6 mb-8">
          <vs-button title="Delete" color="danger" type="filled" class="mr-2" @click="deleteConfirmationPopup=false,$emit('clickOnDeleteTag',tagId)">
            {{ LabelConstant.buttonLabelDelete }}
          </vs-button>
          <vs-button
            color="grey"
            @click="deleteConfirmationPopup = false,tagId =null"
            type="border"
            class="ml-4 grey-btn mr-3"
            >{{ LabelConstant.buttonLabelCancel }}</vs-button
          >
        </div>
    </vs-popup>
    </vs-popup>
</template>
<script>
export default {
    props:{
        isManageTagsPopupActive:{
            required:true,
            type:Boolean,
            default:false
        },
        userDefinedTagsList:{
            required:true,
        }
    },
    data :() =>({
        deleteConfirmationPopup:false,
        tagId:null,
    }),
}
</script>