<template>
    <vs-popup
      :title="popupTitle"
      :active.sync="isPopup"
    >
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full text-center">
            <b-card-text>
              <p>{{ LabelConstant.infoLabelContactsAddedSuccessfully }}</p>
            </b-card-text>
          </div>
        </div>
      </template>
      <div class="text-right mt-6 mb-8">
        <vs-button
          :title="LabelConstant.buttonLabelOkay"
          color="primary"
          type="filled"
          class="mr-2"
          @click="$emit('clickForFinish')"
          >{{ LabelConstant.buttonLabelOkay }}</vs-button
        >
      </div>
    </vs-popup>
</template>

<script>
import { BCardText } from "bootstrap-vue";

export default ({
    components: {
        BCardText
    },
    props:{
        popupTitle:{
            type: String,
            required: true,
        },
        isPopup:{
            type: Boolean,
            required: true,
            default: false
        },
    }
})
</script>
