import moment from "moment";

export default {
    created() {
        this.listJobLevels();
        this.listJobFunctions();
        this.listJobStartDates();
        this.listCompanyIndustries();
        this.listCompanyEmployeeSizes();
        this.listCompanyRevenueBands();
        this.listCompanyNAICS();
        this.listCompanySICS();
        this.listContactRegions();
        this.listAddressTypes();
        this.listContactCountries();
        this.listContactStates();
        this.listContactGenders();
        // this.getListOfUserDefinedTags();
        this.getListSaveFrom();
        this.getListSavedBy();
        // this.listLastUpdatedDates();
    },
    data: () => ({
        chromeExtensionLink: 'https://chrome.google.com/webstore/detail/visual-visitor-easily-fin/lgaajoacmibdfdjklimiamkbjaafekhb',
        selectedCompanyId: null,
        //Job Criteria
        jobLevelOptions: null,
        jobFunctionOptions: null,
        jobStartDateOptions: null,

        //Company Criteria
        companyIndustryOptions: null,
        companyEmployeeSizeOptions: null,
        companyRevenueBandsOptions: null,
        companyNAICSOptions: null,
        companySICSOptions: null,

        //Location Criteria
        contactRegionOptions: null,
        addressTypeOptions: null,
        contactCountriesOptions: null,
        contactStatesOptions: null,

        //personal Criteria
        personalContactGenderOptions: null,

        //Suppression Criteria
        // suppressionLastUpdatedDateOptions: null,

        showListEmailConfidenceScore: false,
        showRealTimeEmailScore: false,

        //saved criteria
        savedByOptions: [],
        savedFromOptions: [],

        //tags
        selectTagsOptions: []

    }),
    computed: {
        tags: {
            get: function() {
                let arr = [];
                let that = this
                for (let i = 0; i < this.jobTitleEntered.length; i++) {
                    if (!arr.includes(this.jobTitleEntered[i])) {
                        arr.push('Job Title:' + this.jobTitleEntered[i]);
                    }
                }
                for (let i = 0; i < this.jobLevelSelected.length; i++) {
                    if (!arr.includes(this.jobLevelSelected[i].Value)) {
                        arr.push('Job Level:' + this.jobLevelSelected[i].Value);
                    }
                }
                for (let i = 0; i < this.jobFunctionSelected.length; i++) {
                    if (!arr.includes(this.jobFunctionSelected[i])) {
                        arr.push('Job Function:' + this.jobFunctionSelected[i].Value);
                    }
                }
                if (this.jobStartDateSelected) {
                    arr.push('Job Start Date:' + this.jobStartDateSelected.Value);
                }
                if (this.recentChangedJob) {
                    arr.push('Recent Changed Job:Yes');
                }
                //Company Criteria
                for (let i = 0; i < this.companyNameEntered.length; i++) {
                    if (!arr.includes(this.companyNameEntered[i])) {
                        arr.push('Company Name:' + this.companyNameEntered[i]);
                    }
                }
                for (let i = 0; i < this.companyWebAddressEntered.length; i++) {
                    if (!arr.includes(this.companyWebAddressEntered[i])) {
                        arr.push('Company Web Address:' + this.companyWebAddressEntered[i]);
                    }
                }
                for (let i = 0; i < this.companyIndustrySelected.length; i++) {
                    if (!arr.includes(this.companyIndustrySelected[i])) {
                        arr.push('Company Industry:' + this.companyIndustrySelected[i].Value);
                    }
                }
                for (let i = 0; i < this.companyEmployeeSizeSelected.length; i++) {
                    if (!arr.includes(this.companyEmployeeSizeSelected[i])) {
                        arr.push('Company Employee Size:' + this.companyEmployeeSizeSelected[i].Value);
                    }
                }
                for (let i = 0; i < this.companyRevenueBandsSelected.length; i++) {
                    if (!arr.includes(this.companyRevenueBandsSelected[i])) {
                        arr.push('Company Revenue Band:' + this.companyRevenueBandsSelected[i].Value);
                    }
                }
                for (let i = 0; i < this.companyNAICSSelected.length; i++) {
                    if (!arr.includes(this.companyNAICSSelected[i])) {
                        arr.push('Company NAICS:' + this.companyNAICSSelected[i].Value);
                    }
                }
                for (let i = 0; i < this.companySICSSelected.length; i++) {
                    if (!arr.includes(this.companySICSSelected[i])) {
                        arr.push('Company SIC:' + this.companySICSSelected[i].Value);
                    }
                }
                //Location Criteria
                for (let i = 0; i < this.contactRegionSelected.length; i++) {
                    if (!arr.includes(this.contactRegionSelected[i])) {
                        arr.push('Contact Region:' + this.contactRegionSelected[i].Value);
                    }
                }
                for (let i = 0; i < this.addressTypeSelected.length; i++) {
                    if (!arr.includes(this.addressTypeSelected[i])) {
                        arr.push('Address Type:' + this.addressTypeSelected[i].Value);
                    }
                }
                for (let i = 0; i < this.contactCountriesSelected.length; i++) {
                    if (!arr.includes(this.contactCountriesSelected[i])) {
                        arr.push('Contact Country:' + this.contactCountriesSelected[i].Value);
                    }
                }
                for (let i = 0; i < this.contactStatesSelected.length; i++) {
                    if (!arr.includes(this.contactStatesSelected[i])) {
                        arr.push('Contact State:' + this.contactStatesSelected[i].Value);
                    }
                }
                for (let i = 0; i < this.contactCityEntered.length; i++) {
                    if (!arr.includes(this.contactCityEntered[i])) {
                        arr.push('Contact City:' + this.contactCityEntered[i]);
                    }
                }
                for (let i = 0; i < this.contactZipCodeEntered.length; i++) {
                    if (!arr.includes(this.contactZipCodeEntered[i])) {
                        arr.push('Contact Zip:' + this.contactZipCodeEntered[i]);
                    }
                }
                // if (this.localAddressRequired) {
                //   arr.push('Local Address Required:Yes');
                // }
                //personal Criteria
                if (this.personalContactFirstNameEntered) {
                    arr.push('Contact First Name:' + this.personalContactFirstNameEntered);
                }
                if (this.personalContactLastNameEntered) {
                    arr.push('Contact Last Name:' + this.personalContactLastNameEntered);
                }
                for (let i = 0; i < this.personalContactGenderSelected.length; i++) {
                    if (!arr.includes(this.personalContactGenderSelected[i])) {
                        arr.push('Contact Gender:' + this.personalContactGenderSelected[i].Value);
                    }
                }
                for (let i = 0; i < this.personalContactAlmaMaterEntered.length; i++) {
                    if (!arr.includes(this.personalContactAlmaMaterEntered[i])) {
                        arr.push('Contact Alma Mater:' + this.personalContactAlmaMaterEntered[i]);
                    }
                }
                if (this.personalContactBirthdayFromEntered) {
                    arr.push('Contact From Age:' + this.personalContactBirthdayFromEntered);
                }
                if (this.personalContactBirthdayToEntered) {
                    arr.push('Contact To Age:' + this.personalContactBirthdayToEntered);
                }
                for (let i = 0; i < this.personalContactSkillsEntered.length; i++) {
                    if (!arr.includes(this.personalContactSkillsEntered[i])) {
                        arr.push('Contact Skill:' + this.personalContactSkillsEntered[i]);
                    }
                }
                for (
                    let i = 0; i < this.personalContactFormerEmployerEntered.length; i++
                ) {
                    if (!arr.includes(this.personalContactFormerEmployerEntered[i])) {
                        arr.push('Contact Former Employee:' + this.personalContactFormerEmployerEntered[i]);
                    }
                }
                // if(this.personalContactMinimumConnectionEntered){
                //   arr.push('Minimum Number Of Connections:' + this.personalContactMinimumConnectionEntered);
                // }
                if (this.personalContactEmailAddressRequired) {
                    arr.push('Personal Email Address Required:Yes');
                }
                if (this.personalContactPhoneRequired) {
                    arr.push('Cell Phone Required:Yes');
                }
                //Suppression Criteria
                // if (this.suppressionLastUpdatedDateSelected) {
                //   arr.push("Suppression Last Updated Date:" + this.suppressionLastUpdatedDateSelected.Value);
                // }
                for (
                    let i = 0; i < this.suppressionCompanyWebAddressEntered.length; i++
                ) {
                    if (!arr.includes(this.suppressionCompanyWebAddressEntered[i])) {
                        arr.push('Suppression Company Web Address:' + this.suppressionCompanyWebAddressEntered[i]);
                    }
                }
                for (let i = 0; i < this.suppressionContactTitlesEntered.length; i++) {
                    if (!arr.includes(this.suppressionContactTitlesEntered[i])) {
                        arr.push('Suppression Contact Titles:' + this.suppressionContactTitlesEntered[i]);
                    }
                }
                for (let i = 0; i < this.suppressionEmailAddressEntered.length; i++) {
                    if (!arr.includes(this.suppressionEmailAddressEntered[i])) {
                        arr.push('Suppression Email Address:' + this.suppressionEmailAddressEntered[i]);
                    }
                }
                if (this.suppressionExcludeMyLeads) {
                    arr.push('Suppression Exclude My Leads:Yes');
                }
                //List Criteria
                if (this.listPrimaryContact) {
                    arr.push('List Primary Contact:Yes')
                }
                if (this.listProfessionalEmail) {
                    arr.push('List Professional Email:Yes')
                }
                if (this.listEmailConfidenceScore && this.showListEmailConfidenceScore) {
                    arr.push('List Email Confidence Score:' + this.listEmailConfidenceScore)
                }
                if (this.realTimeEmailScore && this.showRealTimeEmailScore) {
                    arr.push('Real Time Email Score:' + this.realTimeEmailScore)
                }
                if (this.listDeterministicEmail) {
                    arr.push('List Deterministic Email:Yes')
                }
                if (this.listPhoneNumber) {
                    arr.push('List Phone Number:Yes')
                }
                if (this.$route.name == 'SaveContacts') {
                    for (let i = 0; i < this.userDefinedtagSelected.length; i++) {
                        if (!arr.includes(this.userDefinedtagSelected[i]).TagName) {
                            arr.push('Tags:' + this.userDefinedtagSelected[i].TagName);
                        }
                    }
                    for (let i = 0; i < this.savedFromSelected.length; i++) {
                        if (!arr.includes(this.savedFromSelected[i]).Value) {
                            arr.push('Saved From:' + this.savedFromSelected[i].Value);
                        }
                    }
                    for (let i = 0; i < this.savedBySelected.length; i++) {
                        if (!arr.includes(this.savedBySelected[i]).Value) {
                            arr.push('Saved By:' + this.savedBySelected[i].Value);
                        }
                    }
                    if (this.savedExcludeExported) {
                        arr.push('Exclude Exported:Yes')
                    }
                    if (this.savedDateRange && this.$refs.collapseAccordion && this.$refs.collapseAccordion.showDateSaved) {
                        arr.push('Date Saved:' + moment(this.savedDateRange.startDate).format("MM/DD/YYYY") + '-' + moment(this.savedDateRange.endDate).format("MM/DD/YYYY"))
                    }
                    if (this.lastExportedRange && this.$refs.collapseAccordion && this.$refs.collapseAccordion.showLastExported) {
                        arr.push('Last Exported:' + moment(this.lastExportedRange.startDate).format("MM/DD/YYYY") + '-' + moment(this.lastExportedRange.endDate).format("MM/DD/YYYY"))
                    }
                }
                that.tagsTempArr = []
                that.tagsTempArr = arr
                return arr;
            },
            set: function() {},
        },
    },
    filters: {
        lastUpdatedDate: function(value) {
            return moment(value).format("MM/DD/YYYY");
        },
        addSpaceAfterComma: function(value) {
            return value.replace(/,/g, ", ")
        }
    },
    methods: {
        // comma and semicolon separated input tag
        termSeparation(values, name) {
            if (values[values.length - 1]) {
                let singleValue = values[values.length - 1]
                let splitValue = singleValue.split(/[,|;]/);
                let valueSeprated = []
                splitValue.forEach(e => {
                    if (e.trim().length) {
                        valueSeprated.push(e.trim());
                    }
                });

                if (name == "companyName") {
                    this.companyNameEntered.pop();
                    this.companyNameEntered = this.companyNameEntered.concat(valueSeprated);
                    this.companyNameEntered = [...new Set(this.companyNameEntered)];
                }
                if (name == "companyWebAddress") {
                    this.companyWebAddressEntered.pop();
                    this.companyWebAddressEntered = this.companyWebAddressEntered.concat(valueSeprated);
                    this.companyWebAddressEntered = [...new Set(this.companyWebAddressEntered)];
                }
                if (name == "jobTitle") {
                    this.jobTitleEntered.pop();
                    this.jobTitleEntered = this.jobTitleEntered.concat(valueSeprated);
                    this.jobTitleEntered = [...new Set(this.jobTitleEntered)];
                }
                if (name == "contactCity") {
                    this.contactCityEntered.pop();
                    this.contactCityEntered = this.contactCityEntered.concat(valueSeprated);
                    this.contactCityEntered = [...new Set(this.contactCityEntered)];
                }
                if (name == "contactZipCode") {
                    this.contactZipCodeEntered.pop();
                    this.contactZipCodeEntered = this.contactZipCodeEntered.concat(valueSeprated);
                    this.contactZipCodeEntered = [...new Set(this.contactZipCodeEntered)];
                }
                if (name == "personalContactAlmaMater") {
                    this.personalContactAlmaMaterEntered.pop();
                    this.personalContactAlmaMaterEntered = this.personalContactAlmaMaterEntered.concat(valueSeprated);
                    this.personalContactAlmaMaterEntered = [...new Set(this.personalContactAlmaMaterEntered)];
                }
                if (name == "personalContactSkills") {
                    this.personalContactSkillsEntered.pop();
                    this.personalContactSkillsEntered = this.personalContactSkillsEntered.concat(valueSeprated);
                    this.personalContactSkillsEntered = [...new Set(this.personalContactSkillsEntered)];
                }
                if (name == "personalContactFormerEmployer") {
                    this.personalContactFormerEmployerEntered.pop();
                    this.personalContactFormerEmployerEntered = this.personalContactFormerEmployerEntered.concat(valueSeprated);
                    this.personalContactFormerEmployerEntered = [...new Set(this.personalContactFormerEmployerEntered)];
                }
                if (name == "suppressionCompanyWebAddress") {
                    this.suppressionCompanyWebAddressEntered.pop();
                    this.suppressionCompanyWebAddressEntered = this.suppressionCompanyWebAddressEntered.concat(valueSeprated);
                    this.suppressionCompanyWebAddressEntered = [...new Set(this.suppressionCompanyWebAddressEntered)];
                }
                if (name == "suppressionContactTitles") {
                    this.suppressionContactTitlesEntered.pop();
                    this.suppressionContactTitlesEntered = this.suppressionContactTitlesEntered.concat(valueSeprated);
                    this.suppressionContactTitlesEntered = [...new Set(this.suppressionContactTitlesEntered)];
                }
                if (name == "suppressionEmailAddress") {
                    this.suppressionEmailAddressEntered.pop();
                    this.suppressionEmailAddressEntered = this.suppressionEmailAddressEntered.concat(valueSeprated);
                    this.suppressionEmailAddressEntered = [...new Set(this.suppressionEmailAddressEntered)];
                }
            }
        },
        listJobLevels() {
            this.$vs.loading();
            this.axios
                .get("/ws/FindAnyoneSearchContacts/ListJobLevels")
                .then((response) => {
                    let data = response.data;
                    this.jobLevelOptions = data;
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000,
                    });
                    this.showError(e);
                });
        },
        listJobFunctions() {
            this.$vs.loading();
            this.axios
                .get("/ws/FindAnyoneSearchContacts/ListJobFunctions")
                .then((response) => {
                    let data = response.data;
                    (this.jobFunctionOptions = data), this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000,
                    });
                    this.showError(e);
                });
        },
        listJobStartDates() {
            this.$vs.loading();
            this.axios
                .get("/ws/FindAnyoneSearchContacts/ListJobStartDates")
                .then((response) => {
                    let data = response.data;
                    this.jobStartDateOptions = data;
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000,
                    });
                    this.showError(e);
                });
        },
        listCompanyIndustries() {
            this.$vs.loading();
            this.axios
                .get("/ws/FindAnyoneSearchContacts/ListCompanyIndustries")
                .then((response) => {
                    let data = response.data;
                    this.companyIndustryOptions = data;
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000,
                    });
                    this.showError(e);
                });
        },
        listCompanyEmployeeSizes() {
            this.$vs.loading();
            this.axios
                .get("/ws/FindAnyoneSearchContacts/ListCompanyEmployeeSizes")
                .then((response) => {
                    let data = response.data;
                    this.companyEmployeeSizeOptions = data;
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000,
                    });
                    this.showError(e);
                });
        },
        listCompanyRevenueBands() {
            this.$vs.loading();
            this.axios
                .get("/ws/FindAnyoneSearchContacts/ListCompanyRevenueBands")
                .then((response) => {
                    let data = response.data;
                    this.companyRevenueBandsOptions = data;
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000,
                    });
                    this.showError(e);
                });
        },
        listCompanyNAICS() {
            this.$vs.loading();
            this.axios
                .get("/ws/FindAnyoneSearchContacts/ListCompanyNAICS")
                .then((response) => {
                    let data = response.data;
                    this.companyNAICSOptions = data;
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000,
                    });
                    this.showError(e);
                });
        },
        listCompanySICS() {
            this.$vs.loading();
            this.axios
                .get("/ws/FindAnyoneSearchContacts/ListCompanySICS")
                .then((response) => {
                    let data = response.data;
                    this.companySICSOptions = data;
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000,
                    });
                    this.showError(e);
                });
        },
        listContactRegions() {
            this.$vs.loading();
            this.axios
                .get("/ws/FindAnyoneSearchContacts/ListContactRegions")
                .then((response) => {
                    let data = response.data;
                    this.contactRegionOptions = data;
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000,
                    });
                    this.showError(e);
                });
        },
        listAddressTypes() {
            this.$vs.loading();
            this.axios
                .get("/ws/FindAnyoneSearchContacts/ListContactAddressTypes")
                .then((response) => {
                    let data = response.data;
                    this.addressTypeOptions = data;
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000,
                    });
                    this.showError(e);
                });
        },
        listContactCountries() {
            this.$vs.loading();
            this.axios
                .get("/ws/FindAnyoneSearchContacts/ListContactCountries")
                .then((response) => {
                    let data = response.data;
                    this.contactCountriesOptions = data;
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000,
                    });
                    this.showError(e);
                });
        },
        listContactStates() {
            this.$vs.loading();
            this.axios
                .get("/ws/FindAnyoneSearchContacts/ListContactStates")
                .then((response) => {
                    let data = response.data;
                    this.contactStatesOptions = data;
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000,
                    });
                    this.showError(e);
                });
        },
        listContactGenders() {
            this.$vs.loading();
            this.axios
                .get("/ws/FindAnyoneSearchContacts/ListContactGenders")
                .then((response) => {
                    let data = response.data;
                    this.personalContactGenderOptions = data;
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000,
                    });
                    this.showError(e);
                });
        },
        // listLastUpdatedDates() {
        //   this.$vs.loading();
        //   this.axios
        //     .get("/ws/FindAnyoneSearchContacts/ListLastUpdatedDates")
        //     .then((response) => {
        //       let data = response.data;
        //       this.suppressionLastUpdatedDateOptions = data;
        //       this.$vs.loading.close();
        //     })
        //     .catch((e) => {
        //       this.$vs.loading.close();
        //       this.$vs.notify({
        //         title: "Error",
        //         text: e.response.data.Errors[0].Message,
        //         color: "danger",
        //         iconPack: "feather",
        //         icon: "icon-alert-circle",
        //         position: "top-right",
        //         time: 4000,
        //       });
        //       this.showError(e);
        //     });
        // }
        //Clear selected search criteria and contacts
        clearSearchCriteria() {
            //Job Criteria
            this.$refs.collapseAccordion.showJobTitleText = false
            this.$refs.collapseAccordion.showJobLevelOptions = false
            this.$refs.collapseAccordion.showJobFunctionOptions = false
            this.$refs.collapseAccordion.showJobStartDateOptions = false

            //Company Criteria
            this.$refs.collapseAccordion.showCompanyNameText = false
            this.$refs.collapseAccordion.showCompanyWebAddressText = false
            this.$refs.collapseAccordion.showCompanyIndustryOptions = false
            this.$refs.collapseAccordion.showCompanyEmployeeSizeOptions = false
            this.$refs.collapseAccordion.showCompanyRevenueBandsOptions = false
            this.$refs.collapseAccordion.showCompanyNAICSOptions = false
            this.$refs.collapseAccordion.showCompanySICSOptions = false

            //Location Criteria
            this.$refs.collapseAccordion.showContactRegionOptions = false
            this.$refs.collapseAccordion.showAddressTypeOptions = false
            this.$refs.collapseAccordion.showContactCountriesOptions = false
            this.$refs.collapseAccordion.showContactSatesOptions = false
            this.$refs.collapseAccordion.showContactCity = false
            this.$refs.collapseAccordion.showContactZipCode = false

            //Personal Criteria
            this.$refs.collapseAccordion.showPersonalContactName = false
            this.$refs.collapseAccordion.showPersonalContactGenderOptions = false
            this.$refs.collapseAccordion.showPersonalContactLastName = false
            this.$refs.collapseAccordion.showPersonalContactBirthday = false
            this.$refs.collapseAccordion.fromAgeErrorMessage = null
            this.$refs.collapseAccordion.toAgeErrorMessage = null
            this.$refs.collapseAccordion.showPersonalContactSkills = false
            this.$refs.collapseAccordion.showPersonalContactFormerEmployer = false
            this.$refs.collapseAccordion.showPersonalContactMinimumConnection = false

            //Suppression Criteria
            // this.$refs.collapseAccordion.showSuppressionLastUpdatedDateOptions = false
            this.$refs.collapseAccordion.showSuppressionCompanyWebAddress = false
            this.$refs.collapseAccordion.showSuppressionContactTitles = false
            this.$refs.collapseAccordion.showSuppressionEmailAddress = false

            //saved criteria 
            this.$refs.collapseAccordion.showTags = false;
            this.$refs.collapseAccordion.showSavedFrom = false;
            this.$refs.collapseAccordion.showSavedBy = false;
            this.$refs.collapseAccordion.showDateSaved = false;
            this.$refs.collapseAccordion.showLastExported = false;
            this.$refs.collapseAccordion.excludeExported = false;

            this.jobTitleEntered = []
            this.jobLevelSelected = []
            this.jobFunctionSelected = []
            this.jobStartDateSelected = null
            this.recentChangedJob = false

            //Company Criteria
            this.companyNameEntered = []
            this.companyWebAddressEntered = []
            this.companyIndustrySelected = []
            this.companyEmployeeSizeSelected = []
            this.companyRevenueBandsSelected = []
            this.companyNAICSSelected = []
            this.companySICSSelected = []

            //Location Criteria
            this.contactRegionSelected = []
            this.addressTypeSelected = []
            this.contactCountriesSelected = []
            this.contactStatesSelected = []
            this.contactCityEntered = []
            this.contactZipCodeEntered = []
                // this.localAddressRequired = false

            //personal Criteria
            this.personalContactFirstNameEntered = null
            this.personalContactLastNameEntered = null
            this.personalContactGenderSelected = []
            this.personalContactAlmaMaterEntered = []
            this.personalContactBirthdayFromEntered = 0
            this.personalContactBirthdayToEntered = 0
            this.personalContactSkillsEntered = []
            this.personalContactFormerEmployerEntered = []
                // this.personalContactMinimumConnectionEntered = null,
            this.personalContactEmailAddressRequired = false
            this.personalContactPhoneRequired = false

            //Suppression Criteria
            // this.suppressionLastUpdatedDateSelected = null
            this.suppressionCompanyWebAddressEntered = []
            this.suppressionContactTitlesEntered = []
            this.suppressionEmailAddressEntered = []
            this.suppressionExcludeMyLeads = false

            //List Criteria
            this.listPrimaryContact = false
            this.showListEmailConfidenceScore = false
            this.showRealTimeEmailScore = false
            this.listEmailConfidenceScore = 50
            this.realTimeEmailScore = 0
            this.listProfessionalEmail = false
            this.listDeterministicEmail = false
            this.listPhoneNumber = false

            //saved criteria 
            if (this.$route.name == 'SaveContacts') {
                this.userDefinedtagSelected = [];
                this.savedBySelected = [];
                this.savedFromSelected = [];
                this.setSavedDateRange();
                this.setLastExportedRange();
            }
            this.contacts = []
            this.selectedMain = []
            this.totalContacts = 0
        },
        //Address beautification to show in table
        beautifyAddress(value) {
            let contactAddressArr = [];
            contactAddressArr.push(value.ContactCity);
            contactAddressArr.push(value.ContactState);
            contactAddressArr.push(value.ContactCountry);
            let contactAddressFinal = "";
            for (let i = 0; i < contactAddressArr.length; i++) {
                if (contactAddressArr[i] && contactAddressArr[i].length > 0) {
                    contactAddressFinal +=
                        contactAddressArr[i] + ", ";
                }
            }
            return contactAddressFinal.substring(0, contactAddressFinal.length - 2);
        },
        /*utility function to manipulate Selected criteria*/
        beautifyAddress3(value, type) {
            let contactAddressArr = [];
            if (type == 'hq') {
                contactAddressArr.push(value.HqCity);
                contactAddressArr.push(value.HqState);
                contactAddressArr.push(value.HqCountry);
            } else if (type == 'local') {
                contactAddressArr.push(value.LocalCity);
                contactAddressArr.push(value.LocalState);
                contactAddressArr.push(value.LocalCountry);
            } else {
                contactAddressArr.push(value.PhysicalCity);
                contactAddressArr.push(value.PhysicalState);
                contactAddressArr.push(value.PhysicalCountry);
            }
            let contactAddressFinal = "";
            for (let i = 0; i < contactAddressArr.length; i++) {
                if (contactAddressArr[i] && contactAddressArr[i].length > 0) {
                    contactAddressFinal +=
                        contactAddressArr[i] + ", ";
                }
            }
            return contactAddressFinal.substring(0, contactAddressFinal.length - 2);
        },
        arrayRemove(arr, value) {
            return arr.filter(function(ele) {
                return ele != value;
            });
        },
        //To update selected criteria
        testUpdateSelectedTags(value) {
            let keyArr = this.tagsTempArr.filter((x) => value.indexOf(x) === -1);
            let testStr = keyArr[0];
            let keyStr = keyArr[0].substring(keyArr[0].indexOf(":") + 1);
            //Job Criteria
            if (testStr.includes("Job Title:")) {
                this.jobTitleEntered = this.arrayRemove(this.jobTitleEntered, keyStr);
            }
            if (testStr.includes("Job Level:")) {
                let removableIndex = this.jobLevelSelected.findIndex(function(arr) {
                    if (arr.Value == keyStr) return true;
                });
                this.jobLevelSelected.splice(removableIndex, 1);
            }
            if (testStr.includes("Job Function:")) {
                let removableIndex = this.jobFunctionSelected.findIndex(function(arr) {
                    if (arr.Value == keyStr) return true;
                });
                this.jobFunctionSelected.splice(removableIndex, 1);
            }
            if (testStr.includes("Job Start Date:")) {
                this.jobStartDateSelected = null;
            }
            if (testStr.includes("Recent Changed Job:")) {
                this.recentChangedJob = false;
            }
            //Company Criteria
            if (testStr.includes("Company Name:")) {
                this.companyNameEntered = this.arrayRemove(
                    this.companyNameEntered,
                    keyStr
                );
            }
            if (testStr.includes("Company Web Address:")) {
                this.companyWebAddressEntered = this.arrayRemove(
                    this.companyWebAddressEntered,
                    keyStr
                );
            }
            if (testStr.includes("Company Industry:")) {
                let removableIndex = this.companyIndustrySelected.findIndex(function(
                    arr
                ) {
                    if (arr.Value == keyStr) return true;
                });
                this.companyIndustrySelected.splice(removableIndex, 1);
            }
            if (testStr.includes("Company Employee Size:")) {
                let removableIndex = this.companyEmployeeSizeSelected.findIndex(
                    function(arr) {
                        if (arr.Value == keyStr) return true;
                    }
                );
                this.companyEmployeeSizeSelected.splice(removableIndex, 1);
            }
            if (testStr.includes("Company Revenue Band:")) {
                let removableIndex = this.companyRevenueBandsSelected.findIndex(
                    function(arr) {
                        if (arr.Value == keyStr) return true;
                    }
                );
                this.companyRevenueBandsSelected.splice(removableIndex, 1);
            }
            if (testStr.includes("Company NAICS:")) {
                let removableIndex = this.companyNAICSSelected.findIndex(function(
                    arr
                ) {
                    if (arr.Value == keyStr) return true;
                });
                this.companyNAICSSelected.splice(removableIndex, 1);
            }
            if (testStr.includes("Company SIC:")) {
                let removableIndex = this.companySICSSelected.findIndex(function(arr) {
                    if (arr.Value == keyStr) return true;
                });
                this.companySICSSelected.splice(removableIndex, 1);
            }
            //Location Criteria
            if (testStr.includes("Contact Region:")) {
                let removableIndex = this.contactRegionSelected.findIndex(function(
                    arr
                ) {
                    if (arr.Value == keyStr) return true;
                });
                this.contactRegionSelected.splice(removableIndex, 1);
            }
            if (testStr.includes("Address Type:")) {
                let removableIndex = this.addressTypeSelected.findIndex(function(
                    arr
                ) {
                    if (arr.Value == keyStr) return true;
                });
                this.addressTypeSelected.splice(removableIndex, 1);
            }
            if (testStr.includes("Contact Country:")) {
                let removableIndex = this.contactCountriesSelected.findIndex(function(
                    arr
                ) {
                    if (arr.Value == keyStr) return true;
                });
                this.contactCountriesSelected.splice(removableIndex, 1);
            }
            if (testStr.includes("Contact State:")) {
                let removableIndex = this.contactStatesSelected.findIndex(function(
                    arr
                ) {
                    if (arr.Value == keyStr) return true;
                });
                this.contactStatesSelected.splice(removableIndex, 1);
            }
            if (testStr.includes("Contact City:")) {
                this.contactCityEntered = this.arrayRemove(
                    this.contactCityEntered,
                    keyStr
                );
            }
            if (testStr.includes("Contact Zip:")) {
                this.contactZipCodeEntered = this.arrayRemove(
                    this.contactZipCodeEntered,
                    keyStr
                );
            }
            // if (testStr.includes("Local Address Required:")) {
            //   this.localAddressRequired = false;
            // }
            //personal Criteria
            if (testStr.includes("Contact First Name:")) {
                this.personalContactFirstNameEntered = null;
            }
            if (testStr.includes("Contact Last Name:")) {
                this.personalContactLastNameEntered = null;
            }
            if (testStr.includes("Contact Gender:")) {
                let removableIndex = this.personalContactGenderSelected.findIndex(
                    function(arr) {
                        if (arr.Value == keyStr) return true;
                    }
                );
                this.personalContactGenderSelected.splice(removableIndex, 1);
            }
            if (testStr.includes("Contact Alma Mater:")) {
                this.personalContactAlmaMaterEntered = this.arrayRemove(
                    this.personalContactAlmaMaterEntered,
                    keyStr
                );
            }
            if (testStr.includes("Contact From Age:")) {
                this.personalContactBirthdayFromEntered = null;
            }
            if (testStr.includes("Contact To Age:")) {
                this.personalContactBirthdayToEntered = null;
            }
            if (testStr.includes("Contact Skill:")) {
                this.personalContactSkillsEntered = this.arrayRemove(
                    this.personalContactSkillsEntered,
                    keyStr
                );
            }
            if (testStr.includes("Contact Former Employee:")) {
                this.personalContactFormerEmployerEntered = this.arrayRemove(
                    this.personalContactFormerEmployerEntered,
                    keyStr
                );
            }
            // if (testStr.includes("Minimum Number Of Connections:")) {
            //   this.personalContactMinimumConnectionEntered = null;
            // }
            if (testStr.includes("Personal Email Address Required:")) {
                this.personalContactEmailAddressRequired = false;
            }
            if (testStr.includes("Cell Phone Required:")) {
                this.personalContactPhoneRequired = false;
            }
            //Suppression Criteria
            // if (testStr.includes("Suppression Last Updated Date:")) {
            //   this.suppressionLastUpdatedDateSelected = null;
            // }
            if (testStr.includes("Suppression Company Web Address:")) {
                this.suppressionCompanyWebAddressEntered = this.arrayRemove(
                    this.suppressionCompanyWebAddressEntered,
                    keyStr
                );
            }
            if (testStr.includes("Suppression Contact Titles:")) {
                this.suppressionContactTitlesEntered = this.arrayRemove(
                    this.suppressionContactTitlesEntered,
                    keyStr
                );
            }
            if (testStr.includes("Suppression Email Address:")) {
                this.suppressionEmailAddressEntered = this.arrayRemove(
                    this.suppressionEmailAddressEntered,
                    keyStr
                );
            }
            if (testStr.includes("Suppression Exclude My Leads:")) {
                this.suppressionExcludeMyLeads = false;
            }
            //List Criteria
            if (testStr.includes("List Primary Contact:")) {
                this.listPrimaryContact = false;
            }
            if (testStr.includes("List Professional Email:")) {
                this.listProfessionalEmail = false;
            }
            if (testStr.includes("List Email Confidence Score:")) {
                this.showListEmailConfidenceScore = false;
                this.listEmailConfidenceScore = 50;
            }
            if (testStr.includes("Real Time Email Score:")) {
                this.showRealTimeEmailScore = false;
                this.realTimeEmailScore = 0;
            }
            if (testStr.includes("List Deterministic Email:")) {
                this.listDeterministicEmail = false;
            }
            if (testStr.includes("List Phone Number:")) {
                this.listPhoneNumber = false;
            }
            if (testStr.includes("Tags:")) {
                let removableIndex = this.userDefinedtagSelected.findIndex(function(arr) {
                    if (arr.TagName == keyStr) return true;
                });
                this.userDefinedtagSelected.splice(removableIndex, 1);
            }
            if (testStr.includes("Saved From:")) {
                let removableIndex = this.savedFromSelected.findIndex(function(arr) {
                    if (arr.Value == keyStr) return true;
                });
                this.savedFromSelected.splice(removableIndex, 1);
            }
            if (testStr.includes("Saved By:")) {
                let removableIndex = this.savedBySelected.findIndex(function(arr) {
                    if (arr.Value == keyStr) return true;
                });
                this.savedBySelected.splice(removableIndex, 1);
            }
            if (testStr.includes("Exclude Exported:")) {
                this.savedExcludeExported = false;
            }
            if (testStr.includes("Date Saved:")) {
                this.$refs.collapseAccordion.showDateSaved = false;
                this.setSavedDateRange();
            }
            if (testStr.includes("Last Exported:")) {
                this.$refs.collapseAccordion.showLastExported = false;
                this.setLastExportedRange();
            }
        },
        manipulateLocationCoulumnHeading() {
            if (this.addressTypeSelected.length > 0) {
                let isHq = false
                let isLocal = false
                let isPhysical = false
                for (let i = 0; i < this.addressTypeSelected.length; i++) {
                    if (this.addressTypeSelected[i].Key == 'hq') {
                        isHq = true
                    } else if (this.addressTypeSelected[i].Key == 'local') {
                        isLocal = true
                    } else {
                        isPhysical = true
                    }
                    this.dynamicLocationColumnName = isHq && isLocal && isPhysical ? 'HQ Location' : isLocal && isPhysical ? 'Local Location' : isHq ? 'HQ Location' : isLocal ? 'Local Location' : 'Physical Location'
                }
            } else {
                this.dynamicLocationColumnName = 'HQ Location'
            }
        },

        getListSavedBy() {
            this.$vs.loading();
            this.axios
                .get("ws/FindAnyoneSearchContacts/ListSavedBy")
                .then((response) => {
                    let data = response.data;
                    this.savedByOptions = data;
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000,
                    });
                    this.showError(e);
                });
        },
        getListSaveFrom() {
            this.$vs.loading();
            this.axios
                .get("ws/FindAnyoneSearchContacts/ListSavedFrom")
                .then((response) => {
                    let data = response.data;
                    this.savedFromOptions = data;
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000,
                    });
                    this.showError(e);
                });
        },
        setSavedDateRange() {
            let fromDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
            let fromDay = fromDate.getDate();
            let fromMonth = fromDate.getMonth() + 1;
            let fromYear = fromDate.getFullYear();

            let toDate = new Date();
            let toDay = toDate.getDate();
            let toMonth = toDate.getMonth() + 1;
            let toYear = toDate.getFullYear();

            this.savedDateRange.startDate = fromMonth + "/" + fromDay + "/" + fromYear;
            this.savedDateRange.endDate = toMonth + "/" + toDay + "/" + toYear;
        },
        setLastExportedRange() {
            let fromDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
            let fromDay = fromDate.getDate();
            let fromMonth = fromDate.getMonth() + 1;
            let fromYear = fromDate.getFullYear();

            let toDate = new Date();
            let toDay = toDate.getDate();
            let toMonth = toDate.getMonth() + 1;
            let toYear = toDate.getFullYear();

            this.lastExportedRange.startDate = fromMonth + "/" + fromDay + "/" + fromYear;
            this.lastExportedRange.endDate = toMonth + "/" + toDay + "/" + toYear;
        },
    }
}