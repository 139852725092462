<template>
    <vs-popup :title="popupTitle" :active.sync="isPopup">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full text-center">
            <b-card-text>
              <h4 class="mb-3" v-show="isShowCreditDeduction">
                {{ creditDeductionMessage }}
              </h4>
              <p v-show="isShowCreditDeduction">{{ LabelConstant.infoLabelDoYouWishToContinue }}</p>
              <p v-show="!isShowCreditDeduction">
                {{ LabelConstant.infoLabelPleaseSelectContactsToExport }}
              </p>
            </b-card-text>
          </div>
        </div>
      </template>
      <div class="text-right mt-6 mb-8">
        <vs-button
          v-if="!isCompanyDetailPopup"
          v-show="isSelectedMainLength || selectedContactId || exportContactType =='queryToCSV'"
          :title="LabelConstant.buttonLabelYes"
          color="primary"
          type="filled"
          class="mr-2"
          @click="
            isShowCreditDeduction && selectedContactId && !exportContactType
              ? $emit('getContactDetail')
              : exportContactType == 'queryToCSV'? $emit('queryToCSV') : $emit('exportContacts')
          "
          >{{ LabelConstant.buttonLabelYes }}</vs-button
        >
        <vs-button
          v-if="isCompanyDetailPopup"
          v-show="isSelectedInnerLength || selectedContactId"
          :title="LabelConstant.buttonLabelYes"
          color="primary"
          type="filled"
          class="mr-2"
          @click="
            isShowCreditDeduction && selectedContactId && !exportContactType
              ? $emit('getContactDetail')
              : $emit('exportContacts')
          "
          >{{ LabelConstant.buttonLabelYes }}</vs-button
        >
        <vs-button
          color="grey"
          @click="$emit('clickForFinish')"
          type="border"
          class="grey-btn"
          >{{ isShowCreditDeduction ? LabelConstant.buttonLabelNo : LabelConstant.buttonLabelOkay }}</vs-button
        >
      </div>
    </vs-popup>
</template>

<script>
import { BCardText } from "bootstrap-vue";

export default ({
    components: {
        BCardText
    },
    props:{
        popupTitle:{
            type: String,
            required: true,
        },
        isPopup:{
            type: Boolean,
            required: true,
            default: false
        },
        isShowCreditDeduction:{
            type: Boolean,
            required: true,
        },
        creditDeductionMessage:{
            required: true,
        },
        isCompanyDetailPopup:{
            type: Boolean,
            required: true,
        },
        isSelectedMainLength:{
            type: Boolean,
            required: true,
        },
        selectedContactId:{
            required: true,
        },
        exportContactType:{
            required: true,
        },
        isSelectedInnerLength:{
            type: Boolean,
            required: true,
        }
    }
})
</script>
