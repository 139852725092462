<template>
     <vs-popup class="" :title="requestFromModule=='add'?'New Tag':requestFromModule=='update'?'Update Tag':requestFromModule=='remove'?'Remove Tag':requestFromModule =='export'?'Add tag to contact':'Add tag to contact'" :active.sync="isAddTagsPopupActive">
      <template>
        <form data-vv-scope="selectTagPopup">
          <div class="vx-row">
            <div class="vx-col mb-6 w-full">
              <!-- <p class="w-full float-left">Add a new tag</p> -->
              <div class="vx-row align-center">
                <!-- <div class="vx-col mb-2 w-full">
                  <vx-input-group class="mb-0">
                    <vs-input v-model="input2" />

                    <template slot="append">
                      <div class="append-text btn-addon">
                        <vs-button color="primary" @click="$emit('createNewTag')"><span class="material-icons">playlist_add</span></vs-button>
                      </div>
                    </template>
                  </vx-input-group>
                </div> -->
                <div class="vx-col mb-6 w-full">
                  <p class="w-full float-left">Select Tags</p>
                  <v-select
                    v-validate="'required'"
                    class="multiple-select type-multiselect"
                    label="TagName"
                    multiple
                    :options="userDefinedTagsOptions"
                    v-model="selectedTag"
                    name="selectTagsContact"
                  />
                  <span
                class="text-danger text-sm"
              >{{ errors.first('selectTagPopup.selectTagsContact') }}</span>
                </div>
                 <div class="vx-col mb-6 w-full" v-if="requestFromModule!='remove'">
                  <a class="w-full float-left  add-newtag-link" @click="$emit('createNewTag')"><span class="material-icons">add</span> Create a new Tag</a>
                </div>
              </div>
            </div>
          </div>
        </form>
      </template>
      <div class="text-right mt-6 mb-8">
        <vs-button title="Update" color="primary" type="filled" class="mr-2" @click="addTagsToContacts()">{{
          LabelConstant.buttonLabelSave
        }}</vs-button>
        <vs-button
          color="grey"
          @click="closePopup()"
          type="border"
          class="grey-btn"
          >{{ requestFromModule =='export' || requestFromModule
           =='savecontact' || requestFromModule == 'contactdetails'? LabelConstant.buttonLabelSkip :LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>
</template>
<script>
import vSelect from "vue-select";
import { Validator } from "vee-validate";
const dict = {
		custom: {
			selectTagsContact: {
				required: "Please add a tag to Save or Skip if no tags are needed",
			}
		}
	};

Validator.localize("en", dict);
export default {
    components:{
        vSelect
    },
    props:{
        isAddTagsPopupActive:{
            type: Boolean,
            required: true,
            default: false,
        },
        userDefinedTagsOptions:{
          required: true,
        },
        requestFromModule:{
          required: true,
        }
    },
    data :() =>({
        input2:null,
        selectedTag:[],
    }),
    watch:{
      isAddTagsPopupActive(){
        this.errors.clear('selectTagPopup')
      }
    },
    created () {
    },
    methods:{
      addTagsToContacts(){
        this.$validator.validateAll('selectTagPopup').then(result =>{
          if(result){
            if(this.requestFromModule == 'add'){
              this.$emit("addTagToContacts",{contactSelectedTags: this.selectedTag,isExportContact: false});
            } else if (this.requestFromModule =='update'){
              this.$emit("updateTagToContacts",this.selectedTag);
            } else if(this.requestFromModule =='export'){
              this.$emit('addTagToContacts',{contactSelectedTags: this.selectedTag,isExportContact: true});
            } else if(this.requestFromModule =='savecontact'){
              this.$emit('addTagToContacts',{contactSelectedTags: this.selectedTag,isExportContact: false});
            }  else if(this.requestFromModule =='contactdetails'){
              this.$emit('addTagToContacts',{contactSelectedTags: this.selectedTag,isExportContact: false});
              } else {
              this.$emit("removeTagToContacts",this.selectedTag);
            }
          }
        })
      },
      closePopup(){
        this.errors.clear('selectTagPopup')
        if(this.requestFromModule == 'export'){
          this.$emit('addTagToContacts',{contactSelectedTags: [],isExportContact: true})
        } else if(this.requestFromModule =='savecontact'){
           this.$emit('addTagToContacts',{contactSelectedTags: [],isExportContact: false})
        } else if(this.requestFromModule =='contactdetails'){
           this.$emit('addTagToContacts',{contactSelectedTags: [],isExportContact: false})
        } else {
          this.$emit('closePopupAddTagToSelected')
        }
      }
    },
}
</script>