<template>
  <vs-popup class="popup-width close-open" :title="LabelConstant.popupTitleCompanyDetails" :active.sync="companyDetailPopup">
    <vs-button
      @click="clearFilter(), $emit('clickForClose')"
      color="secondary"
      type="filled"
      class="mb-8 float-right close-popup-btn"
      >{{ LabelConstant.buttonLabelClose }}
    </vs-button>
    <template>
      <div id="page-user-view" v-if="companyDetail">
        <!-- Company details  -->
        <vx-card :title="companyDetail.CompanyName" class="mb-base">
          <div class="vx-row">
            <!-- Company logo -->
            <div class="vx-col" id="avatar-col">
              <div class="img-container mb-4">
                <img
                  :src="companyDetail.CompanyLogoUrl"
                  height="100"
                  width="100"
                />
              </div>
            </div>
            <!-- Social icon, Company Website, Industry, Specialities -->
            <div class="vx-col flex-1" id="account-info-col-1">
              <!-- Social icon -->
              <div class="flex">
                <!-- <vs-avatar
                    color="primary"
                    icon-pack="feather"
                    icon="icon-facebook"
                  />
                  <vs-avatar
                    color="primary"
                    icon-pack="feather"
                    icon="icon-twitter"
                  /> -->
                <a
                  v-show="
                    companyDetail.CompanyLinkedInUrl &&
                    companyDetail.CompanyLinkedInUrl != ''
                  "
                  :href="'https://' + companyDetail.CompanyLinkedInUrl"
                  target="_blank"
                >
                  <vs-avatar
                    color="primary"
                    icon-pack="feather"
                    icon="icon-linkedin"
                    title="Linkedin"
                  />
                </a>
              </div>
              <table>
                <!-- Company Website -->
                <tr>
                  <td class="font-semibold">
                    <feather-icon
                      icon="GlobeIcon"
                      title="Website"
                    ></feather-icon>
                  </td>
                  <td>{{ companyDetail.CompanyDomain }}</td>
                </tr>
                <!-- Company Industry -->
                <tr>
                  <td class="font-semibold">
                    <feather-icon icon="LayersIcon"  title="Industry"></feather-icon>
                  </td>
                  <td class="word-break-all">
                    {{ companyDetail.CompanyIndustry }}
                  </td>
                </tr>
                <!-- Company NAICS -->
                <tr v-if="companyDetail.CompanyNAICS && companyDetail.CompanyNAICS !== ''">
                  <td class="font-semibold">
                    <!-- <feather-icon icon="IndustryIcon" title="NAICS"></feather-icon> -->
                     <span class="material-icons" title="NAICS"> business </span>
                  </td>
                  <td class="word-break-all">
                    {{ companyDetail.CompanyNAICS }}
                  </td>
                </tr>
                <!-- Company Specialities -->
                <tr>
                  <td class="font-semibold">
                    <feather-icon icon="TagIcon"  title="Specialities"></feather-icon>
                  </td>
                  <td class="word-break-all">
                    {{ companyDetail.CompanySpecialties | addSpaceAfterComma }}
                  </td>
                  <!-- <td class="word-break-all">{{ 'Mobile Marketing, Search Engine Optimization, Interactive Marketing, Reputation Management, Pay Per Click Lead Generation, Local Listings Management' }}</td> -->
                </tr>
              </table>
            </div>
            <!-- Number of Company Employee, Phone, Address, Revenue -->
            <div
              class="vx-col lg:w-1/2 md:w-3/3 sm:w-3/3 w-full lg:pt-0 pt-4"
              id="account-info-col-2"
            >
              <table>
                <!-- Number of Company Employee -->
                <tr>
                  <td class="font-semibold">
                    <feather-icon icon="UsersIcon" title="Employees"></feather-icon>
                  </td>
                  <td>
                    {{
                      companyDetail.CompanyEmployees
                        | tableRecordNumberFormatter
                    }}
                  </td>
                </tr>
                <!-- Company Phone -->
                <tr>
                  <td class="font-semibold">
                    <feather-icon icon="PhoneIcon" title="Contact Number"></feather-icon>
                  </td>
                  <td>{{ companyDetail.CompanyPhone }}</td>
                </tr>
                <!-- Company Address -->
                <tr>
                  <td class="font-semibold">
                    <feather-icon icon="MapPinIcon" title="Address"></feather-icon>
                  </td>
                  <td class="word-break-all">
                    {{ companyDetail.companyAddress }}
                  </td>
                </tr>
                <!-- Company SICS -->
                <tr v-if="companyDetail.CompanySICS && companyDetail.CompanySICS !== ''">
                  <td class="font-semibold">
                    <!-- <feather-icon icon="MapPinIcon" title="Company SICS"></feather-icon> -->
                    <span class="material-icons" title="SICS">business_center</span>
                  </td>
                  <td class="word-break-all">
                    {{ companyDetail.CompanySICS }}
                  </td>
                </tr>
                <!-- Company Revenue -->
                <tr>
                  <td class="font-semibold">
                    <feather-icon icon="DollarSignIcon" title="Revenue" ></feather-icon>
                  </td>
                  <td>{{ companyDetail.CompanyRevenueRange }}</td>
                </tr>
              </table>
            </div>
            <!-- Score -->
            <div
              v-if="isAvailableContactsIntentSignal"
              class="vx-col flex-1 justify-center"
              id="account-info-col-1"
            >
              <table>
                <tr>
                  <td class="font-semibold">
                    <h6>Score</h6>
                  </td>
                </tr>
                <tr>
                  <td>
                    <vs-chip color="primary"
                      ><h3 class="text-white">
                        {{ companyDetailsScore }}
                      </h3></vs-chip
                    >
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </vx-card>
        <!-- About Company -->
        <vx-card :title="'About ' + companyDetail.CompanyName" class="mb-base">
          <div>
            <b-card-text>
              {{ companyDetail.CompanyDescription }}
            </b-card-text>
          </div>
        </vx-card>
        <!--  Selected search criteria and,  selected Search Criteria-->
        <vx-card :class="isViewFilters === true || tags.length > 0 ? 'mb-8 ' : 'd-none'">
          <div class="card-title more-card-title flex justify-between items-center">
            <div class="flex items-center flex-wrap">
              <h2 class="w-auto mb-2">Selected Criteria</h2>
              <p class="table-data-entries-details text-left mb-2">
                Total Contacts:
                <span>{{ totalContacts | tableRecordNumberFormatter }}</span>
              </p>
            </div>
            <!-- Clear Selected search criteria and Contacts -->
            <vs-button
              color="grey"
              type="border"
              class="grey-btn sm:mt-0 mt-2"
              icon-pack="feather"
              icon="icon-refresh-ccw"
              @click="clearSearchCriteria()"
              >{{ LabelConstant.buttonLabelClearSearchCriteria }}
            </vs-button>
          </div>
          <!-- Selected Search Criteria -->
          <div class="vx-row find-people">
            <div class="vx-col w-full">
              <input-tag v-model="tags" @input="testUpdateSelectedTags"></input-tag>
            </div>
          </div>
        </vx-card>
        <!-- Available Contacts -->
        <vx-card class="mb-base" v-if="!isAvailableContactsIntentSignal">
          <div>
            <vs-navbar class="nabarx mb-8 table-nav">
              <div slot="title">
                <vs-navbar-title> Available Contacts </vs-navbar-title>
              </div>
              <vs-navbar-item index="0">
                <a v-on:click="isViewFilters = !isViewFilters">{{
                  isViewFilters ? "Hide Filter" : "Show Filter"
                }}</a>
              </vs-navbar-item>
              <vs-navbar-item index="2">
                <dropdown-menu class="dropdown-export">
                  <button slot="trigger" class="dropdown-export-btn">
                    {{ LabelConstant.buttonLabelExport }}
                    <i
                      class="vs-icon notranslate icon-scale material-icons null"
                      >expand_more</i
                    >
                  </button>

                  <ul slot="body">
                    <li
                      @click="
                        $emit('exportType', 'csv'),
                          $emit('clickForExport', 'csv')
                      "
                    >
                      {{ LabelConstant.buttonLabelExportCSV }}
                    </li>
                    <li
                      @click="
                        $emit('exportType', 'excel'),
                          $emit('clickForExport', 'excel')
                      "
                    >
                      {{ LabelConstant.buttonLabelExportExcel }}
                    </li>
                    <li
                      @click="CommingsoonPopup = true"
                      v-if="
                        initialRole == 'Global Admin' &&
                        appRunningOnDev.toLowerCase() === 'true'
                      "
                    >
                      {{ LabelConstant.buttonLabelExportCRM }}
                    </li>
                    <!-- <li
                      @click="CommingsoonPopup = true"
                      v-if="
                        initialRole == 'Global Admin' &&
                        appRunningOnDev.toLowerCase() === 'true'
                      "
                    >
                      {{ LabelConstant.buttonLabelExportQueryResultToCSV }}
                    </li> -->
                  </ul>
                </dropdown-menu>
              </vs-navbar-item>

              <vs-navbar-item index="1">
                <a @click="$emit('clickForAddToMySavedContacts')"
                  >Save Contacts</a
                >
              </vs-navbar-item>
              <v-select
                class="mr-2 show-tbl-data"
                label="value"
                :options="showEntriesOptions"
                :reduce="(option) => option.value"
                :clearable="false"
                v-model="recordPerInnerPage"
              />
            </vs-navbar>
          </div>
          <!-- Per page, Export, Add to My Saved Contacts -->
          <!-- <div class="card-title d-flex justify-content-between">
            <div><h4>Available Contacts</h4></div>

            <div>
              <v-select
                class="mr-2 float-left show-tbl-data min-width-80"
                label="value"
                :options="showEntriesOptions"
                :reduce="(option) => option.value"
                :clearable="false"
                v-model="recordPerInnerPage"
              />
              <vs-button
                title="Update"
                color="warning"
                type="filled"
                icon-pack="feather"
                icon="icon-filter"
                class="mr-2 float-left"
                v-on:click="isViewFilters = !isViewFilters"
                >{{isViewFilters ? 'Hide Filter' : 'Show Filter'}}</vs-button
              >
              <vs-button
                title="Update"
                color="primary"
                type="filled"
                icon-pack="feather"
                icon="icon-download"
                class="mr-2 float-left"
                @click="$emit('clickForExport')"
                :disabled="disabledExportORAddToMySavedContactsORDownload"
                >{{ LabelConstant.buttonLabelExport }}</vs-button
              >
              <vs-button
                v-if="isShowAddToMySavedContactsButton"
                title="Update"
                color="success"
                type="filled"
                icon-pack="feather"
                icon="icon-users"
                class="mr-2 float-left"
                @click="$emit('clickForAddToMySavedContacts')"
                :disabled="disabledExportORAddToMySavedContactsORDownload"
                >{{ LabelConstant.buttonLabelAddtoMySavedContacts }}</vs-button
              >
            </div>
          </div> -->
          <!-- Download Limit over message -->
          <div class="mb-2 sm:w-auto w-full flex flex-col" v-if="disabledExportORAddToMySavedContactsORDownload">
            <p class="error-msg text-right w-auto">Your contact download limit is over.</p>
          </div>
          <div class="vx-row find-people">
            <!-- Search by criteria -->
            <div v-if="isViewFilters" :class="isViewFilters === true ? ' vx-col xl:w-1/4 col-cus-12 mb-8 w-full' : 'd-none'">
              <!-- Search Criteria -->
              <div class="px-0 lead-accordion z-0 search-filters">
                <div class="filter-card border-0 px-0">
                  <div slot="header" class="filter-header">Search Criteria</div>
                  <div class="filter-list label-mt-2">
                    <vs-collapse accordion class="p-0">
                      <!-- Job Criteria -->
                      <div class="filter-card px-0">
                        <vs-collapse-item>
                          <div slot="header">Job Criteria</div>
                          <div class="filter-list">
                            <vs-checkbox v-model="showJobTitleText" @click="showJobTitleText == true ? (jobTitle = []) : false">Job Title</vs-checkbox>
                            <div v-show="showJobTitleText">
                              <input-tag v-model="jobTitle" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]" @input="termSeparation(jobTitle,'jobTitle')"></input-tag>
                            </div>
                            <vs-checkbox v-model="showJobLevelOptions" @click="jobLevel = []">Job Level</vs-checkbox>
                            <div v-if="showJobLevelOptions">
                              <v-select
                                class="multiple-select type-multiselect"
                                label="Value"
                                :options="jobLevelOptions"
                                v-model="jobLevel"
                                multiple
                              />
                            </div>
                            <vs-checkbox v-model="showJobFunctionOptions" @click="jobFunction = []">Job Function</vs-checkbox>
                            <div v-if="showJobFunctionOptions">
                              <v-select
                                class="multiple-select type-multiselect"
                                label="Value"
                                :options="jobFunctionOptions"
                                v-model="jobFunction"
                                multiple
                              />
                            </div>
                            <vs-checkbox v-model="showJobStartDateOptions" @click="jobStartDate = null">Job Start Date</vs-checkbox>
                            <div v-if="showJobStartDateOptions">
                              <v-select
                                label="Value"
                                :options="jobStartDateOptions"
                                v-model="jobStartDate"
                              />
                            </div>
                            <vs-checkbox v-model="recentChangedJob">Recent Changed Job</vs-checkbox>
                          </div>
                        </vs-collapse-item>
                      </div>
                      <!-- Location -->
                      <div class="filter-card px-0">
                        <vs-collapse-item>
                          <div slot="header">Location</div>
                          <div class="filter-list">
                            <!-- <vs-checkbox v-model="showContactRegionOptions" @click="contactRegion = []"> Contact Region</vs-checkbox>
                            <div v-if="showContactRegionOptions">
                              <v-select
                                class="multiple-select type-multiselect"
                                label="Value"
                                :options="contactRegionOptions"
                                v-model="contactRegion"
                                multiple
                              />
                            </div> -->
                            <vs-checkbox v-model="showAddressTypeOptions" @click="addressType = []">Address Type</vs-checkbox>
                            <div v-if="showAddressTypeOptions">
                              <v-select
                                class="multiple-select type-multiselect"
                                label="Value"
                                :options="addressTypeOptions"
                                v-model="addressType"
                                multiple
                              />
                            </div>
                            <vs-checkbox v-model="showContactCountriesOptions" @click="contactCountries = []">Contact Country</vs-checkbox>
                            <div v-if="showContactCountriesOptions">
                              <v-select
                                class="multiple-select type-multiselect"
                                label="Value"
                                :options="contactCountriesOptions"
                                v-model="contactCountries"
                                multiple
                              />
                            </div>
                            <vs-checkbox v-model="showContactSatesOptions" @click="contactStates = []">Contact State</vs-checkbox>
                            <div v-if="showContactSatesOptions">
                              <v-select
                                class="multiple-select type-multiselect"
                                label="Value"
                                :options="contactStatesOptions"
                                v-model="contactStates"
                                multiple
                              />
                            </div>
                            <vs-checkbox v-model="showContactCity" @click="contactCity = []">Contact City</vs-checkbox>
                            <div v-if="showContactCity">
                              <input-tag v-model="contactCity" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]" @input="termSeparation(contactCity,'contactCity')"></input-tag>
                            </div>
                            <vs-checkbox v-model="showContactZipCode" @click="contactZipCode = []">Contact Zip</vs-checkbox>
                            <div v-if="showContactZipCode">
                              <input-tag v-model="contactZipCode" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]" @input="termSeparation(contactZipCode,'contactZipCode')"></input-tag>
                            </div>
                          </div>
                        </vs-collapse-item>
                      </div>
                      <!-- Personal Criteria -->
                      <div class="filter-card px-0">
                        <vs-collapse-item>
                          <div slot="header">Personal Criteria</div>
                          <div class="filter-list">
                            <vs-checkbox v-model="showPersonalContactName" @click="clearFirstAndLastName()">Contact Name</vs-checkbox>
                            <div v-if="showPersonalContactName" class="my-3 w-full">
                              <vs-input v-model="personalContactFirstName" class="mb-2 w-full" placeholder="Type first name here"/>
                              <vs-input v-model="personalContactLastName" class="w-full" placeholder="Type last name here"/>
                            </div>
                            <vs-checkbox v-model="showPersonalContactGenderOptions" @click="personalContactGender = []">Contact Gender</vs-checkbox>
                            <div v-if="showPersonalContactGenderOptions">
                              <v-select
                                class="multiple-select type-multiselect"
                                label="Value"
                                :options="personalContactGenderOptions"
                                v-model="personalContactGender"
                                multiple
                              />
                            </div>
                            <vs-checkbox v-model="showPersonalContactAlmaMater" @click="personalContactAlmaMater = []">Contact Alma Mater</vs-checkbox>
                            <div v-if="showPersonalContactAlmaMater">
                              <input-tag v-model="personalContactAlmaMater" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]" @input="termSeparation(personalContactAlmaMater,'personalContactAlmaMater')"></input-tag>
                            </div>
                            <vs-checkbox v-model="showPersonalContactBirthday">Contact Age</vs-checkbox>
                            <div v-if="showPersonalContactBirthday" class="my-3">
                              <vue-numeric-input
                                placeholder="From"
                                :controls="false"
                                v-model="personalContactBirthdayFrom"
                                class="mb-2"
                                @change="fromAgeValidate"
                              ></vue-numeric-input>
                              <span class="text-danger text-sm error-msg" v-show="fromAgeErrorMessage">{{ fromAgeErrorMessage }}</span>
                              <vue-numeric-input
                                placeholder="To"
                                :controls="false"
                                v-model="personalContactBirthdayTo"
                                @change="toAgeValidate"
                              ></vue-numeric-input>
                              <span class="text-danger text-sm error-msg mb-3" v-show="toAgeErrorMessage">{{ toAgeErrorMessage }}</span>
                            </div>
                            <vs-checkbox v-model="showPersonalContactSkills" @click="personalContactSkills = []"> Contact Skill</vs-checkbox>
                            <div v-if="showPersonalContactSkills">
                              <input-tag v-model="personalContactSkills" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]" @input="termSeparation(personalContactSkills,'personalContactSkills')"></input-tag>
                            </div>
                            <vs-checkbox v-model="showPersonalContactFormerEmployer" @click="personalContactFormerEmployer = []">Contact Former Employer</vs-checkbox>
                            <div v-if="showPersonalContactFormerEmployer">
                              <input-tag v-model="personalContactFormerEmployer" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]" @input="termSeparation(personalContactFormerEmployer,'personalContactFormerEmployer')"></input-tag>
                            </div>
                            <vs-checkbox v-model="personalContactEmailAddressRequired">Personal Email Address Required</vs-checkbox>
                            <vs-checkbox v-model="personalContactPhoneRequired">Cell Phone Required</vs-checkbox>
                          </div>
                        </vs-collapse-item>
                      </div>
                      <!-- Suppression Criteria -->
                      <div class="filter-card px-0">
                        <vs-collapse-item>
                          <div slot="header">Suppression Criteria</div>
                          <div class="filter-list">
                            <vs-checkbox v-model="showSuppressionContactTitles" @click="suppressionContactTitles = []">Contact Titles</vs-checkbox>
                            <div v-if="showSuppressionContactTitles">
                              <input-tag v-model="suppressionContactTitles" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]" @input="termSeparation(suppressionContactTitles,'suppressionContactTitles')"></input-tag>
                            </div>
                            <vs-checkbox v-model="showSuppressionEmailAddress" @click="suppressionEmailAddress = []">Email Address</vs-checkbox>
                            <div v-if="showSuppressionEmailAddress">
                              <input-tag v-model="suppressionEmailAddress" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]" @input="termSeparation(suppressionEmailAddress,'suppressionEmailAddress')"></input-tag>
                            </div>
                            <vs-checkbox v-model="suppressionExcludeMyLeads">Exclude My Leads</vs-checkbox>
                          </div>
                        </vs-collapse-item>
                      </div>
                      <!-- List Criteria -->
                      <div class="filter-card px-0">
                        <vs-collapse-item>
                          <div slot="header">List Criteria</div>
                          <div class="filter-list">
                            <vs-checkbox v-model="listPrimaryContact">Primary Contact</vs-checkbox>
                            <vs-checkbox v-model="listProfessionalEmail">Professional Email</vs-checkbox>
                            <vs-checkbox v-model="showListEmailConfidenceScore" @click="listEmailConfidenceScore = 50">Email Confidence Score</vs-checkbox>
                            <div class="fliter-slider contact-filter" v-if="showListEmailConfidenceScore">
                              <!-- <vs-slider :max="100" :min="50" v-model="listEmailConfidenceScore"/> -->
                              <vue-slider
                                v-model="listEmailConfidenceScore"
                                :min="50"
                                :max="100"
                                :process="false"
                                :tooltip="'always'"
                                :height="6"
                                class="mr-4"
                                :direction="'horizontal'"
                              />
                            </div>
                            <vs-checkbox v-model="listDeterministicEmail">Deterministic Email</vs-checkbox>
                            <vs-checkbox v-model="listPhoneNumber">Phone Number</vs-checkbox>
                          </div>
                        </vs-collapse-item>
                      </div>
                    </vs-collapse>
                  </div>
                </div>
              </div>
              <div class="pt-5 mt-5 flex flex-wrap items-center">
                <vs-button
                  :title="LabelConstant.buttonLabelSearch"
                  color="primary"
                  type="filled"
                  class="mr-1 float-left px-4 my-2"
                  @click="searchContacts()"
                  >{{ LabelConstant.buttonLabelSearch }}</vs-button
                >
                <!-- <vs-button
                  :title="buttonName"
                  color="success"
                  type="filled"
                  class="mr-2 float-left px-4 my-2"
                  >Save New Search</vs-button
                >
                <a
                  class="mr-1 float-left my-2 btn-link"
                  @click="listSavedSearch()"
                  >View Saved Search</a
                > -->
              </div>
            </div>
            <!--  table-->
            <div
              :class="
                isViewFilters === true
                  ? 'vx-col xl:w-3/4 col-cus-12 mb-8 w-full border-left'
                  : 'vx-col col-cus-12 mb-8 w-full '
              "
            >
              <!-- Available Contacts table -->
              <vs-table
                class="popup-company-tbl"
                multiple
                v-model="selectedInner"
                :data="contacts"
              >
                <template slot="thead">
                  <vs-th>Full Name</vs-th>
                  <vs-th v-show="isViewFilters !== true">LinkedIn</vs-th>
                  <vs-th>Title</vs-th>
                  <vs-th>{{ dynamicLocationColumnName }}</vs-th>
                  <vs-th v-show="isViewFilters !== true">Saved</vs-th>
                  <vs-th>Phone</vs-th>
                  <vs-th>Email</vs-th>
                </template>
                <template slot-scope="{ data }">
                  <vs-tr
                    :data="tr"
                    :key="indextr"
                    v-for="(tr, indextr) in data"
                  >
                    <vs-td
                      :data="data[indextr].fullName"
                      class="word-break-all"
                    >
                      <div class="flex items-center">
                        <a
                          v-show="data[indextr].IsSavedContact == true"
                          @click="
                            $emit('contactsDetailPopupInput', true),
                              $emit('selectedContactIdInput', data[indextr].Id),
                              $emit('clickForGetSavedContactDetail')
                          "
                        >
                          {{ data[indextr].fullName }}
                        </a>
                        <a
                          v-show="data[indextr].IsSavedContact == false"
                          @click="
                            $emit(
                              'selectedContactIdInput',
                              data[indextr].PersonId
                            ),
                              $emit('clickForOpenContactCreditPopup')
                          "
                        >
                          {{ data[indextr].fullName }}
                        </a>
                      </div>
                    </vs-td>
                    <vs-td
                      :data="data[indextr].fullName"
                      v-show="isViewFilters !== true"
                    >
                      <a
                        v-show="
                          data[indextr].ContactLinkedinUrl &&
                          data[indextr].ContactLinkedinUrl != ''
                        "
                        :href="'https://' + data[indextr].ContactLinkedinUrl"
                        target="_blank"
                      >
                        <vs-avatar
                          color="primary"
                          icon-pack="feather"
                          icon="icon-linkedin"
                          size="28px"
                        />
                      </a>
                    </vs-td>
                    <vs-td
                      :data="data[indextr].ContactTitle"
                      class="word-break-all"
                    >
                      {{ data[indextr].ContactTitle }}
                    </vs-td>

                    <vs-td
                      :data="data[indextr].location"
                      class="word-break-all"
                    >
                      {{ data[indextr].location }}
                      <div class="flex who-to-contact-help-icon">
                        <vx-tooltip
                          title="HQ"
                          color="secondary"
                          :text="data[indextr].addressHq"
                          v-if="data[indextr].HasHqAddress"
                        >
                          <vs-button
                            type="flat"
                            icon-pack="material-icons"
                            icon="apartment"
                            class="p-0 help-btn"
                          ></vs-button>
                        </vx-tooltip>
                        <vx-tooltip
                          title="Local"
                          color="secondary"
                          :text="data[indextr].addressLocal"
                          v-if="data[indextr].HasLocalAddress"
                        >
                          <vs-button
                            type="flat"
                            icon-pack="material-icons"
                            icon="place"
                            class="p-0 help-btn"
                          ></vs-button>
                        </vx-tooltip>
                        <vx-tooltip
                          title="Physical"
                          color="secondary"
                          :text="data[indextr].addressPhysical"
                          v-if="data[indextr].HasPhysicalAddress"
                        >
                          <vs-button
                            type="flat"
                            icon-pack="material-icons"
                            icon="home_work"
                            class="p-0 help-btn"
                          ></vs-button>
                        </vx-tooltip>
                      </div>
                    </vs-td>

                    <vs-td
                      :data="data[indextr].IsSavedContact"
                      v-show="isViewFilters !== true"
                    >
                      <span v-show="data[indextr].IsSavedContact == true"
                        >{{ LabelConstant.buttonLabelYes }}</span
                      >
                      <span v-show="data[indextr].IsSavedContact == false"
                        >No</span
                      >
                    </vs-td>
                    <vs-td>
                      <a
                        v-show="data[indextr].IsSavedContact == false"
                        @click="
                          $emit(
                            'selectedContactIdInput',
                            data[indextr].PersonId
                          ),
                            $emit('clickForOpenContactCreditPopup')
                        "
                      >
                        <feather-icon
                          class="text-success"
                          icon="PhoneIcon"
                          v-show="data[indextr].HasDirectPhone"
                          title="Direct Phone"
                        ></feather-icon>
                        <feather-icon
                          class="text-warning"
                          icon="SmartphoneIcon"
                          v-show="data[indextr].HasMobilePhone"
                          title="Mobile Phone"
                        ></feather-icon>
                      </a>
                      <a
                        v-show="data[indextr].IsSavedContact == true"
                        @click="
                          $emit('contactsDetailPopupInput', true),
                            $emit('selectedContactIdInput', data[indextr].Id),
                            $emit('clickForGetSavedContactDetail')
                        "
                      >
                        <feather-icon
                          class="text-success"
                          icon="PhoneIcon"
                          v-show="
                            data[indextr].HasDirectPhone &&
                            data[indextr].IsSavedContact == true
                          "
                          title="Direct Phone"
                        ></feather-icon>
                        <feather-icon
                          class="text-warning"
                          icon="SmartphoneIcon"
                          v-show="
                            data[indextr].HasMobilePhone &&
                            data[indextr].IsSavedContact == true
                          "
                          title="Mobile Phone"
                        ></feather-icon>
                      </a>
                    </vs-td>

                    <vs-td>
                      <a
                        v-show="data[indextr].IsSavedContact == false"
                        @click="
                          $emit(
                            'selectedContactIdInput',
                            data[indextr].PersonId
                          ),
                            $emit('clickForOpenContactCreditPopup')
                        "
                      >
                        <feather-icon
                          class="text-success"
                          icon="MailIcon"
                          v-show="data[indextr].HasCompanyEmail"
                          title="Company Email"
                        ></feather-icon>
                        <feather-icon
                          class="text-warning"
                          icon="MailIcon"
                          v-show="data[indextr].HasPersonalEmail"
                          title="Personal Email"
                        ></feather-icon>
                      </a>
                      <a
                        v-show="data[indextr].IsSavedContact == true"
                        @click="
                          $emit('contactsDetailPopupInput', true),
                            $emit('selectedContactIdInput', data[indextr].Id),
                            $emit('clickForGetSavedContactDetail')
                        "
                      >
                        <feather-icon
                          class="text-success"
                          icon="MailIcon"
                          v-show="
                            data[indextr].HasCompanyEmail &&
                            data[indextr].IsSavedContact == true
                          "
                          title="Company Email"
                        ></feather-icon>
                        <feather-icon
                          class="text-warning"
                          icon="MailIcon"
                          v-show="
                            data[indextr].HasPersonalEmail &&
                            data[indextr].IsSavedContact == true
                          "
                          title="Personal Email"
                        ></feather-icon>
                      </a>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
              <!-- Total contacts and Pagination -->
              <div class="vx-row mt-2">
                <div class="vx-col w-full lg:w-full">
                  <div
                    class="
                      flex
                      items-center
                      justify-between
                      paginate-row
                      flex-wrap
                    "
                  >
                    <p class="table-data-entries-details">
                      Total Contacts:
                      <span>{{
                        totalContacts | tableRecordNumberFormatter
                      }}</span>
                    </p>
                    <!-- <paginate
                      ref="contactsMainPaginate"
                      :page-count="totalPages"
                      :click-handler="searchContactsByPagination"
                      :prev-text="'<'"
                      :next-text="'>'"
                      :page-range="3"
                      :first-last-button="true"
                      :first-button-text="'|<'"
                      :last-button-text="'>|'"
                      :container-class="'paginate'"
                    >
                    </paginate> -->
                    <!-- <paginate
                    ref="contactsMainPaginate"
                    :page-count="1"
                    :click-handler="searchContactsByPagination"
                    :prevText="'< Prev'"
                    :nextText="'Next >'"
                    :page-range="1"
                    :first-last-button="false"
                    :container-class="'paginate'"
                  >
                  </paginate> -->
                    <b-pagination
                      ref="contactsInnerPaginate"
                      pills
                      size="sm"
                      class="pr-3 bootstrap-pagination"
                      v-model="currentPage"
                      :total-rows="totalPages"
                      :per-page="recordPerInnerPageValue"
                      :limit="1"
                      prev-text="< Prev"
                      next-text="Next >"
                      :hide-goto-end-buttons="true"
                      @change="searchPaginationSetTimeOut()"
                    ></b-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
        <!-- Available Contacts, Intent Signal -->
        <vx-card class="mb-base" v-if="isAvailableContactsIntentSignal">
          <div>
            <vs-tabs class="search-tab">
              <!-- Available Contacts -->
              <vs-tab label="Available Contacts" class="p-0">
                <!-- Per page, Export, Add to My Saved Contacts -->
                <div class="card-title more-card-title flex align-center justify-end">
                  <!-- <div>
                    <v-select
                      class="mr-1 float-left show-tbl-data min-width-80"
                      label="value"
                      :options="showEntriesOptions"
                      :reduce="(option) => option.value"
                      :clearable="false"
                      v-model="recordPerInnerPage"
                    />
                    <vs-button
                      title="Update"
                      color="warning"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-filter"
                      class="mr-1 float-left"
                      v-on:click="isViewFilters = !isViewFilters"
                      >{{
                        isViewFilters ? "Hide Filter" : "Show Filter"
                      }}</vs-button
                    >
                    <vs-button
                      title="Update"
                      color="primary"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-download"
                      class="mr-1 float-left"
                      @click="$emit('clickForExport')"
                      :disabled="disabledExportORAddToMySavedContactsORDownload"
                      >{{ LabelConstant.buttonLabelExport }}</vs-button
                    >

                    <vs-button
                      title="Update"
                      color="success"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-users"
                      class="mr-1 float-left"
                      @click="$emit('clickForAddToMySavedContacts')"
                      :disabled="disabledExportORAddToMySavedContactsORDownload"
                      >{{ LabelConstant.buttonLabelAddtoMySavedContacts }}
                    </vs-button>

                    <vs-button
                      :title="LabelConstant.buttonLabelDownloadLimit"
                      color="warning"
                      type="filled"
                      class="mr-1 float-left"
                      >{{ LabelConstant.buttonLabelDownloadLimit }}:
                      {{
                        totalContactLimitRemaining | tableRecordNumberFormatter
                      }}
                    </vs-button>
                  </div> -->
                </div>
                <div>
                  <vs-navbar class="nabarx mb-8 table-nav">
                    <div slot="title">
                      <vs-navbar-title> Available Contacts </vs-navbar-title>
                    </div>
                    <vs-navbar-item index="0">
                      <a v-on:click="isViewFilters = !isViewFilters">{{
                        isViewFilters ? "Hide Filter" : "Show Filter"
                      }}</a>
                    </vs-navbar-item>
 <vs-navbar-item index="2">
                <dropdown-menu class="dropdown-export">
                  <button slot="trigger" class="dropdown-export-btn">
                    {{ LabelConstant.buttonLabelExport }}
                    <i
                      class="vs-icon notranslate icon-scale material-icons null"
                      >expand_more</i
                    >
                  </button>

                  <ul slot="body">
                    <li
                      @click="
                        $emit('exportType', 'csv'),
                          $emit('clickForExport', 'csv')
                      "
                    >
                      {{ LabelConstant.buttonLabelExportCSV }}
                    </li>
                    <li
                      @click="
                        $emit('exportType', 'excel'),
                          $emit('clickForExport', 'excel')
                      "
                    >
                      {{ LabelConstant.buttonLabelExportExcel }}
                    </li>
                    <li
                      @click="CommingsoonPopup = true"
                      v-if="
                        initialRole == 'Global Admin' &&
                        appRunningOnDev.toLowerCase() === 'true'
                      "
                    >
                      {{ LabelConstant.buttonLabelExportCRM }}
                    </li>
                    <!-- <li
                      @click="CommingsoonPopup = true"
                      v-if="
                        initialRole == 'Global Admin' &&
                        appRunningOnDev.toLowerCase() === 'true'
                      "
                    >
                      {{ LabelConstant.buttonLabelExportQueryResultToCSV }}
                    </li> -->
                  </ul>
                </dropdown-menu>
              </vs-navbar-item>
                    <!-- <vs-navbar-item index="2">
                      <vs-dropdown>
                        <a class="a-icon" href="#">
                          {{ LabelConstant.buttonLabelExport }}
                          <vs-icon class="" icon="expand_more"></vs-icon>
                        </a>

                        <vs-dropdown-menu>
                          <vs-dropdown-item
                            @click="
                              $emit('exportType', 'csv'),
                                $emit('clickForExport')
                            "
                          >
                            {{ LabelConstant.buttonLabelExportCSV }}
                          </vs-dropdown-item>
                          <vs-dropdown-item
                            @click="
                              $emit('exportType', 'excel'),
                                $emit('clickForExport')
                            "
                          >
                            {{ LabelConstant.buttonLabelExportExcel }}
                          </vs-dropdown-item>
                          <vs-dropdown-item
                            @click="CommingsoonPopup = true"
                            v-if="
                              initialRole == 'Global Admin' &&
                              appRunningOnDev.toLowerCase() === 'true'
                            "
                          >
                            {{ LabelConstant.buttonLabelExportCRM }}
                          </vs-dropdown-item>
                          <vs-dropdown-item
                            @click="CommingsoonPopup = true"
                            v-if="
                              initialRole == 'Global Admin' &&
                              appRunningOnDev.toLowerCase() === 'true'
                            "
                          >
                            {{ LabelConstant.buttonLabelExportQueryResultToCSV }}
                          </vs-dropdown-item>
                        </vs-dropdown-menu>
                      </vs-dropdown>
                    </vs-navbar-item> -->
                    <!-- <vs-navbar-item index="3">
                <vs-dropdown>
                  <a class="a-icon" href.prevent>
                    Tag
                    <vs-icon class="" icon="expand_more"></vs-icon>
                  </a>

                  <vs-dropdown-menu>
                    <vs-dropdown-item @click="$emit('addTagToSelected')"> Add Tag to Selected </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('removeTagToSelected')">
                      Remove Tag from Selected
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('manageTags')"> Manage Tags </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-navbar-item> -->
                    <vs-navbar-item index="1">
                      <a @click="$emit('clickForAddToMySavedContacts')"
                        >Save Contacts</a
                      >
                    </vs-navbar-item>
                    <v-select
                      class="mr-2 show-tbl-data"
                      label="value"
                      :options="showEntriesOptions"
                      :reduce="(option) => option.value"
                      :clearable="false"
                      v-model="recordPerInnerPage"
                    />
                  </vs-navbar>
                </div>
                <!-- Download Limit over message -->
                <div
                  class="mb-2 sm:w-auto w-full flex flex-col"
                  v-if="disabledExportORAddToMySavedContactsORDownload"
                >
                  <p class="error-msg text-right w-auto">
                    Your contact download limit is over.
                  </p>
                </div>
                <div class="vx-row find-people">
                  <!-- Search by criteria -->
                  <div v-if="isViewFilters" :class="isViewFilters === true ? ' vx-col xl:w-1/4 col-cus-12 mb-8 w-full' : 'd-none'">
                    <!-- Search Criteria -->
                    <div class="px-0 lead-accordion z-0 search-filters">
                      <div class="filter-card border-0 px-0">
                        <div slot="header" class="filter-header pl-0">Search Criteria</div>
                        <div class="filter-list label-mt-2">
                          <vs-collapse accordion class="p-0">
                            <!-- Job Criteria -->
                            <div class="filter-card px-0">
                              <vs-collapse-item>
                                <div slot="header">Job Criteria</div>
                                <div class="filter-list">
                                  <vs-checkbox v-model="showJobTitleText" @click="showJobTitleText == true ? (jobTitle = []) : false">Job Title</vs-checkbox>
                                  <div v-show="showJobTitleText">
                                    <input-tag v-model="jobTitle" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]" @input="termSeparation(jobTitle,'jobTitle')"></input-tag>
                                  </div>
                                  <vs-checkbox v-model="showJobLevelOptions" @click="jobLevel = []">Job Level</vs-checkbox>
                                  <div v-if="showJobLevelOptions">
                                    <v-select
                                      class="multiple-select type-multiselect"
                                      label="Value"
                                      :options="jobLevelOptions"
                                      v-model="jobLevel"
                                      multiple
                                    />
                                  </div>
                                  <vs-checkbox v-model="showJobFunctionOptions" @click="jobFunction = []">Job Function</vs-checkbox>
                                  <div v-if="showJobFunctionOptions">
                                    <v-select
                                      class="multiple-select type-multiselect"
                                      label="Value"
                                      :options="jobFunctionOptions"
                                      v-model="jobFunction"
                                      multiple
                                    />
                                  </div>
                                  <vs-checkbox v-model="showJobStartDateOptions" @click="jobStartDate = null" >Job Start Date
                                  </vs-checkbox>
                                  <div v-if="showJobStartDateOptions">
                                    <v-select
                                      label="Value"
                                      :options="jobStartDateOptions"
                                      v-model="jobStartDate"
                                    />
                                  </div>
                                  <vs-checkbox v-model="recentChangedJob">Recent Changed Job</vs-checkbox>
                                </div>
                              </vs-collapse-item>
                            </div>
                            <!-- Location -->
                            <div class="filter-card px-0">
                              <vs-collapse-item>
                                <div slot="header">Location</div>
                                <div class="filter-list">
                                  <vs-checkbox v-model="showContactRegionOptions" @click="contactRegion = []">Contact Region</vs-checkbox>
                                  <div v-if="showContactRegionOptions">
                                    <v-select
                                      class="multiple-select type-multiselect"
                                      label="Value"
                                      :options="contactRegionOptions"
                                      v-model="contactRegion"
                                      multiple
                                    />
                                  </div>
                                  <vs-checkbox v-model="showContactCountriesOptions" @click="contactCountries = []">Contact Country</vs-checkbox>
                                  <div v-if="showContactCountriesOptions">
                                    <v-select
                                      class="multiple-select type-multiselect"
                                      label="Value"
                                      :options="contactCountriesOptions"
                                      v-model="contactCountries"
                                      multiple
                                    />
                                  </div>
                                  <vs-checkbox v-model="showContactSatesOptions" @click="contactStates = []">Contact State</vs-checkbox>
                                  <div v-if="showContactSatesOptions">
                                    <v-select
                                      class="multiple-select type-multiselect"
                                      label="Value"
                                      :options="contactStatesOptions"
                                      v-model="contactStates"
                                      multiple
                                    />
                                  </div>
                                  <vs-checkbox v-model="showContactCity" @click="contactCity = []">Contact City</vs-checkbox>
                                  <div v-if="showContactCity">
                                    <input-tag v-model="contactCity" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]" @input="termSeparation(contactCity,'contactCity')"></input-tag>
                                  </div>
                                  <vs-checkbox v-model="showContactZipCode" @click="contactZipCode = []"> Contact Zip</vs-checkbox>
                                  <div v-if="showContactZipCode">
                                    <input-tag v-model="contactZipCode" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]" @input="termSeparation(contactZipCode,'contactZipCode')"></input-tag>
                                  </div>
                                </div>
                              </vs-collapse-item>
                            </div>
                            <!-- Personal Criteria -->
                            <div class="filter-card px-0">
                              <vs-collapse-item>
                                <div slot="header">Personal Criteria</div>
                                <div class="filter-list">
                                  <vs-checkbox v-model="showPersonalContactName" @click="clearFirstAndLastName()">Contact Name</vs-checkbox>
                                  <div v-if="showPersonalContactName" class="my-3 w-full">
                                    <vs-input v-model="personalContactFirstName" class="mb-2 w-full" placeholder="Type first name here"/>
                                    <vs-input v-model="personalContactLastName" class="w-full" placeholder="Type last name here"/>
                                  </div>
                                  <vs-checkbox v-model="showPersonalContactGenderOptions" @click="personalContactGender = []">Contact Gender</vs-checkbox>
                                  <div v-if="showPersonalContactGenderOptions">
                                    <v-select
                                      class="multiple-select type-multiselect"
                                      label="Value"
                                      :options="personalContactGenderOptions"
                                      v-model="personalContactGender"
                                      multiple
                                    />
                                  </div>
                                  <vs-checkbox v-model="showPersonalContactAlmaMater" @click="personalContactAlmaMater = []">Contact Alma Mater</vs-checkbox>
                                  <div v-if="showPersonalContactAlmaMater">
                                    <input-tag v-model="personalContactAlmaMater" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]" @input="termSeparation(personalContactAlmaMater,'personalContactAlmaMater')"></input-tag>
                                  </div>
                                  <vs-checkbox v-model="showPersonalContactBirthday">Contact Age</vs-checkbox>
                                  <div v-if="showPersonalContactBirthday" class="my-3">
                                    <vue-numeric-input
                                      placeholder="From"
                                      :controls="false"
                                      v-model="personalContactBirthdayFrom"
                                      class="mb-2"
                                      @change="fromAgeValidate"
                                    ></vue-numeric-input>
                                    <span class="text-danger text-sm error-msg" v-show="fromAgeErrorMessage">{{ fromAgeErrorMessage }}</span>
                                    <vue-numeric-input
                                      placeholder="To"
                                      :controls="false"
                                      v-model="personalContactBirthdayTo"
                                      @change="toAgeValidate"
                                    ></vue-numeric-input>
                                    <span class="text-danger text-sm error-msg mb-3" v-show="toAgeErrorMessage">{{ toAgeErrorMessage }}</span>
                                  </div>
                                  <vs-checkbox v-model="showPersonalContactSkills" @click="personalContactSkills = []">Contact Skill</vs-checkbox>
                                  <div v-if="showPersonalContactSkills">
                                    <input-tag v-model="personalContactSkills" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]" @input="termSeparation(personalContactSkills,'personalContactSkills')"></input-tag>
                                  </div>
                                  <vs-checkbox v-model="showPersonalContactFormerEmployer" @click="personalContactFormerEmployer = []">Contact Former Employer</vs-checkbox>
                                  <div v-if="showPersonalContactFormerEmployer">
                                    <input-tag v-model="personalContactFormerEmployer" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]" @input="termSeparation(personalContactFormerEmployer,'personalContactFormerEmployer')"></input-tag>
                                  </div>
                                  <vs-checkbox v-model="personalContactEmailAddressRequired ">Personal Email Address Required</vs-checkbox>
                                  <vs-checkbox v-model="personalContactPhoneRequired">Cell Phone Required</vs-checkbox>
                                </div>
                              </vs-collapse-item>
                            </div>
                            <!-- Suppression Criteria -->
                            <div class="filter-card px-0">
                              <vs-collapse-item>
                                <div slot="header">Suppression Criteria</div>
                                <div class="filter-list">
                                  <vs-checkbox v-model="showSuppressionContactTitles" @click="suppressionContactTitles = []">Contact Titles</vs-checkbox>
                                  <div v-if="showSuppressionContactTitles">
                                    <input-tag v-model="suppressionContactTitles" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]" @input="termSeparation(suppressionContactTitles,'suppressionContactTitles')"></input-tag>
                                  </div>
                                  <vs-checkbox v-model="showSuppressionEmailAddress" @click="suppressionEmailAddress = []">Email Address</vs-checkbox>
                                  <div v-if="showSuppressionEmailAddress">
                                    <input-tag v-model="suppressionEmailAddress" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]" @input="termSeparation(suppressionEmailAddress,'suppressionEmailAddress')"></input-tag>
                                  </div>
                                  <vs-checkbox v-model="suppressionExcludeMyLeads">Exclude My Leads</vs-checkbox>
                                </div>
                              </vs-collapse-item>
                            </div>
                            <!-- List Criteria -->
                            <div class="filter-card px-0">
                              <vs-collapse-item>
                                <div slot="header">List Criteria</div>
                                <div class="filter-list">
                                  <vs-checkbox v-model="listPrimaryContact">Primary Contact</vs-checkbox>
                                  <vs-checkbox v-model="listProfessionalEmail">Professional Email</vs-checkbox>
                                  <vs-checkbox v-model="showListEmailConfidenceScore" @click="listEmailConfidenceScore = 50">Email Confidence Score</vs-checkbox>
                                  <div class="fliter-slider contact-filter" v-if="showListEmailConfidenceScore">
                                    <!-- <vs-slider :max="100" :min="50" v-model="listEmailConfidenceScore"/> -->
                                    <vue-slider
                                      v-model="listEmailConfidenceScore"
                                      :min="50"
                                      :max="100"
                                      :process="false"
                                      :tooltip="'always'"
                                      :height="6"
                                      class="mr-4"
                                      :direction="'horizontal'"
                                    />
                                  </div>
                                  <vs-checkbox v-model="listDeterministicEmail">Deterministic Email</vs-checkbox>
                                  <vs-checkbox v-model="listPhoneNumber">Phone Number</vs-checkbox>
                                </div>
                              </vs-collapse-item>
                            </div>
                          </vs-collapse>
                        </div>
                      </div>
                    </div>
                    <div class="pt-5 mt-5 flex flex-wrap items-center">
                      <vs-button
                        :title="LabelConstant.buttonLabelSearch"
                        color="primary"
                        type="filled"
                        class="mr-1 float-left px-4 my-2"
                        @click="searchContacts()"
                        >{{ LabelConstant.buttonLabelSearch }}</vs-button
                      >
                      <!-- <vs-button
                        :title="buttonName"
                        color="success"
                        type="filled"
                        class="mr-2 float-left px-4 my-2"
                        >Save New Search</vs-button
                      >
                      <a
                        class="mr-1 float-left my-2 btn-link"
                        @click="listSavedSearch()"
                        >View Saved Search</a
                      > -->
                    </div>
                  </div>
                  <!--  table-->
                  <div
                    :class="
                      isViewFilters === true
                        ? 'vx-col xl:w-3/4 col-cus-12 mb-8 w-full border-left'
                        : 'vx-col col-cus-12 mb-8 w-full '
                    "
                  >
                    <!-- Available Contacts table -->
                    <vs-table
                      class="popup-company-tbl"
                      multiple
                      v-model="selectedInner"
                      :data="contacts"
                    >
                      <template slot="thead">
                        <vs-th>Full Name</vs-th>
                        <vs-th v-show="isViewFilters !== true">LinkedIn</vs-th>
                        <vs-th>Title</vs-th>
                        <vs-th>Location</vs-th>
                        <vs-th v-show="isViewFilters !== true">Saved</vs-th>
                        <vs-th>Phone</vs-th>
                        <vs-th>Email</vs-th>
                      </template>
                      <template slot-scope="{ data }">
                        <vs-tr
                          :data="tr"
                          :key="indextr"
                          v-for="(tr, indextr) in data"
                        >
                          <vs-td
                            :data="data[indextr].fullName"
                            class="word-break-all"
                          >
                            <div class="flex items-center">
                              <a
                                v-show="data[indextr].IsSavedContact == true"
                                @click="
                                  $emit('contactsDetailPopupInput', true),
                                    $emit(
                                      'selectedContactIdInput',
                                      data[indextr].Id
                                    ),
                                    $emit('clickForGetSavedContactDetail')
                                "
                              >
                                {{ data[indextr].fullName }}
                              </a>
                              <a
                                v-show="data[indextr].IsSavedContact == false"
                                @click="
                                  $emit(
                                    'selectedContactIdInput',
                                    data[indextr].PersonId
                                  ),
                                    $emit('clickForOpenContactCreditPopup')
                                "
                              >
                                {{ data[indextr].fullName }}
                              </a>
                            </div>
                          </vs-td>
                          <vs-td
                            :data="data[indextr].fullName"
                            v-show="isViewFilters !== true"
                          >
                            <a
                              v-show="
                                data[indextr].ContactLinkedinUrl &&
                                data[indextr].ContactLinkedinUrl != ''
                              "
                              :href="
                                'https://' + data[indextr].ContactLinkedinUrl
                              "
                              target="_blank"
                            >
                              <vs-avatar
                                color="primary"
                                icon-pack="feather"
                                icon="icon-linkedin"
                                size="28px"
                              />
                            </a>
                          </vs-td>
                          <vs-td
                            :data="data[indextr].ContactTitle"
                            class="word-break-all"
                          >
                            {{ data[indextr].ContactTitle }}
                          </vs-td>

                          <vs-td
                            :data="data[indextr].location"
                            class="word-break-all"
                          >
                            {{ data[indextr].location }}
                          </vs-td>

                          <vs-td
                            :data="data[indextr].IsSavedContact"
                            v-show="isViewFilters !== true"
                          >
                            <span v-show="data[indextr].IsSavedContact == true"
                              >{{ LabelConstant.buttonLabelYes }}</span
                            >
                            <span v-show="data[indextr].IsSavedContact == false"
                              >No</span
                            >
                          </vs-td>
                          <vs-td>
                            <a
                              v-show="data[indextr].IsSavedContact == false"
                              @click="
                                $emit(
                                  'selectedContactIdInput',
                                  data[indextr].PersonId
                                ),
                                  $emit('clickForOpenContactCreditPopup')
                              "
                            >
                              <feather-icon
                                class="text-success"
                                icon="PhoneIcon"
                                v-show="data[indextr].HasDirectPhone"
                                title="Direct Phone"
                              ></feather-icon>
                              <feather-icon
                                class="text-warning"
                                icon="SmartphoneIcon"
                                v-show="data[indextr].HasMobilePhone"
                                title="Mobile Phone"
                              ></feather-icon>
                            </a>
                            <a
                              v-show="data[indextr].IsSavedContact == true"
                              @click="
                                $emit('contactsDetailPopupInput', true),
                                  $emit(
                                    'selectedContactIdInput',
                                    data[indextr].Id
                                  ),
                                  $emit('clickForGetSavedContactDetail')
                              "
                            >
                              <feather-icon
                                class="text-success"
                                icon="PhoneIcon"
                                v-show="
                                  data[indextr].HasDirectPhone &&
                                  data[indextr].IsSavedContact == true
                                "
                                title="Direct Phone"
                              ></feather-icon>
                              <feather-icon
                                class="text-warning"
                                icon="SmartphoneIcon"
                                v-show="
                                  data[indextr].HasMobilePhone &&
                                  data[indextr].IsSavedContact == true
                                "
                                title="Mobile Phone"
                              ></feather-icon>
                            </a>
                          </vs-td>

                          <vs-td>
                            <a
                              v-show="data[indextr].IsSavedContact == false"
                              @click="
                                $emit(
                                  'selectedContactIdInput',
                                  data[indextr].PersonId
                                ),
                                  $emit('clickForOpenContactCreditPopup')
                              "
                            >
                              <feather-icon
                                class="text-success"
                                icon="MailIcon"
                                v-show="data[indextr].HasCompanyEmail"
                                title="Company Email"
                              ></feather-icon>
                              <feather-icon
                                class="text-warning"
                                icon="MailIcon"
                                v-show="data[indextr].HasPersonalEmail"
                                title="Personal Email"
                              ></feather-icon>
                            </a>
                            <a
                              v-show="data[indextr].IsSavedContact == true"
                              @click="
                                $emit('contactsDetailPopupInput', true),
                                  $emit(
                                    'selectedContactIdInput',
                                    data[indextr].Id
                                  ),
                                  $emit('clickForGetSavedContactDetail')
                              "
                            >
                              <feather-icon
                                class="text-success"
                                icon="MailIcon"
                                v-show="
                                  data[indextr].HasCompanyEmail &&
                                  data[indextr].IsSavedContact == true
                                "
                                title="Company Email"
                              ></feather-icon>
                              <feather-icon
                                class="text-warning"
                                icon="MailIcon"
                                v-show="
                                  data[indextr].HasPersonalEmail &&
                                  data[indextr].IsSavedContact == true
                                "
                                title="Personal Email"
                              ></feather-icon>
                            </a>
                          </vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                    <!-- Total contacts and Pagination -->
                    <div class="vx-row mt-2">
                      <div class="vx-col w-full lg:w-full">
                        <div
                          class="
                            flex
                            items-center
                            justify-between
                            paginate-row
                            flex-wrap
                          "
                        >
                          <p class="table-data-entries-details">
                            Total Contacts:
                            <span>{{
                              totalContacts | tableRecordNumberFormatter
                            }}</span>
                          </p>
                          <!-- <paginate
                            :page-count="totalPages"
                            :click-handler="searchContactsByPagination"
                            :prev-text="'<'"
                            :next-text="'>'"
                            :page-range="3"
                            :first-last-button="true"
                            :first-button-text="'|<'"
                            :last-button-text="'>|'"
                            :container-class="'paginate'"
                          >
                          </paginate> -->
                          <b-pagination
                            ref="contactsMainPaginate"
                            pills
                            size="sm"
                            class="pr-3 bootstrap-pagination"
                            v-model="currentPage"
                            :total-rows="totalContacts"
                            :per-page="recordPerInnerPageValue"
                            :limit="1"
                            prev-text="< Prev"
                            next-text="Next >"
                            :hide-goto-end-buttons="true"
                            @change="searchPaginationSetTimeOut()"
                          ></b-pagination>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </vs-tab>
              <!-- Intent Signal -->
              <vs-tab label="Intent Signals">
                <div class="report-list">
                  <ul class="intent-services">
                    <li v-for="(intentSignal, index) in intentSignals" :key="index">
                      <feather-icon icon="UserIcon"></feather-icon>
                      <div>
                        <h3 class="mb-0">
                          {{ intentSignal.IntentDate | lastUpdatedDate }}{{ ": " }}Engaged with
                          {{ intentSignal.IntentTrigger }}:
                          {{ intentSignal.IntentValue }}
                        </h3>
                      </div>
                      <vs-chip color="primary" class="text-white ml-2">{{ intentSignal.IntentScore }}</vs-chip>
                    </li>
                  </ul>
                </div>
              </vs-tab>
            </vs-tabs>
          </div>
        </vx-card>
        <!-- end tabs -->
      </div>
    </template>
    <div class="text-right mt-6 mb-8">
      <vs-button
        color="grey"
        @click="clearFilter(), $emit('clickForClose')"
        type="border"
        class="ml-4 grey-btn mr-3"
        >{{ LabelConstant.buttonLabelClose }}</vs-button
      >
    </div>
    <!-- <vs-popup
      title="Download Companies"
      :active.sync="downloadExcelCompaniesPopup"
    >
      <template>
        <div class="vx-row px-10 mb-3">
          <div class="vx-col w-full text-center mb-2">
            <xlsx-workbook>
              <xlsx-sheet
                :collection="downloadCompany.data"
                v-for="downloadCompany in downloadCompanies"
                :key="downloadCompany.name"
                :sheet-name="downloadCompany.name"
              />
              <xlsx-download
                :filename="'Companies.xlsx'"
                :options="{ bookType: 'xlsx' }"
              >
                <button
                  class="link-text"
                  @click="downloadExcelCompaniesPopup = false"
                >
                  Download Excel
                </button>
              </xlsx-download>
              <br />
              <xlsx-download
                :filename="'Companies.csv'"
                :options="{ bookType: 'csv' }"
              >
                <button
                  class="link-text"
                  @click="downloadExcelCompaniesPopup = false"
                >
                  Download CSV
                </button>
              </xlsx-download>
            </xlsx-workbook>
          </div>
        </div>
      </template>
      <div class="text-center mt-6 mb-8">
        <vs-button
          color="grey"
          type="border"
          class="grey-btn"
          @click="(downloadExcelCompaniesPopup = false), (selectedMain = [])"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup> -->
    <vs-popup title="Coming soon" :active.sync="CommingsoonPopup">
      <template>
        <vx-card>
          <div class="vx-row">
            <div class="vx-col w-full text-center">
              <div class="coming-soon sm:w-1/2 w-full py-5 mx-auto">
                <img
                  src="../../../src/assets/images/maintance.png"
                  class="d-inline-block w-full"
                />
                <h4 class="text-primary py-5">Coming soon</h4>
              </div>
            </div>
          </div>
        </vx-card>
        <div class="text-center float-right mt-6 mb-8">
          <vs-button
            color="primary"
            type="border"
            @click="CommingsoonPopup = false"
            >{{ LabelConstant.buttonLabelOkay }}</vs-button
          >
        </div>
      </template>
    </vs-popup>
  </vs-popup>
</template>

<script>
import { BCardText } from "bootstrap-vue";
import vSelect from "vue-select";
import InputTag from "vue-input-tag";
import VueNumericInput from "vue-numeric-input";
import moment from "moment";
import vueSlider from "vue-slider-component";
import { BPagination } from "bootstrap-vue";
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";
import VueJwtDecode from "vue-jwt-decode";
import DropdownMenu from "v-dropdown-menu";
import "v-dropdown-menu/dist/v-dropdown-menu.css";

export default {
  components: {
    BCardText,
    "v-select": vSelect,
    InputTag,
    VueNumericInput,
    vueSlider,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
    BPagination,
    DropdownMenu,
    VueJwtDecode,
  },
  props: {
    companyDetailPopup: {
      type: Boolean,
      required: true,
      default: false,
    },
    companyDetail: {
      required: true,
    },
    showEntriesOptions: {
      type: Array,
      required: true,
    },
    recordPerInnerPageValue: {
      type: Number,
      required: true,
    },
    isShowAddToMySavedContactsButton: {
      type: Boolean,
    },
    disabledExportORAddToMySavedContactsORDownload: {
      type: Boolean,
      default: false,
    },
    totalContactLimitRemaining: {
      type: Number,
      required: false,
    },
    selectedInnerValue: {
      type: Array,
      required: false,
    },
    companyTotalContacts: {
      type: Number,
      required: true,
    },
    companyTotalPages: {
      type: Number,
      required: true,
    },
    jobLevelOptions: {},
    jobFunctionOptions: {},
    jobStartDateOptions: {},
    contactRegionOptions: {},
    addressTypeOptions: {},
    contactCountriesOptions: {},
    contactStatesOptions: {},
    personalContactGenderOptions: {},
    isAvailableContactsIntentSignal: {},
    intentSignals: {},
    selectedCompanyId: {},
    companyDetailsScore: {},
  },
  data() {
    return {
      initialRole: "",

      //Set to show and hide the export tag menu's
      appRunningOnDev: process.env.VUE_APP_RUNNING_ON_DEV
        ? process.env.VUE_APP_RUNNING_ON_DEV
        : "",

      currentPage: 1,
      tagsTempArr: [],

      isViewFilters: false,
      //Job Criteria
      showJobTitleText: false,
      jobTitle: [],
      showJobLevelOptions: false,
      jobLevel: [],
      showJobFunctionOptions: false,
      jobFunction: [],
      showJobStartDateOptions: false,
      jobStartDate: null,
      recentChangedJob: false,
      //Location Criteria
      showContactRegionOptions: false,
      contactRegion: [],
      showAddressTypeOptions: false,
      addressType: [],
      showContactCountriesOptions: false,
      contactCountries: [],
      showContactSatesOptions: false,
      contactStates: [],
      showContactCity: false,
      contactCity: [],
      showContactZipCode: false,
      contactZipCode: [],
      //personal Criteria
      showPersonalContactName: false,
      personalContactFirstName: null,
      personalContactLastName: null,
      showPersonalContactGenderOptions: false,
      personalContactGender: [],
      showPersonalContactAlmaMater: false,
      personalContactAlmaMater: [],
      showPersonalContactBirthday: false,
      personalContactBirthdayFrom: 0,
      personalContactBirthdayTo: 0,
      fromAgeErrorMessage: null,
      toAgeErrorMessage: null,
      showPersonalContactSkills: false,
      personalContactSkills: [],
      showPersonalContactFormerEmployer: false,
      personalContactFormerEmployer: [],
      personalContactEmailAddressRequired: false,
      personalContactPhoneRequired: false,
      //Suppression Criteria
      showSuppressionContactTitles: false,
      suppressionContactTitles: [],
      showSuppressionEmailAddress: false,
      suppressionEmailAddress: [],
      suppressionExcludeMyLeads: false,
      //List Criteria
      listPrimaryContact: false,
      listProfessionalEmail: false,
      showListEmailConfidenceScore: false,
      listEmailConfidenceScore: 50,
      listDeterministicEmail: false,
      listPhoneNumber: false,

      searchContactsParams: null,
      contacts: [],
      totalContacts: 0,
      totalPages: 0,

      dynamicLocationColumnName: "HQ Location",
      CommingsoonPopup: false,
    };
  },
  computed: {
    tags: {
      get: function () {
        let arr = [];
        let that = this;
        for (let i = 0; i < this.jobTitle.length; i++) {
          if (!arr.includes(this.jobTitle[i])) {
            arr.push("Job Title:" + this.jobTitle[i]);
          }
        }
        for (let i = 0; i < this.jobLevel.length; i++) {
          if (!arr.includes(this.jobLevel[i].Value)) {
            arr.push("Job Level:" + this.jobLevel[i].Value);
          }
        }
        for (let i = 0; i < this.jobFunction.length; i++) {
          if (!arr.includes(this.jobFunction[i])) {
            arr.push("Job Function:" + this.jobFunction[i].Value);
          }
        }
        if (this.jobStartDate) {
          arr.push("Job Start Date:" + this.jobStartDate.Value);
        }
        if (this.recentChangedJob) {
          arr.push("Recent Changed Job:Yes");
        }
        //Location Criteria
        for (let i = 0; i < this.contactRegion.length; i++) {
          if (!arr.includes(this.contactRegion[i])) {
            arr.push("Contact Region:" + this.contactRegion[i].Value);
          }
        }
        for (let i = 0; i < this.addressType.length; i++) {
          if (!arr.includes(this.addressType[i])) {
            arr.push("Address Type:" + this.addressType[i].Value);
          }
        }
        for (let i = 0; i < this.contactCountries.length; i++) {
          if (!arr.includes(this.contactCountries[i])) {
            arr.push("Contact Country:" + this.contactCountries[i].Value);
          }
        }
        for (let i = 0; i < this.contactStates.length; i++) {
          if (!arr.includes(this.contactStates[i])) {
            arr.push("Contact State:" + this.contactStates[i].Value);
          }
        }
        for (let i = 0; i < this.contactCity.length; i++) {
          if (!arr.includes(this.contactCity[i])) {
            arr.push("Contact City:" + this.contactCity[i]);
          }
        }
        for (let i = 0; i < this.contactZipCode.length; i++) {
          if (!arr.includes(this.contactZipCode[i])) {
            arr.push("Contact Zip:" + this.contactZipCode[i]);
          }
        }
        // if (this.localAddressRequired) {
        //   arr.push('Local Address Required:Yes');
        // }
        //personal Criteria
        if (this.personalContactFirstName) {
          arr.push("Contact First Name:" + this.personalContactFirstName);
        }
        if (this.personalContactLastName) {
          arr.push("Contact Last Name:" + this.personalContactLastName);
        }
        for (let i = 0; i < this.personalContactGender.length; i++) {
          if (!arr.includes(this.personalContactGender[i])) {
            arr.push("Contact Gender:" + this.personalContactGender[i].Value);
          }
        }
        for (let i = 0; i < this.personalContactAlmaMater.length; i++) {
          if (!arr.includes(this.personalContactAlmaMater[i])) {
            arr.push("Contact Alma Mater:" + this.personalContactAlmaMater[i]);
          }
        }
        if (this.personalContactBirthdayFrom) {
          arr.push("Contact From Age:" + this.personalContactBirthdayFrom);
        }
        if (this.personalContactBirthdayTo) {
          arr.push("Contact To Age:" + this.personalContactBirthdayTo);
        }
        for (let i = 0; i < this.personalContactSkills.length; i++) {
          if (!arr.includes(this.personalContactSkills[i])) {
            arr.push("Contact Skill:" + this.personalContactSkills[i]);
          }
        }
        for (let i = 0; i < this.personalContactFormerEmployer.length; i++) {
          if (!arr.includes(this.personalContactFormerEmployer[i])) {
            arr.push(
              "Contact Former Employee:" + this.personalContactFormerEmployer[i]
            );
          }
        }
        if (this.personalContactEmailAddressRequired) {
          arr.push("Personal Email Address Required:Yes");
        }
        if (this.personalContactPhoneRequired) {
          arr.push("Cell Phone Required:Yes");
        }
        //Suppression Criteria
        for (let i = 0; i < this.suppressionContactTitles.length; i++) {
          if (!arr.includes(this.suppressionContactTitles[i])) {
            arr.push(
              "Suppression Contact Titles:" + this.suppressionContactTitles[i]
            );
          }
        }
        for (let i = 0; i < this.suppressionEmailAddress.length; i++) {
          if (!arr.includes(this.suppressionEmailAddress[i])) {
            arr.push(
              "Suppression Email Address:" + this.suppressionEmailAddress[i]
            );
          }
        }
        if (this.suppressionExcludeMyLeads) {
          arr.push("Suppression Exclude My Leads:Yes");
        }
        //List Criteria
        if (this.listPrimaryContact) {
          arr.push("List Primary Contact:Yes");
        }
        if (this.listProfessionalEmail) {
          arr.push("List Professional Email:Yes");
        }
        if (
          this.listEmailConfidenceScore &&
          this.showListEmailConfidenceScore
        ) {
          arr.push(
            "List Email Confidence Score:" + this.listEmailConfidenceScore
          );
        }
        if (this.listDeterministicEmail) {
          arr.push("List Deterministic Email:Yes");
        }
        if (this.listPhoneNumber) {
          arr.push("List Phone Number:Yes");
        }
        that.tagsTempArr = [];
        that.tagsTempArr = arr;
        return arr;
      },
      set: function () {},
    },
    recordPerInnerPage: {
      get() {
        return this.recordPerInnerPageValue;
      },
      set(recordPerInnerPageValue) {
        this.$emit("recordPerInnerPageInput", recordPerInnerPageValue);
      },
    },
    selectedInner: {
      get() {
        return this.selectedInnerValue;
      },
      set(selectedInnerValue) {
        this.$emit("selectedInnerInput", selectedInnerValue);
      },
    },
  },
  created() {
    this.decodeJWT();
  },
  methods: {
    // comma and semicolon separated input tag
    termSeparation(values, name) {
      if (values[values.length - 1]) {
        let singleValue=values[values.length-1]
        let splitValue=singleValue.split(/[,|;]/);
        let valueSeprated=[]
        splitValue.forEach(e => {
          if(e.trim().length){
            valueSeprated.push(e.trim());
          }
        });

        if (name == "jobTitle") {
          this.jobTitle.pop();
          this.jobTitle = this.jobTitle.concat(valueSeprated);
          this.jobTitle = [...new Set(this.jobTitle)];
        }
        if (name == "contactCity") {
          this.contactCity.pop();
          this.contactCity = this.contactCity.concat(valueSeprated);
          this.contactCity = [...new Set(this.contactCity)];
        }
        if (name == "contactZipCode") {
          this.contactZipCode.pop();
          this.contactZipCode = this.contactZipCode.concat(valueSeprated);
          this.contactZipCode = [...new Set(this.contactZipCode)];
        }
        if (name == "personalContactAlmaMater") {
          this.personalContactAlmaMater.pop();
          this.personalContactAlmaMater = this.personalContactAlmaMater.concat(valueSeprated);
          this.personalContactAlmaMater = [...new Set(this.personalContactAlmaMater)];
        }
        if (name == "personalContactFormerEmployer") {
          this.personalContactFormerEmployer.pop();
          this.personalContactFormerEmployer = this.personalContactFormerEmployer.concat(valueSeprated);
          this.personalContactFormerEmployer = [...new Set(this.personalContactFormerEmployer)];
        }
        if (name == "personalContactSkills") {
          this.personalContactSkills.pop();
          this.personalContactSkills = this.personalContactSkills.concat(valueSeprated);
          this.personalContactSkills = [...new Set(this.personalContactSkills)];
        }
        if (name == "suppressionContactTitles") {
          this.suppressionContactTitles.pop();
          this.suppressionContactTitles = this.suppressionContactTitles.concat(valueSeprated);
          this.suppressionContactTitles = [...new Set(this.suppressionContactTitles)];
        }
        if (name == "suppressionEmailAddress") {
          this.suppressionEmailAddress.pop();
          this.suppressionEmailAddress = this.suppressionEmailAddress.concat(valueSeprated);
          this.suppressionEmailAddress = [...new Set(this.suppressionEmailAddress)];
        }
      }
    },
    // getCompanyDetailByPagination(pageNumber) {
    //   this.$emit("clickForGetCompanyDetailByPagination", pageNumber);
    // },
    clearSearchCriteria() {
      //Job Criteria
      this.showJobTitleText = false;
      this.showJobLevelOptions = false;
      this.showJobFunctionOptions = false;
      this.showJobStartDateOptions = false;

      //Location Criteria
      this.showContactRegionOptions = false;
      this.showAddressTypeOptions = false;
      this.showContactCountriesOptions = false;
      this.showContactSatesOptions = false;
      this.showContactCity = false;
      this.showContactZipCode = false;

      //Personal Criteria
      this.showPersonalContactName = false;
      this.showPersonalContactGenderOptions = false;
      this.showPersonalContactAlmaMater = false;
      this.showPersonalContactBirthday = false;
      this.fromAgeErrorMessage = null;
      this.toAgeErrorMessage = null;
      this.showPersonalContactSkills = false;
      this.showPersonalContactFormerEmployer = false;

      //Suppression Criteria
      this.showSuppressionContactTitles = false;
      this.showSuppressionEmailAddress = false;

      this.jobTitle = [];
      this.jobLevel = [];
      this.jobFunction = [];
      this.jobStartDate = null;
      this.recentChangedJob = false;

      //Location Criteria
      this.contactRegion = [];
      this.addressType = [];
      this.contactCountries = [];
      this.contactStates = [];
      this.contactCity = [];
      this.contactZipCode = [];

      //personal Criteria
      this.personalContactFirstName = null;
      this.personalContactLastName = null;
      this.personalContactGender = [];
      this.personalContactAlmaMater = [];
      this.personalContactBirthdayFrom = 0;
      this.personalContactBirthdayTo = 0;
      this.personalContactSkills = [];
      this.personalContactFormerEmployer = [];
      this.personalContactEmailAddressRequired = false;
      this.personalContactPhoneRequired = false;

      //Suppression Criteria
      this.suppressionContactTitles = [];
      this.suppressionEmailAddress = [];
      this.suppressionExcludeMyLeads = false;

      //List Criteria
      this.listPrimaryContact = false;
      this.listProfessionalEmail = false;
      this.showListEmailConfidenceScore = false;
      this.listEmailConfidenceScore = 50;
      this.listDeterministicEmail = false;
      this.listPhoneNumber = false;
    },
    clearFilter() {
      (this.isViewFilters = false), this.clearSearchCriteria();
    },
    /*utility function to manipulate Selected criteria*/
    arrayRemove(arr, value) {
      return arr.filter(function (ele) {
        return ele != value;
      });
    },
    //To update selected criteria
    testUpdateSelectedTags(value) {
      let keyArr = this.tagsTempArr.filter((x) => value.indexOf(x) === -1);
      let testStr = keyArr[0];
      let keyStr = keyArr[0].substring(keyArr[0].indexOf(":") + 1);
      //Job Criteria
      if (testStr.includes("Job Title:")) {
        this.jobTitle = this.arrayRemove(this.jobTitle, keyStr);
      }
      if (testStr.includes("Job Level:")) {
        let removableIndex = this.jobLevel.findIndex(function (arr) {
          if (arr.Value == keyStr) return true;
        });
        this.jobLevel.splice(removableIndex, 1);
      }
      if (testStr.includes("Job Function:")) {
        let removableIndex = this.jobFunction.findIndex(function (arr) {
          if (arr.Value == keyStr) return true;
        });
        this.jobFunction.splice(removableIndex, 1);
      }
      if (testStr.includes("Job Start Date:")) {
        this.jobStartDate = null;
      }
      if (testStr.includes("Recent Changed Job:")) {
        this.recentChangedJob = false;
      }
      //Location Criteria
      if (testStr.includes("Contact Region:")) {
        let removableIndex = this.contactRegion.findIndex(function (arr) {
          if (arr.Value == keyStr) return true;
        });
        this.contactRegion.splice(removableIndex, 1);
      }
      if (testStr.includes("Address Type:")) {
        let removableIndex = this.addressType.findIndex(function (arr) {
          if (arr.Value == keyStr) return true;
        });
        this.addressType.splice(removableIndex, 1);
      }
      if (testStr.includes("Contact Country:")) {
        let removableIndex = this.contactCountries.findIndex(function (arr) {
          if (arr.Value == keyStr) return true;
        });
        this.contactCountries.splice(removableIndex, 1);
      }
      if (testStr.includes("Contact State:")) {
        let removableIndex = this.contactStates.findIndex(function (arr) {
          if (arr.Value == keyStr) return true;
        });
        this.contactStates.splice(removableIndex, 1);
      }
      if (testStr.includes("Contact City:")) {
        this.contactCity = this.arrayRemove(this.contactCity, keyStr);
      }
      if (testStr.includes("Contact Zip:")) {
        this.contactZipCode = this.arrayRemove(this.contactZipCode, keyStr);
      }
      // if (testStr.includes("Local Address Required:")) {
      //   this.localAddressRequired = false;
      // }
      //personal Criteria
      if (testStr.includes("Contact First Name:")) {
        this.personalContactFirstName = null;
      }
      if (testStr.includes("Contact Last Name:")) {
        this.personalContactLastName = null;
      }
      if (testStr.includes("Contact Gender:")) {
        let removableIndex = this.personalContactGender.findIndex(function (
          arr
        ) {
          if (arr.Value == keyStr) return true;
        });
        this.personalContactGender.splice(removableIndex, 1);
      }
      if (testStr.includes("Contact Alma Mater:")) {
        this.personalContactAlmaMater = this.arrayRemove(
          this.personalContactAlmaMater,
          keyStr
        );
      }
      if (testStr.includes("Contact From Age:")) {
        this.personalContactBirthdayFrom = null;
      }
      if (testStr.includes("Contact To Age:")) {
        this.personalContactBirthdayTo = null;
      }
      if (testStr.includes("Contact Skill:")) {
        this.personalContactSkills = this.arrayRemove(
          this.personalContactSkills,
          keyStr
        );
      }
      if (testStr.includes("Contact Former Employee:")) {
        this.personalContactFormerEmployer = this.arrayRemove(
          this.personalContactFormerEmployer,
          keyStr
        );
      }
      if (testStr.includes("Personal Email Address Required:")) {
        this.personalContactEmailAddressRequired = false;
      }
      if (testStr.includes("Cell Phone Required:")) {
        this.personalContactPhoneRequired = false;
      }
      //Suppression Criteria
      if (testStr.includes("Suppression Contact Titles:")) {
        this.suppressionContactTitles = this.arrayRemove(
          this.suppressionContactTitles,
          keyStr
        );
      }
      if (testStr.includes("Suppression Email Address:")) {
        this.suppressionEmailAddress = this.arrayRemove(
          this.suppressionEmailAddress,
          keyStr
        );
      }
      if (testStr.includes("Suppression Exclude My Leads:")) {
        this.suppressionExcludeMyLeads = false;
      }
      //List Criteria
      if (testStr.includes("List Primary Contact:")) {
        this.listPrimaryContact = false;
      }
      if (testStr.includes("List Professional Email:")) {
        this.listProfessionalEmail = false;
      }
      if (testStr.includes("List Email Confidence Score:")) {
        this.showListEmailConfidenceScore = false;
        this.listEmailConfidenceScore = 50;
      }
      if (testStr.includes("List Deterministic Email:")) {
        this.listDeterministicEmail = false;
      }
      if (testStr.includes("List Phone Number:")) {
        this.listPhoneNumber = false;
      }
    },
    fromAgeValidate() {
      if (this.personalContactBirthdayFrom > this.personalContactBirthdayTo) {
        this.fromAgeErrorMessage = "From age should be less than To age.";
      } else {
        this.fromAgeErrorMessage = null;
        this.toAgeErrorMessage = null;
      }
    },
    toAgeValidate() {
      if (this.personalContactBirthdayTo < this.personalContactBirthdayFrom) {
        this.toAgeErrorMessage = "To age should be greater than From age.";
      } else {
        this.fromAgeErrorMessage = null;
        this.toAgeErrorMessage = null;
      }
    },
    clearFirstAndLastName() {
      if (this.showPersonalContactName) {
        this.personalContactFirstName = null;
        this.personalContactLastName = null;
      }
    },
    //Search contacts based on search criteria
    searchContacts() {
      //Job Criteria
      let isJobTitleEntered = false;
      let jobTitleEnteredParam = [];
      if (this.jobTitle.length > 0) {
        isJobTitleEntered = true;
        for (let i = 0; i < this.jobTitle.length; i++) {
          jobTitleEnteredParam.push({
            Text: this.jobTitle[i],
          });
        }
      }
      let isJobLevelSelected = false;
      let jobLevelSelectedParam = [];
      if (this.jobLevel.length > 0) {
        isJobLevelSelected = true;
        for (let i = 0; i < this.jobLevel.length; i++) {
          jobLevelSelectedParam.push({
            Text: this.jobLevel[i].Key,
          });
        }
      }
      let isjobFunctionSelected = false;
      let jobFunctionSelectedParam = [];
      if (this.jobFunction.length > 0) {
        isjobFunctionSelected = true;
        for (let i = 0; i < this.jobFunction.length; i++) {
          jobFunctionSelectedParam.push({
            Text: this.jobFunction[i].Key,
          });
        }
      }
      let isJobStartDateSelected = false;
      let jobStartDateSelectedParam = "";
      if (this.jobStartDate) {
        isJobStartDateSelected = true;
        jobStartDateSelectedParam = this.jobStartDate.Key;
      }
      let recentChangedJobParam = false;
      if (this.recentChangedJob) {
        recentChangedJobParam = this.recentChangedJob;
      }
      //Location Criteria
      let isContactRegionSelected = false;
      let contactRegionSelectedParam = [];
      if (this.contactRegion.length > 0) {
        isContactRegionSelected = true;
        for (let i = 0; i < this.contactRegion.length; i++) {
          contactRegionSelectedParam.push({
            Text: this.contactRegion[i].Key,
          });
        }
      }
      let isAddressTypeSelected = false;
      let addressTypeSelectedParam = [];
      if (this.addressType.length > 0) {
        isAddressTypeSelected = true;
        for (let i = 0; i < this.addressType.length; i++) {
          addressTypeSelectedParam.push({
            Text: this.addressType[i].Key,
          });
        }
      }
      let isContactCountriesSelected = false;
      let contactCountriesSelectedParam = [];
      if (this.contactCountries.length > 0) {
        isContactCountriesSelected = true;
        for (let i = 0; i < this.contactCountries.length; i++) {
          contactCountriesSelectedParam.push({
            Text: this.contactCountries[i].Key,
          });
        }
      }
      let isContactStatesSelected = false;
      let contactStatesSelectedParam = [];
      if (this.contactStates.length > 0) {
        isContactStatesSelected = true;
        for (let i = 0; i < this.contactStates.length; i++) {
          contactStatesSelectedParam.push({
            Text: this.contactStates[i].Key,
          });
        }
      }
      let isContactCityEntered = false;
      let contactCityEnteredParam = [];
      if (this.contactCity.length > 0) {
        isContactCityEntered = true;
        for (let i = 0; i < this.contactCity.length; i++) {
          contactCityEnteredParam.push({
            Text: this.contactCity[i],
          });
        }
      }
      let isContactZipCodeEntered = false;
      let contactZipCodeEnteredParam = [];
      if (this.contactZipCode.length > 0) {
        isContactZipCodeEntered = true;
        for (let i = 0; i < this.contactZipCode.length; i++) {
          contactZipCodeEnteredParam.push({
            Text: this.contactZipCode[i],
          });
        }
      }
      //Personal Criteria
      let isFullNameEntered = false;
      let personalContactFirstNameEnteredParam = "";
      let personalContactLastNameEnteredParam = "";
      if (this.personalContactFirstName || this.personalContactLastName) {
        isFullNameEntered = true;
        personalContactFirstNameEnteredParam = this.personalContactFirstName;
        personalContactLastNameEnteredParam = this.personalContactLastName;
      }
      let isPersonalContactGenderSelected = false;
      let personalContactGenderSelectedParam = [];
      if (this.personalContactGender.length > 0) {
        isPersonalContactGenderSelected = true;
        for (let i = 0; i < this.personalContactGender.length; i++) {
          personalContactGenderSelectedParam.push({
            Text: this.personalContactGender[i].Key,
          });
        }
      }
      let isPersonalContactAlmaMaterEntered = false;
      let personalContactAlmaMaterEnteredParam = [];
      if (this.personalContactAlmaMater.length > 0) {
        isPersonalContactAlmaMaterEntered = true;
        for (let i = 0; i < this.personalContactAlmaMater.length; i++) {
          personalContactAlmaMaterEnteredParam.push({
            Text: this.personalContactAlmaMater[i],
          });
        }
      }
      let isBirthDateEntered = false;
      let personalContactBirthdayFromEnteredParam = 0;
      let personalContactBirthdayToEnteredParam = 0;
      if (this.personalContactBirthdayFrom || this.personalContactBirthdayTo) {
        isBirthDateEntered = true;
        personalContactBirthdayFromEnteredParam =
          this.personalContactBirthdayFrom;
        personalContactBirthdayToEnteredParam = this.personalContactBirthdayTo;
      }
      let isPersonalContactFormerEmployerEntered = false;
      let personalContactFormerEmployerEnteredParam = [];
      if (this.personalContactFormerEmployer.length > 0) {
        isPersonalContactFormerEmployerEntered = true;
        for (let i = 0; i < this.personalContactFormerEmployer.length; i++) {
          personalContactFormerEmployerEnteredParam.push({
            Text: this.personalContactFormerEmployer[i],
          });
        }
      }
      let isPersonalContactSkillsEntered = false;
      let personalContactSkillsEnteredParam = [];
      if (this.personalContactSkills.length > 0) {
        isPersonalContactSkillsEntered = true;
        for (let i = 0; i < this.personalContactSkills.length; i++) {
          personalContactSkillsEnteredParam.push({
            Text: this.personalContactSkills[i],
          });
        }
      }
      let personalContactEmailAddressRequiredParam = false;
      if (this.personalContactEmailAddressRequired) {
        personalContactEmailAddressRequiredParam =
          this.personalContactEmailAddressRequired;
      }
      let personalContactPhoneRequiredParam = false;
      if (this.personalContactPhoneRequired) {
        personalContactPhoneRequiredParam = this.personalContactPhoneRequired;
      }
      //Suppression Criteria
      let isSuppressionContactTitlesEntered = false;
      let suppressionContactTitlesEnteredParam = [];
      if (this.suppressionContactTitles.length > 0) {
        isSuppressionContactTitlesEntered = true;
        for (let i = 0; i < this.suppressionContactTitles.length; i++) {
          suppressionContactTitlesEnteredParam.push({
            Text: this.suppressionContactTitles[i],
          });
        }
      }
      let isSuppressionEmailAddressEntered = false;
      let suppressionEmailAddressEnteredParam = [];
      if (this.suppressionEmailAddress.length > 0) {
        isSuppressionEmailAddressEntered = true;
        for (let i = 0; i < this.suppressionEmailAddress.length; i++) {
          suppressionEmailAddressEnteredParam.push({
            Text: this.suppressionEmailAddress[i],
          });
        }
      }
      let suppressionExcludeMyLeadsParam = false;
      if (this.suppressionExcludeMyLeads) {
        suppressionExcludeMyLeadsParam = this.suppressionExcludeMyLeads;
      }
      //List Criteria
      let listPrimaryContactParam = false;
      if (this.listPrimaryContact) {
        listPrimaryContactParam = this.listPrimaryContact;
      }
      let listProfessionalEmailParam = false;
      if (this.listProfessionalEmail) {
        listProfessionalEmailParam = this.listProfessionalEmail;
      }
      let listEmailConfidenceScoreParam = this.listEmailConfidenceScore;
      let listDeterministicEmailParam = false;
      if (this.listDeterministicEmail) {
        listDeterministicEmailParam = this.listDeterministicEmail;
      }
      let listPhoneNumberParam = false;
      if (this.listPhoneNumber) {
        listPhoneNumberParam = this.listPhoneNumber;
      }

      this.searchContactsParams = {
        IsCompanyIdSelected: true,
        CompanyIds: [{ Text: this.selectedCompanyId.toString() }],
        // CompanyIds: [{Text: 81869801}],

        IsContactTitlesSelected: isJobTitleEntered,
        ContactTitles: jobTitleEnteredParam,
        IsContactLevelsSelected: isJobLevelSelected,
        ContactLevels: jobLevelSelectedParam,
        IsContactJobFunctionsSelected: isjobFunctionSelected,
        ContactJobFunctions: jobFunctionSelectedParam,
        IsContactJobStartDateSelected: isJobStartDateSelected,
        ContactJobStartDate: jobStartDateSelectedParam,
        HasChangedJobsRecently: recentChangedJobParam,

        // IsCompanyNamesSelected: false,
        // CompanyNames: [],
        // IsURLsSelected: false,
        // Urls: [],
        // IsCompanyIndustriesSelected: false,
        // CompanyIndustries: [],
        // IsCompanySizesSelected: false,
        // CompanySizeIds: [],
        // IsCompanyRevenuesSelected: false,
        // CompanyRevenueIds: [],
        // IsCompanyNAICSCodesSelected: false,
        // CompanyNAICSCodes: [],
        // IsCompanySICCodesSelected: false,
        // CompanySICCodes: [],

        IsRegionSelected: isContactRegionSelected,
        Regions: contactRegionSelectedParam,
        IsAddressTypeSelected: isAddressTypeSelected,
        AddressTypes: addressTypeSelectedParam,
        IsCountrySelected: isContactCountriesSelected,
        Countries: contactCountriesSelectedParam,
        IsStatesSelected: isContactStatesSelected,
        States: contactStatesSelectedParam,
        IsCitiesSelected: isContactCityEntered,
        Cities: contactCityEnteredParam,
        IsZipCodesSelected: isContactZipCodeEntered,
        ZipCodes: contactZipCodeEnteredParam,

        IsFullNameSelected: isFullNameEntered,
        FirstName: personalContactFirstNameEnteredParam,
        LastName: personalContactLastNameEnteredParam,
        IsGenderSelected: isPersonalContactGenderSelected,
        Gender: personalContactGenderSelectedParam,
        IsAlmaMaterSelected: isPersonalContactAlmaMaterEntered,
        AlmaMater: personalContactAlmaMaterEnteredParam,
        IsBirthDateSelected: isBirthDateEntered,
        FromAge: personalContactBirthdayFromEnteredParam,
        ToAge: personalContactBirthdayToEnteredParam,
        IsContactSkillsSelected: isPersonalContactSkillsEntered,
        ContactSkills: personalContactSkillsEnteredParam,
        IsFormerEmployerSelected: isPersonalContactFormerEmployerEntered,
        FormerEmployer: personalContactFormerEmployerEnteredParam,
        PersonalEmailOnly: personalContactEmailAddressRequiredParam,
        IsCellPhoneRequired: personalContactPhoneRequiredParam,

        // IsUrlsToSuppressSelected: false,
        // UrlsToSuppress: [],
        IsTitlesToSuppressSelected: isSuppressionContactTitlesEntered,
        TitlesToSuppress: suppressionContactTitlesEnteredParam,
        IsEmailsToSuppressSelected: isSuppressionEmailAddressEntered,
        EmailsToSuppress: suppressionEmailAddressEnteredParam,
        IsExcludeMyLeadsSelected: suppressionExcludeMyLeadsParam,

        PrimaryContactsOnly: listPrimaryContactParam,
        IsProfessionalEmailRequired: listProfessionalEmailParam,
        DeterministicEmailsOnly: listDeterministicEmailParam,
        HasPhone: listPhoneNumberParam,

        IsEmailConfidenceSelected: this.showListEmailConfidenceScore,
        EmailConfidenceScore: listEmailConfidenceScoreParam,

        DirectDialOnly: false,
        IsEmailAddressSelected: false,
        IsUseEmailSuppressionFileSelected: false,
        IsUseTitleSuppressionFileSelected: false,
        IsUseUrlSuppressionFileSelected: false,
        IsLimitPerCompany: true,
        IsLimitPerListSelected: true,
        LimitPerCompanyCount: 0,
        LimitPerList: 0,
        Page: 1,
        PageSize: this.recordPerInnerPage,
        RequiredEmail: true,
        EmailAddress: [],
        UseAllSuppressionRecords: true,
        ValidateEmails: true,
      };
      if (this.contacts.length > 0) {
        this.currentPage = 1;
      }
      this.$vs.loading();
      this.axios
        .post(
          "/ws/FindAnyoneSearchContacts/SearchContacts",
          this.searchContactsParams
        )
        .then((response) => {
          let data = response.data;
          if (data.Contacts) {
            this.contacts = data.Contacts;
          } else {
            this.contacts = [];
          }
          this.totalContacts = data.ContactCount;
          this.totalPages = Math.ceil(data.ContactCount / data.ContactsPerPage);
          if (data.ContactCount >= 10000) {
            this.totalPages = 999;
          }
          let that = this;
          this.contacts.map(function (manipulatedData) {
            manipulatedData.location = that.beautifyAddress(manipulatedData);
            manipulatedData.addressHq = that.beautifyAddress(
              manipulatedData,
              "hq"
            );
            manipulatedData.addressLocal = that.beautifyAddress(
              manipulatedData,
              "local"
            );
            manipulatedData.addressPhysical = that.beautifyAddress(
              manipulatedData,
              "physical"
            );
            manipulatedData.fullName =
              manipulatedData.ContactFirstName +
              " " +
              manipulatedData.ContactLastName;
            return manipulatedData;
          });
          this.manipulateLocationCoulumnHeading();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    //Search criteria based on criteria via Pagination
    searchContactsByPagination() {
      let pageNumber = this.currentPage;
      this.searchContactsParams.Page = pageNumber;
      this.searchContactsParams.PageSize = this.recordPerInnerPage;
      this.$vs.loading();
      this.axios
        .post(
          "/ws/FindAnyoneSearchContacts/SearchContacts",
          this.searchContactsParams
        )
        .then((response) => {
          let data = response.data;
          if (data.Contacts) {
            this.contacts = data.Contacts;
          } else {
            this.contacts = [];
          }
          this.totalContacts = data.ContactCount;
          this.totalPages = Math.ceil(data.ContactCount / data.ContactsPerPage);
          if (data.ContactCount >= 10000) {
            this.totalPages = 999;
          }
          let that = this;
          this.contacts.map(function (manipulatedData) {
            manipulatedData.location = that.beautifyAddress(
              manipulatedData,
              "other"
            );
            manipulatedData.addressHq = that.beautifyAddress(
              manipulatedData,
              "hq"
            );
            manipulatedData.addressLocal = that.beautifyAddress(
              manipulatedData,
              "local"
            );
            manipulatedData.addressPhysical = that.beautifyAddress(
              manipulatedData,
              "physical"
            );
            manipulatedData.fullName =
              manipulatedData.ContactFirstName +
              " " +
              manipulatedData.ContactLastName;
            return manipulatedData;
          });
          this.manipulateLocationCoulumnHeading();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
        });
    },
    //Address beautification to show in table
    beautifyAddress(value, type) {
      let contactAddressArr = [];
      if (type == "hq") {
        contactAddressArr.push(value.HqCity);
        contactAddressArr.push(value.HqState);
        contactAddressArr.push(value.HqCountry);
      } else if (type == "local") {
        contactAddressArr.push(value.LocalCity);
        contactAddressArr.push(value.LocalState);
        contactAddressArr.push(value.LocalCountry);
      } else if (type == "physical") {
        contactAddressArr.push(value.PhysicalCity);
        contactAddressArr.push(value.PhysicalState);
        contactAddressArr.push(value.PhysicalCountry);
      } else {
        contactAddressArr.push(value.ContactCity);
        contactAddressArr.push(value.ContactState);
        contactAddressArr.push(value.ContactCountry);
      }
      let contactAddressFinal = "";
      for (let i = 0; i < contactAddressArr.length; i++) {
        if (contactAddressArr[i] && contactAddressArr[i].length > 0) {
          contactAddressFinal += contactAddressArr[i] + ", ";
        }
      }
      return contactAddressFinal.substring(0, contactAddressFinal.length - 2);
    },
    manipulateLocationCoulumnHeading() {
      if (this.addressType.length > 0) {
        let isHq = false;
        let isLocal = false;
        let isPhysical = false;
        for (let i = 0; i < this.addressType.length; i++) {
          if (this.addressType[i].Key == "hq") {
            isHq = true;
          } else if (this.addressType[i].Key == "local") {
            isLocal = true;
          } else {
            isPhysical = true;
          }
          this.dynamicLocationColumnName =
            isHq && isLocal && isPhysical
              ? "HQ Location"
              : isLocal && isPhysical
              ? "Local Location"
              : isHq
              ? "HQ Location"
              : isLocal
              ? "Local Location"
              : "Physical Location";
        }
      } else {
        this.dynamicLocationColumnName = "HQ Location";
      }
    },
    decodeJWT() {
      let uid = this.$route.params.id;
      let jwtDecode = VueJwtDecode.decode(
        this.$store.state.authTokenVV[uid].token
      );
      this.initialRole = jwtDecode.initialRole;
    },
    searchPaginationSetTimeOut() {
      setTimeout(() => {
        this.searchContactsByPagination();
      }, 1000);
    },
  },
  filters: {
    lastUpdatedDate: function (value) {
      return moment(value).format("MM/DD/YYYY");
    },
  },
};
</script>
