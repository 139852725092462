<template>
  <vs-popup
    class="popup-width close-open"
    :title="LabelConstant.popupTitleLabelContactDetails"
    :active.sync="contactsDetailPopup"
  >
    <vs-button
      @click="$emit('clickForClose')"
      color="secondary"
      type="filled"
      class="mb-8 float-right close-popup-btn"
      >{{ LabelConstant.buttonLabelClose }}
    </vs-button>
    <template>
      <div id="page-user-view" v-if="contactDetails">
        <!-- Company details  -->
        <vx-card :title="contactDetails.CompanyName" class="mb-base">
          <div class="vx-row">
            <!-- Company logo -->
            <div class="vx-col" id="avatar-col">
              <div class="img-container mb-4">
                <img
                  :src="contactDetails.CompanyLogoUrl"
                  height="100"
                  width="100"
                />
              </div>
            </div>
            <!-- Social icon, Company Website, Industry, Specialities -->
            <div class="vx-col flex-1" id="account-info-col-1">
              <!-- Social icon -->
              <div class="flex">
                <!-- <vs-avatar
                    color="primary"
                    icon-pack="feather"
                    icon="icon-facebook"
                  />
                  <vs-avatar
                    color="primary"
                    icon-pack="feather"
                    icon="icon-twitter"
                  /> -->
                <a
                  v-show="
                    contactDetails.CompanyLinkedInUrl &&
                    contactDetails.CompanyLinkedInUrl != ''
                  "
                  :href="'https://' + contactDetails.CompanyLinkedInUrl"
                  target="_blank"
                >
                  <vs-avatar
                    color="primary"
                    icon-pack="feather"
                    icon="icon-linkedin"
                  />
                </a>
              </div>
              <table>
                <!-- Company Website -->
                <tr>
                  <td class="font-semibold">
                    <feather-icon
                      icon="GlobeIcon"
                      title="Website"
                    ></feather-icon>
                  </td>
                  <td>{{ contactDetails.CompanyDomain }}</td>
                </tr>
                <!-- Company Industry -->
                <tr>
                  <td class="font-semibold">
                    <feather-icon icon="LayersIcon"></feather-icon>
                  </td>
                  <td class="word-break-all">
                    {{ contactDetails.CompanyIndustry }}
                  </td>
                </tr>
                <!-- Company Specialities -->
                <tr>
                  <td class="font-semibold">
                    <feather-icon icon="TagIcon"></feather-icon>
                  </td>
                  <td class="word-break-all">
                    {{ contactDetails.CompanySpecialties | addSpaceAfterComma }}
                  </td>
                </tr>
              </table>
            </div>
            <!-- Number of Company Employee, Phone, Address, Revenue -->
            <div
              class="vx-col lg:w-1/2 md:w-3/3 sm:w-3/3 w-full lg:pt-0 pt-4"
              id="account-info-col-2"
            >
              <table>
                <!-- Number of Company Employee -->
                <tr>
                  <td class="font-semibold">
                    <feather-icon icon="UsersIcon"></feather-icon>
                  </td>
                  <td>
                    {{
                      contactDetails.CompanyEmployees
                        | tableRecordNumberFormatter
                    }}
                  </td>
                </tr>
                <!-- Company Phone -->
                <tr>
                  <td class="font-semibold">
                    <feather-icon icon="PhoneIcon"></feather-icon>
                  </td>
                  <td>{{ contactDetails.CompanyPhone }}</td>
                </tr>
                <!-- Company Address -->
                <tr>
                  <td class="font-semibold">
                    <feather-icon icon="MapPinIcon"></feather-icon>
                  </td>
                  <td class="word-break-all">
                    {{ contactDetails.contactAddress }}
                  </td>
                </tr>
                <!-- Company Revenue -->
                <tr>
                  <td class="font-semibold">
                    <feather-icon icon="DollarSignIcon"></feather-icon>
                  </td>
                  <td>{{ contactDetails.CompanyRevenueRange }}</td>
                </tr>
              </table>
            </div>
          </div>
        </vx-card>
        <!-- About Company -->
        <vx-card :title="'About ' + contactDetails.CompanyName" class="mb-base">
          <div>
            <b-card-text>
              {{ contactDetails.CompanyDescription }}
            </b-card-text>
          </div>
        </vx-card>

        <!-- Contact Details -->
        <vx-card
          :title="
            contactDetails.ContactFirstName +
            ' ' +
            contactDetails.ContactLastName
          "
          class="mb-base"
        >
          <!-- Contact Title, Contact business email, Contact personal email, Contact personal phone, Social icon -->
          <div class="vx-row">
            <div class="vx-col flex-1" id="account-info-col-1">
              <!-- Contact Title -->
              <p class="mb-2">{{ contactDetails.ContactTitle }}</p>
              <table>
                <!-- Contact email -->
                <tr>
                  <td class="font-semibold">
                    <feather-icon
                      icon="MailIcon"
                      title="Website"
                    ></feather-icon>
                  </td>
                  <td>
                    <div class="flex email-chip">
                      {{ contactDetails.ContactEmail }}
                      <vs-tooltip text="Historical Email Confidence">
                        <vs-chip
                          transparent
                          color="secondary"
                          class="vs-chip-gray bg-chip-transparent"
                        >
                          {{ contactDetails.HistoricalEmailConfidenceScore }}
                        </vs-chip>
                        <span></span>
                      </vs-tooltip>
                      <vs-tooltip text="Realtime Email Confidence">
                        <vs-chip class="bg-chip-transparent" color="primary">
                          {{ contactDetails.EmailConfidenceScore }}
                        </vs-chip>
                        <span></span>
                      </vs-tooltip>
                    </div>
                  </td>
                </tr>
                <!-- Contact personal email -->
                <tr>
                  <td class="font-semibold">
                    <span class="material-icons width-12"> contact_mail </span>
                  </td>
                  <td>{{ contactDetails.PersonalEmails }}</td>
                </tr>
                <!-- Contact personal phone -->
                <tr>
                  <td class="font-semibold">
                    <feather-icon icon="PhoneIcon"></feather-icon>
                  </td>
                  <td>{{ contactDetails.ContactPhone }}</td>
                </tr>
                <!-- Contact Mobile phone -->
                <tr>
                  <td class="font-semibold">
                    <feather-icon icon="SmartphoneIcon"></feather-icon>
                  </td>
                  <td>{{ contactDetails.ContactMobilePhone }}</td>
                </tr>
              </table>
              <!-- Social icon -->
              <div class="flex pt-2">
                <!-- <vs-avatar
                    color="primary"
                    icon-pack="feather"
                    icon="icon-facebook"
                  />
                  <vs-avatar
                    color="primary"
                    icon-pack="feather"
                    icon="icon-twitter"
                  /> -->
                <a
                  v-show="
                    contactDetails.ContactLinkedinUrl &&
                    contactDetails.ContactLinkedinUrl != ''
                  "
                  :href="'https://' + contactDetails.ContactLinkedinUrl"
                  target="_blank"
                >
                  <vs-avatar
                    color="primary"
                    icon-pack="feather"
                    icon="icon-linkedin"
                  />
                </a>
              </div>
            </div>
            <!-- Contact office address -->
            <div
              class="vx-col lg:w-1/2 md:w-3/3 sm:w-3/3 w-full lg:pt-0 pt-4"
              id="account-info-col-2"
            >
              <div class="mb-5 pb-5" pb-5>
                <h5 class="mb-2">
                  <img
                    src="../../../src/assets/images/map.svg"
                    class="inline-block"
                    width="18"
                    height="16"
                  />
                  {{ contactDetails.ContactFirstName }}'s Office Address
                </h5>
                <p class="ml-5 pl-1">
                  {{ contactDetails.officeAddress }}
                </p>
              </div>

              <!-- Physical Address -->
              <h5 class="mb-2" v-if="notShowPhysicalAddressForLead">
                <img
                  src="../../../src/assets/images/map.svg"
                  class="inline-block"
                  width="18"
                  height="16"
                />
                Physical Address
              </h5>
              <p v-if="notShowPhysicalAddressForLead" class="ml-5 pl-1">
                {{ contactDetails.physicalAddress }}
              </p>
            </div>
          </div>
          <!-- Education, Skills, Job Function -->
          <div class="vx-row pt-4">
            <!-- Education -->
            <div class="vx-col flex-1" id="account-info-col-1">
              <h5 class="mb-2">
                <img
                  src="../../../src/assets/images/education.svg"
                  class="inline-block"
                  width="18"
                />
                Education
              </h5>
              <!-- <p>{{ contactDetails.Education|stringWithCommaSpace }}</p> -->
              <span
                class="badge badge-primary-light"
                v-show="contactDetails.Education"
                >{{ contactDetails.Education | stringWithCommaSpace }}</span
              >
            </div>
            <!-- Skills -->
            <div class="vx-col flex-1" id="account-info-col-1">
              <h5 class="mb-2">
                <img
                  src="../../../src/assets/images/lightbulb.svg"
                  class="inline-block"
                  width="18"
                />
                Skill
              </h5>
              <span
                class="badge badge-primary-light"
                v-show="contactDetails.ContactSkills"
                >{{ contactDetails.ContactSkills | stringWithCommaSpace }}</span
              >
            </div>
            <!-- Job Function -->
            <div class="vx-col flex-1" id="account-info-col-2">
              <h5 class="mb-2">
                <img
                  src="../../../src/assets/images/suitcase.svg"
                  class="inline-block"
                  width="18"
                />
                Job Function
              </h5>
              <span
                class="badge badge-primary-light"
                v-show="contactDetails.ContactJobFunctions"
                >{{
                  contactDetails.ContactJobFunctions | stringWithCommaSpace
                }}</span
              >
            </div>
          </div>
          <!-- Gender -->
          <div class="vx-row mb-4 pt-4">
            <!-- Gender -->
            <div class="vx-col flex-1" id="account-info-col-2">
              <h5 class="mb-2">
                <img
                  src="../../../src/assets/images/gender.svg"
                  class="inline-block"
                  width="18"
                />
                Gender
              </h5>
              <p>{{ contactDetails.ContactGender }}</p>
            </div>
          </div>
          <vs-divider  />
          <!-- Saved From, Saved By, Saved Date -->
          <div class="vx-row mb-4 pt-4" >
            <!-- Saved From -->
            <div class="vx-col flex-1" id="account-info-col-1">
              <h5 class="mb-2">Saved From</h5>
              <p>{{ contactDetails.ContactSavedFrom }}</p>
            </div>
            <!-- Saved By -->
            <div class="vx-col flex-1" id="account-info-col-1">
              <h5 class="mb-2">Saved By</h5>
              <p>{{ contactDetails.ContactSavedBy }}</p>
            </div>
            <!-- Saved Date -->
            <div class="vx-col flex-1" id="account-info-col-2">
              <h5 class="mb-2">Saved Date</h5>
              <p>{{ contactDetails.ContactSavedDate | dateSavedFormatDate}}</p>
            </div>
          </div>
          <!-- Tags, Last Exported -->
          <div class="vx-row pt-4" >
            <!-- Tags -->
            <div class="vx-col flex-1" id="account-info-col-1">
              <h5 class="mb-2 flex items-center">
                Tags <a class="material-icons cursor-pointer" @click="$emit('editTagsForContact')">edit</a>
              </h5>

              <span class="badge badge-primary-light"
                >{{ contactDetails.ContactUserDefinedTags?contactDetails.ContactUserDefinedTags.join():'' }}
              </span>
            </div>
            <div class="vx-col flex-1 ">
             
            </div>
            <!-- Last Exported -->
            <div class="vx-col flex-1 " id="account-info-col-1">
              <h5 class="mb-2">Last Exported</h5>
              <p>{{ lastExportedformatDate(contactDetails.ContactLastExportDate) }}</p>
            </div>
          </div>
        </vx-card>
      </div>
    </template>
    <div class="text-right mt-6 mb-8">
      <vs-button
        color="grey"
        @click="$emit('clickForClose')"
        type="border"
        class="ml-4 grey-btn mr-3"
        >{{ LabelConstant.buttonLabelClose }}</vs-button
      >
    </div>
  </vs-popup>
</template>

<script>
import { BCardText } from "bootstrap-vue";
import VueJwtDecode from "vue-jwt-decode";
import moment from "moment";

export default {
  components: {
    BCardText,
    VueJwtDecode
  },
  props: {
    contactsDetailPopup: {
      type: Boolean,
      required: true,
      default: false,
    },
    contactDetails: {
      required: true,
    },
    moduleName:{
      required: true,
    }
  },
  mounted() {
    this.notShowPhysicalAddressForLead =
      this.$router.currentRoute.name == "IntentLeads" ? false : true;
  },
  data() {
    return {
      notShowPhysicalAddressForLead: false,
    };
  },
  filters: {
    stringWithCommaSpace: function (value) {
      return value.replace(/,/g, ", ");
    },
    dateSavedFormatDate(value){
      // let a = new Date(value).toUTCString();
      // let b = moment.utc(a);
      let yr = parseInt(moment(value).format("YYYY"));
      if(yr >= 2000){
        return moment(value).format("LLL")
      } else {
          return '';
      }
    },
    
  },
  methods: {
    lastExportedformatDate(value){
      // let a = new Date(value).toUTCString();
      // let b = moment.utc(a);
      let yr = parseInt(moment(value).format("YYYY"));
      let dtSaved = this.contactDetails?this.contactDetails.ContactSavedDate:'';
      let dateSavedUnix = moment(dtSaved).valueOf();
      let lastExportedUnix = moment(value).valueOf();
      if(dateSavedUnix == lastExportedUnix){
          return '';  
      } else {
        if(yr >= 2000){
          return moment(value).format("LLL")
        } else {
            return '';
        }
      }
    }
  },
};
</script>
