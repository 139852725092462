<template>
    <vs-popup
      class="popup-width"
      :title="popupTitle"
      :active.sync="isPopup"
    >
      <template>
        <div class="vx-row find-people">
          <div class="vx-col w-full">
            <div class="search-heading mb-8">
              <div class="search-h4-bg">
                <h4>{{ LabelConstant.headingLabelSearchTopic1 }}</h4>
              </div>

              <div class="search-h4-bg">
                <h4>{{ LabelConstant.headingLabelSearchTopic1 }}</h4>
              </div>
              <div class="search-h4-bg">
                <h4>{{ LabelConstant.headingLabelSearchTopic1 }}</h4>
              </div>
              <div class="search-h4-bg">
                <h4>{{ LabelConstant.headingLabelSearchTopic1 }}</h4>
              </div>
            </div>
          </div>
        </div>

        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <vx-card class="mb-8">
              <div class="flex flex-lg-nowrap flex-wrap align-items-top">
                <div class="mr-2 mb-2">
                  <img
                    src="../../../src/assets/images/avatar-s-11.png"
                    blank-color="#ccc"
                    width="150"
                    alt="placeholder"
                  />
                </div>
                <div>
                  <h5>{{ LabelConstant.headingLabelTopAlignedMedia }}</h5>
                  <b-card-text>
                    {{ LabelConstant.infoLabelOneSearchTip }}
                  </b-card-text>
                </div>
              </div>
            </vx-card>
            <vx-card class="mb-8">
              <div class="flex flex-lg-nowrap flex-wrap align-items-top">
                <div class="mr-2 mb-2">
                  <img
                    src="../../../src/assets/images/avatar-s-11.png"
                    blank-color="#ccc"
                    width="150"
                    alt="placeholder"
                  />
                </div>
                <div>
                  <h5>{{ LabelConstant.headingLabelTopAlignedMedia }}</h5>
                  <b-card-text>
                    {{ LabelConstant.infoLabelOneSearchTip }}
                  </b-card-text>
                </div>
              </div>
            </vx-card>
          </div>
        </div>
      </template>
      <div class="text-center mt-6 mb-8">
        <vs-button
          color="grey"
          @click="$emit('searchTipsPopupIput', false)"
          type="border"
          class="ml-4 grey-btn mr-3"
          >{{ LabelConstant.buttonLabelOkay }}</vs-button
        >
      </div>
    </vs-popup>
</template>

<script>
import { BCardText } from "bootstrap-vue";

export default ({
    components: {
        BCardText
    },
    props:{
        popupTitle:{
            type: String,
            required: true,
        },
        isPopup:{
            type: Boolean,
            required: true,
            default: false
        }
    }    
})
</script>
